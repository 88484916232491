import { Locomotive } from './../../../dashboard/model/locomotive';
import {  ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Date } from '@app/modules/shared/model/date';
import { AggregateService } from '@app/modules/shared/services/aggregate.service';
import { Aggregate } from '@app/modules/shared/utilities/aggregate';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import { AuthService } from '@edgelinc/ui-library';
import * as moment from 'moment';
import { AggregateOutput, DataObject} from '@app/modules/overview/model/aggregate-output';
import { AggregateInput } from '@app/modules/overview/model/aggregrate-input';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { Router } from '@angular/router';
import { Card, FuelSession } from '@app/modules/shared/model/fuel-session';
import { DateDropDownComponent } from '@app/modules/shared/components/dateSelection/date-drop-down/date-drop-down.component';

type FilterType = {
    startTime: number;
    endTime: number;
    assetGrpId?: string[];
}
@Component({
    selector: 'fuel-app-kpi',
    templateUrl: './kpi.component.html',
    styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
    iconUpward= 'arrow_upward'; 
    iconDownward='arrow_downward'
    innerSize = '70%';
    defaultValue ='Today';
    fuelComparison = true;
    startVariation = 0;
    endVariation = 0;
    currentDate = `Today, ${ moment().format('DD MMMM YYYY')}`;
    startTime = 0;
    endTime = 0;
    showSpinner = true;
    receivedDate = {'startTime':0,'endTime':0,'type':this.defaultValue};
    unitToDisplay: string | undefined;
    unitToConvert:string | undefined;
    percentage: number | undefined;
    percentageAverage: string | undefined;
    fuelAdded: number | undefined;
    fuelAddedDisplay: string | undefined;
    fuelFilled: string | undefined;
    fuelYester: number | undefined;
    aggreList: Array<AggregateOutput> = [];
    calculateDateUtil!: TimestampCalculation;
    unitConversion!:UnitConversion;
    tenantSpecificData!:FuelTenant
    assetGrpList!: Locomotive[];
    fuelData:Array<DataObject>=[];
    totalFuelCount=0;
    isLocoListChanged = true;
    @ViewChild(DateDropDownComponent) dateDropDownComponent!: DateDropDownComponent;
    infoTemplate = '<div class="infoTemplate-line1">Data is unavailable for current selection<div class="infoTemplate-line2">Try changing the time range or locomotive selection</div></div>';
    @Input() set locoList(value: Locomotive[]) {
        if (!value) { return; }
        this.assetGrpList = value; 
        this.isLocoListChanged = true;
        // do not call aggregrate API here on page load;only on locolist dropdown value should be called 
        if(this.receivedDate.startTime!=0 && this.receivedDate.endTime!=0){ 
            this.aersAggregatefrom();
        }
    }

    @Input() price: number | string = 0;
    @Output() updatePriceValue = new EventEmitter<number | string>();
    @Output() setPricePerGalon = new EventEmitter();

    
    constructor(private aggregateService: AggregateService, 
        private authService: AuthService,
        private router:Router,
        private fuelSessionService:FuelSessionService,
        private cdr: ChangeDetectorRef){}

    ngOnInit(): void {
        if(this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData){
            this.tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if(this.tenantSpecificData && this.tenantSpecificData.unitToDisplay){
                this.unitToDisplay = Object.values(this.tenantSpecificData.unitToDisplay)[0] as string;
                this.unitToConvert = Object.keys(this.tenantSpecificData.unitToDisplay)[0];
            }
        }
        this.calculateDateUtil = new TimestampCalculation();
        this.unitConversion = new UnitConversion();
        const calculatedDate = this.calculateDateUtil.calculateDate(this.defaultValue);
        this.receivedDate.startTime = calculatedDate.startTime;
        this.receivedDate.endTime = calculatedDate.endTime;
        this.receivedDate.type = this.defaultValue;
        this.startVariation=this.calculateDateUtil.calculateVariation(this.defaultValue).startTime;
        this.endVariation=this.calculateDateUtil.calculateVariation(this.defaultValue).endTime;
        this.fuelComparison=this.calculateDateUtil.calculateVariation(this.defaultValue).type!=='Custom'? true:false;
    }

    // function call to recieved calculated startTime/endtime from called component
    receivedDateValue(receivedDate:Date):void{
        this.receivedDate = receivedDate;
        if(this.fuelSessionService && this.fuelSessionService.fuelSession){
            const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
            if( fuelSession && fuelSession.card){
                fuelSession.card[0] = {'type':this.receivedDate.type,'startTime':this.receivedDate.startTime,'endTime':this.receivedDate.endTime,'cardName':'overview'};
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives,fuelSession.card);
            }
        }
        this.startVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
        this.endVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
        this.fuelComparison=this.calculateDateUtil.calculateVariation(this.receivedDate.type).type!=='Custom'? true:false;  
        this.aersAggregatefrom();
    }
    ngAfterViewInit():void {
        // retaining previously selected values in date drop down
        const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        if(fuelSession && fuelSession.card){
            const overview = fuelSession.card.find((a: { cardName: string; }) => a.cardName=='overview');
            if(overview?.type !=undefined){
                if(overview.type !=='Custom'){
                    // eslint-disable-next-line unicorn/no-lonely-if
                    if(overview.type!=''){
                        this.defaultValue = overview.type;
                        this.receivedDate = {'type': this.defaultValue,'startTime':overview.startTime,'endTime':overview.endTime};
                        this.startVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison=this.calculateDateUtil.calculateVariation(this.receivedDate.type).type!=='Custom'? true:false;  

                    } else{
                        this.defaultValue = 'Today';
                        this.receivedDate = {'type': this.defaultValue,'startTime':this.calculateDateUtil.calculateDate(this.defaultValue).startTime,'endTime':this.calculateDateUtil.calculateDate(this.defaultValue).endTime};
                        this.startVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation=this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison=this.calculateDateUtil.calculateVariation(this.receivedDate.type).type!=='Custom'? true:false;  
                    }
                   
                    if(this.fuelSessionService && this.fuelSessionService.fuelSession){//set in fuelSession localstorage
                        fuelSession.card[0]=  {'type':this.calculateDateUtil.calculateDate(this.defaultValue).type,'startTime':this.calculateDateUtil.calculateDate(this.defaultValue).startTime,'endTime':this.calculateDateUtil.calculateDate(this.defaultValue).endTime,'cardName':'overview'};
                        this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives,fuelSession.card);
                    }
                } else{// for custom get date value and set in date picker option
                    const date =`${moment(overview.startTime).format('DD-MMMM-YYYY')} - ${moment(overview.endTime).format('DD-MMMM-YYYY')}`;
                    if(this.dateDropDownComponent){// datedropdown child component 
                        this.dateDropDownComponent.customText = date;//setting drop down display value
                        this.defaultValue = 'Custom'; // setting selected value 
                        this.receivedDate = {'type':overview.type,'startTime':overview.startTime,'endTime':overview.endTime};// for rendering as full width 
                    } 
                }
            }
        }else{ // on initial login no local storage found so setting in localStorage
            if(this.fuelSessionService && this.fuelSessionService.fuelSession){
                this.fuelSessionService.fuelSession.card[0]=  {'type':this.calculateDateUtil.calculateDate(this.defaultValue).type,'startTime':this.calculateDateUtil.calculateDate(this.defaultValue).startTime,'endTime':this.calculateDateUtil.calculateDate(this.defaultValue).endTime,'cardName':'overview'};
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, this.fuelSessionService.fuelSession.card);
            }
        }
        this.cdr.detectChanges();
    }

    aersAggregatefrom(): void {
        this.showSpinner = true;
        const aggregate = new Aggregate();
        const eventList:Array<AggregateInput> = [];
        const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        let overview:Card ={'type':'','startTime':0,'endTime':0,'cardName':''};
        if(fuelSession && fuelSession.card){// get store overview card data from localStorage
            overview = fuelSession.card.find((a: { cardName: string; }) => a.cardName=='overview') as Card;
        }
        const assetInfo:string[] = fuelSession?.locomotives?.length > 0 ? fuelSession.locomotives.map(x => x.device_name) as string[]: [];
        //assetInfo = this.assetGrpList && this.assetGrpList.length > 0 ? this.assetGrpList.map(x => x.device_name) as string[]: [];

        const cumulativeFilter: { assetGrpId?: string[] } = {};
        const filter:FilterType = {
            startTime: overview?.startTime !==0 ? overview?.startTime:this.receivedDate.startTime,
            endTime: overview?.endTime!==0 ?overview?.endTime:this.receivedDate.endTime,
        };
        const filterCalculate:FilterType = {
            startTime: this.startVariation,
            endTime: this.endVariation,
        };

        if (assetInfo.length > 0) {
            filter.assetGrpId = assetInfo;
            cumulativeFilter.assetGrpId = assetInfo;
            filterCalculate.assetGrpId = assetInfo;
        }

        // added all fuel consumptions operations to request all object to API call.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const cumulativeFuel = aggregate.creatObjects('CumulativeFuel','SUM', 'Fuel_Fill','total_fuel_change',cumulativeFilter,['total_fuel_change_unit']);
        const fuelAdded = aggregate.creatObjects('FuelAdded','SUM', 'Fuel_Fill','total_fuel_change', filter, ['total_fuel_change_unit']);
        const fuelAddedVariation = aggregate.creatObjects('FuelAddedVariation','SUM', 'Fuel_Fill','total_fuel_change', filterCalculate, ['total_fuel_change_unit']);
        const fuelUsage = aggregate.creatObjects('FuelUsage','SUM', 'Notch_State_Change','total_fuel_consumed', filter, ['current_loco_state', 'total_fuel_consumed_unit']);
        const totalFuelUsage = aggregate.creatObjects('TotalFuelUsage','SUM', 'Notch_State_Change','total_fuel_consumed', filter, ['total_fuel_consumed_unit']);
        if(this.receivedDate.type !=='Custom'){// insert fuelAddedVariation operation only when Custom not selected
            eventList.push(cumulativeFuel, fuelAdded, fuelAddedVariation, fuelUsage, totalFuelUsage);
        } else{
            eventList.push(cumulativeFuel, fuelAdded, fuelUsage, totalFuelUsage);
        }

        this.aggregateService.getAggregate(eventList).subscribe(response => {
            if(response && Array.isArray(response)){ 
                response.map((data: AggregateOutput) => {
                    this.aggreList.push(data); 
                    //fuel added, cumulative fuel, total percentage variable to be assigned from the response list.
                    if(data && data.data && data.operationId =='FuelAdded'){ 
                        if(data.data.length > 0 ){
                            if(data.data[0]?.total_fuel_change_unit && (this.unitToConvert == data.data[0]?.total_fuel_change_unit)) {
                                this.fuelAdded = data.data[0]?.result; // units matched and use for fuel percentage calculation in same unit
                                this.fuelAddedDisplay = this.unitConversion.convertFormat(data.data[0]?.result);// units matched and use for display in K,M,G format in UI
                            }else{
                                // units not matched ;convert to gallons/liters value
                                const valueConverted = this.unitConversion.convertUnits(data.data[0]?.result ,this.unitToConvert); 
                                // units matched and use for display in K,M,G format in UI
                                this.fuelAddedDisplay = this.unitConversion.convertFormat(valueConverted);
                                // units matched and use for fuel percentage calculation
                                this.fuelAdded = valueConverted;
                            }
                        }else{
                            this.fuelAddedDisplay = '';
                            console.log('fuelAdded No data found');
                        }
                    }else if (data && data.data && data.operationId =='CumulativeFuel'){
                        if(data.data.length > 0 ){
                            if(data?.data[0]?.total_fuel_change_unit && (this.unitToConvert == data?.data[0]?.total_fuel_change_unit)) {
                                //units matched and convert to K,M,G format for display
                                this.fuelFilled = this.unitConversion.convertFormat(data?.data[0]?.result);
                            } else{
                                // units not matched and convert to K,M,G format for display
                                const valueConverted = this.unitConversion.convertUnits(data?.data[0]?.result , this.unitToConvert);
                                this.fuelFilled = this.unitConversion.convertFormat(valueConverted);
                            }
                        }  
                        else {
                            this.fuelFilled = '';
                            console.log('CumulativeFuel No data found');
                        }  
                    }else if (data && data.data && data.operationId =='FuelAddedVariation'){
                        if(data.data.length > 0 ){
                            if(data?.data[0]?.total_fuel_change_unit && (this.unitToConvert == data?.data[0]?.total_fuel_change_unit)) {
                                this.fuelYester = data?.data[0]?.result;
                            } else{
                                this.fuelYester = this.unitConversion.convertUnits(data?.data[0]?.result , this.unitToConvert);
                            }
                        }else{
                            this.fuelYester = 0; 
                            console.log('fuelYester No data found');
                        }   
                    }else if((data && data.data && data.operationId == 'TotalFuelUsage')){
                        if(data.data.length>0){
                            this.totalFuelCount=data.data[0].result;
                        }    
                    }else if((data && data.data && data.operationId == 'FuelUsage') && data.data) {
                        this.fuelData=data.data;
                    }
                });
                this.showSpinner = false;
            }
            //Average percentage caluculation for fuel Added today and yesterday.
            if(this.fuelAdded && this.fuelYester){
                const averagefuelToday = (this.fuelAdded-this.fuelYester);
                this.percentage = ((averagefuelToday/this.fuelYester)*100);
                this.percentageAverage = this.percentage.toFixed();
            }else{//hide percentage value and percentage indicator
                this.fuelComparison = false;
            }
            this.isLocoListChanged = false;
        },(error) => {
            this.showSpinner = false;
            this.isLocoListChanged = false;
            this.infoTemplate = '<div class="infoTemplate-line1">We are unable to fetch the data for current selection<div class="infoTemplate-line2">Try refreshing the page</div></div>';
            console.log('error');
            console.log(error);
        });
   
    }
    // go to kpi list view page
    gotoListView():void{
        void this.router.navigate(['/views/kpi/list']);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updatePrice($event: Event):void {
        const target = $event.target as HTMLTextAreaElement;
        const price = Number(target.value) || 0;
        this.updatePriceValue.emit(price);
    }

    setPrice(): void {
        this.setPricePerGalon.emit();
    }
}
