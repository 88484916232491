/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { AppStorage } from '../../../data/app-storage';
import { Tenant } from '../../../data/tenant';

@Injectable()
export class FakeAppStorage {
    tenant = new Tenant();
    getFromAppStorage(name:string):AppStorage {
        if(name == 'data') {
            return {
                'token': {
                    'access_token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImxlZ2FjeS10b2tlbi1rZXkiLCJ0eXAiOiJKV1QifQ.eyJqdGkiOiI3Zjg1NWI0MDQwNmM0ZDMzODI3ZjE3ZTZkNjc1NTM5YiIsInN1YiI6ImQ1MGRmOWNjLWFiM2EtNDdkOC04Y2E3LWY4YmE1NDBjYzgxMCIsInNjb3BlIjpbInByZWRpeC1lZGdlLnpvbmVzLnByZWRpeC1hcHBsaWFuY2UtMDYtdGVuYW50LTAxLnVzZXIiLCJlZGdlbWFuYWdlci56b25lcy5wcmVkaXgtYXBwbGlhbmNlLTA2LXRlbmFudC0wMS5BZG1pbmlzdHJhdG9yIiwib3BlbmlkIiwidWFhLnVzZXIiLCJwYXNzd29yZC53cml0ZSJdLCJjbGllbnRfaWQiOiJlbS1jbGllbnQiLCJjaWQiOiJlbS1jbGllbnQiLCJhenAiOiJlbS1jbGllbnQiLCJncmFudF90eXBlIjoicGFzc3dvcmQiLCJ1c2VyX2lkIjoiZDUwZGY5Y2MtYWIzYS00N2Q4LThjYTctZjhiYTU0MGNjODEwIiwib3JpZ2luIjoidWFhIiwidXNlcl9uYW1lIjoiaWFucl9hZG1pbiIsImVtYWlsIjoiZG9lc25vdGV4aXN0QGdlLmNvbSIsImF1dGhfdGltZSI6MTYxNjU5Mzk5MywicmV2X3NpZyI6ImY2ZWZkMmIiLCJpYXQiOjE2MTY1OTM5OTMsImV4cCI6MTYxNjYzNzE5MywiaXNzIjoiaHR0cDovL3ByZWRpeC11YWEtZGV2LWVkZ2VsaW5jLnNjYWxyLndhYnRlYy5jb20vdWFhL29hdXRoL3Rva2VuIiwiemlkIjoidWFhIiwiYXVkIjpbInBhc3N3b3JkIiwidWFhIiwib3BlbmlkIiwiZWRnZW1hbmFnZXIuem9uZXMucHJlZGl4LWFwcGxpYW5jZS0wNi10ZW5hbnQtMDEiLCJlbS1jbGllbnQiLCJwcmVkaXgtZWRnZS56b25lcy5wcmVkaXgtYXBwbGlhbmNlLTA2LXRlbmFudC0wMSJdfQ.a49FZu9pGK4wpKxeW6H39Zto27x2xAiITcyx52azO5uDUWC0pr5SCfSw3fkBwODPwDKho_EIPmo6L_Tqq8Tf6tNVrz_xFYhxf1L6JGyaOXtcazh4ZxUnKzjZUK2HvWG-WQMX1GsBS5sG91vxvUdBP7DR3wqVgGL_KaXycMxr4ar0TpLf1rDAq_RguxNZLLoSv63yS3Fh6IWg9W8SZBE7HLVhqbMq4YNHjtRonOxcAQ2kPwn97PeWVfwPRvULPbowzJTLcVfaSihALEGJdN5t62z5idAHkyzMivBsFrZrzJZeG_qJtBpTZrL91TjW9vYrroWGwRgfRD0GdbEHik1Syw',
                    'token_type': 'bearer',
                    'refresh_token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImxlZ2FjeS10b2tlbi1rZXkiLCJ0eXAiOiJKV1QifQ.eyJqdGkiOiI5YTM0ZGUzMWUzZmM0ZDA4ODk1OTJmMzU2NGNiODQ0YS1yIiwic3ViIjoiZDUwZGY5Y2MtYWIzYS00N2Q4LThjYTctZjhiYTU0MGNjODEwIiwic2NvcGUiOlsicHJlZGl4LWVkZ2Uuem9uZXMucHJlZGl4LWFwcGxpYW5jZS0wNi10ZW5hbnQtMDEudXNlciIsImVkZ2VtYW5hZ2VyLnpvbmVzLnByZWRpeC1hcHBsaWFuY2UtMDYtdGVuYW50LTAxLkFkbWluaXN0cmF0b3IiLCJvcGVuaWQiLCJ1YWEudXNlciIsInBhc3N3b3JkLndyaXRlIl0sImlhdCI6MTYxNjU5Mzk5MywiZXhwIjoxNjE5MTg1OTkzLCJjaWQiOiJlbS1jbGllbnQiLCJjbGllbnRfaWQiOiJlbS1jbGllbnQiLCJpc3MiOiJodHRwOi8vcHJlZGl4LXVhYS1kZXYtZWRnZWxpbmMuc2NhbHIud2FidGVjLmNvbS91YWEvb2F1dGgvdG9rZW4iLCJ6aWQiOiJ1YWEiLCJncmFudF90eXBlIjoicGFzc3dvcmQiLCJ1c2VyX25hbWUiOiJpYW5yX2FkbWluIiwib3JpZ2luIjoidWFhIiwidXNlcl9pZCI6ImQ1MGRmOWNjLWFiM2EtNDdkOC04Y2E3LWY4YmE1NDBjYzgxMCIsInJldl9zaWciOiJmNmVmZDJiIiwiYXVkIjpbInBhc3N3b3JkIiwidWFhIiwib3BlbmlkIiwiZWRnZW1hbmFnZXIuem9uZXMucHJlZGl4LWFwcGxpYW5jZS0wNi10ZW5hbnQtMDEiLCJlbS1jbGllbnQiLCJwcmVkaXgtZWRnZS56b25lcy5wcmVkaXgtYXBwbGlhbmNlLTA2LXRlbmFudC0wMSJdfQ.UONC9m50897rfdjHWhPyI3MaAXrQBk7Ya2UgTWc3OdLfWszRfBrMQnlJfrasoU4c8g_BtE0hGZmJ5fYBp8YDVcz6gqmpRks91VkR55S9iYEuYJOuktYbEfOEGC8idaMzcYbrHH1VdpEB5T4AjYmYYkWmqhaHRttTGckNswpiaqYkxxTbI4ohuf-Xr2z0blPxDlLDZWMJMYyKIbbo-x8NggKAVSvy8-htAbd3cIUNNZOYRVIalUpV8hwpwIgTnXWVdAgOMWlM6meVN_ipGvGPYQikqjMNB79hxptnUZWMtscYVvNv0ln5fwEyYdx-Ry-Ynm0xXk6S6dIXNZeMWNrPSw',
                    'expires_in': 43199,
                    'scope': 'predix-edge.zones.predix-appliance-06-tenant-01.user edgemanager.zones.predix-appliance-06-tenant-01.Administrator openid uaa.user password.write',
                    'jti': '7f855b40406c4d33827f17e6d675539b',
                    'expires_at': 1616637192985
                },
                'userData': {
                    'name': 'ianr_admin',
                    'roles': ['Administrator', 'Operator'],
                    'email': 'doesnotexist@ge.com',
                    'attributes': {}
                },
                'tokenRefreshing': false,
                'permissions': ['Manage MCS SSH keys', 'View Requests', 'View System Errors', 'View System Parameters', 'Manage Template', 'View Master Data', 'View RuleBundle', 'Manage MCS Geozone', 'View MCS Device Status', 'View Alerts', 'Manage Geo Zone Mgmt Service', 'View Dashboard', 'MCS View', 'Approve Template', 'Manage MCS Devices', 'Manage Fleet Manager Service', 'Admin Roles', 'Create Edit History', 'Manage User SVC', 'QNX Gateway Template Management', 'View Lookup Set', 'Admin', 'View Devices', 'Manage Last Stat Value', 'View RuleEditor', 'Default View', 'View Network', 'View Configuration', 'View Locomotives', 'Admin Users', 'View Parameters', 'Request MCS Device', 'Management', 'Manage Snapshot Service', 'View QNX Gateway Device Summary', 'Manage RuleBundle', 'Assign Technician to Device', 'Enroll Device', 'Apply Templates', 'View MCS Device Templates', 'Manage Device Enrollment', 'View Tasks', 'Manage Devices', 'Manage User View Service', 'View MCS Device Request History', 'View Maps', 'View History', 'Apply RuleBundle', 'Manage Locomotives', 'View Templates'],
                'tenantSpecificData': this.tenant
            };
        } 
    }
   
    setToAppStorage(name:string , value:string):void {}

    removeFromAppStorage(name:string):void {}
}
