<mat-card>
    <mat-card-content>
        <div class="d-flex justify-content-between">
            <span class="text overview" [innerText]="'events.events' | translate"></span>
            <div class="filter-container mt-n2">
                <fuel-app-date-drop-down [defaultValue]='defaultValue' (emitDateValue)="receivedDateValue($event)">
                </fuel-app-date-drop-down>
                <div class="list-view-el d-none d-sm-block" (click)="gotoListView()"></div>
            </div>
        </div>
        <div class="container-md">
            <div *ngIf="!dataEmpty && !loading">
                <div class='row mt-n0 mt-md-n3 h-center'>
                    <div class='col-md-2 col-xl-3'>
                        <div>
                            <span class="text small-title" [innerText]="'events.totalEvents' | translate"></span>
                        </div>
                        <div class="text-container">
                            <div>
                                <span class="text unitText" [innerText]='totalEvents'></span>
                            </div>
                            <div class='perc-container'>
                                <!-- <div *ngIf='receivedDate.type !=="Custom"' class='perc-text'>
                                    <span class="percentage red">1.22</span>
                                    <span class="percentage red">%</span>
                                    <span class='ng-star-inserted'>
                                        <mat-icon class='mat-icon notranslate material-icons red mat-icon-no-color'
                                            [ngClass]='"red"' [innerText]='iconDownward'></mat-icon>
                                    </span>
                                </div> -->
                                <div *ngIf='comparisonDays' class='compared-text'>Compared to the last {{comparisonDays}} {{comparisonDays === 1 ? 'day' : 'days'}}</div>    
                            </div>
                        </div>
                    </div>
            
                    <div class='separator'>&nbsp;</div>
                    <div class='col-md-6 col-xl-4 chart-container'>
                        <div class='row donut-chart'>
                            <div *ngIf='!dataEmpty ;else no_data'>
                                <highcharts-chart *ngIf="!dataEmpty" [Highcharts]="Highcharts" [options]="idleChartOptions"
                                    style="width:auto; height:180px; display: block;">
                                </highcharts-chart>
                            </div>
                            <ng-template #no_data>
                                <div class='col-12 mt-2 mt-md-1 no-data'>-.-
                                    <mat-icon matTooltip="{{infoTemplate}}" matTooltipClass="my-custom-tooltip" matTooltipPosition='below'>
                                        <mat-icon class="help">help</mat-icon>
                                    </mat-icon>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-auto col-xl-1 d-none d-md-block arrow-sign">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41.896" viewBox="0 0 25 41.896">
                            <path id="Mask"
                                d="M3.34.821A1.768,1.768,0,0,0,.8.711,1.866,1.866,0,0,0,.7,3.306L19.848,24.633a1.769,1.769,0,0,0,2.645,0l8.339-9.286L41.645,3.306A1.866,1.866,0,0,0,41.537.711,1.768,1.768,0,0,0,39,.821L21.17,20.676Z"
                                transform="translate(-0.227 42.118) rotate(-90)" />
                        </svg>
                    </div>
                    <div class='col-md-2 col-xl-3'>
                        <div class='text-container'>
                            <div>
                                <span class="text unitText" [innerText]="currentEventsNumber"></span>&nbsp;
                                <span class="text unitText">Events</span>
                            </div>

                            <div class='perc-container'>
                                <!-- <div class="perc-text">
                                    <span class="percentage green">3.11%</span>
                                    <span>
                                        <mat-icon [ngClass]='"green"' [innerText]='iconUpward'></mat-icon>
                                    </span>
                                </div> -->
                                <div *ngIf='comparisonDays' class='compared-text'>Compared to the last {{comparisonDays}} {{comparisonDays === 1 ? 'day' : 'days'}}</div>  
                            </div>
                        </div>
                    </div>
                    <!--  Mobile List View -->
                    <div class='col-12 d-block d-sm-none mt-4 d-flex justify-content-center'>
                        <volt-button variant="outline" [innerText]="'overview.listView' | translate" (click)="gotoListView()"></volt-button>
                    </div>
                </div>
            </div>
            <div *ngIf="dataEmpty" class='chart-view-row empty wrap-c center full'>
                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
                    <path id="Mask"
                        d="M42.888,1.027a4.956,4.956,0,0,1,6.654,2.2h0l40.3,80.088A4.955,4.955,0,0,1,85.417,90.5H5.329A4.956,4.956,0,0,1,.891,83.336h0L40.678,3.249,41,2.7A4.956,4.956,0,0,1,42.888,1.027Zm2.244,10.842L9.958,82.672h70.8Zm.245,53.849a5.216,5.216,0,1,1-5.216,5.216A5.216,5.216,0,0,1,45.378,65.719Zm0-37.82a3.771,3.771,0,0,1,3.912,3.611h0V55.587a3.925,3.925,0,0,1-7.825,0h0V31.511A3.771,3.771,0,0,1,45.378,27.9Z"
                        transform="translate(-0.372 -0.497)" />
                </svg>
                <div class="text small-title mt-1" [innerText]="'general.Empty' | translate"></div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="example-loading-shade" *ngIf="loading">
                <el-spinner></el-spinner>
            </div>
        </div>
    </mat-card-content>
</mat-card>