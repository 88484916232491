<mat-card class='consumption-profile'>
  <mat-card-content>
    <div class="d-flex justify-content-between">
      <span class="text overview" [innerText]="'consumption-profile.consumption-profile' | translate"></span>
      <div class="filter-container mt-n2">
          <fuel-app-date-drop-down [defaultValue]='defaultValue' (emitDateValue)="receivedDateValue($event)">
          </fuel-app-date-drop-down>
          <div (click)="gotoListView()" class="list-view-el d-none d-sm-block"></div>
      </div>
    </div>
    <div class='row' *ngIf='!dataEmpty; else no_consumption_profile_data'>
      <div class='col-12 col-md-8 charts-col'>
        <highcharts-chart id="container" [Highcharts]="Highcharts" [options]="lineChartOptions" [(update)]="updateFlag"
          [oneToOne]="true" style="width: auto; height: auto; display: block;"
          (chartInstance)="onChartInstance($event)"
          >
        </highcharts-chart>
      </div>
      <div [ngClass]="{'align-self-center':!table}" class='col-12 align-self-center col-md-4 mt-4'>
        <div *ngIf="!table" class='notchContainer' class="d-flex justify-content-center">
          <span class="notchValue" [innerText]='notchValue'></span>
        </div>

        <mat-form-field *ngIf="table" class='notch-form-field'>
          <mat-select [value]='notchValue' (selectionChange)="selectNotch($event)">
            <mat-option *ngFor="let notch of xAxis" [value]="notch">Notch {{notch}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="table">
          <div *ngIf="groupByField !== 'locomotives'" class="table-container">
            <table mat-table [dataSource]="dataSource">
              <ng-container *ngFor="let col of tableColumns; let i = index;" [matColumnDef]="col.field">
                <th mat-header-cell *matHeaderCellDef> {{col.title}} </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="col.field!=='color'; else color_dot">{{element[col.field]}}</div>
                  <ng-template #color_dot>
                    <div  class="dot" [style.backgroundColor]="element[col.field]"></div>
                  </ng-template>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <div *ngIf="groupByField === 'locomotives'" class="table-container">
            <table mat-table [dataSource]="dataSource">
              <ng-container *ngFor="let col of tableColumnsByLoc; let i = index;" [matColumnDef]="col.field">
                <th mat-header-cell *matHeaderCellDef>
                  {{col.title}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="col.field!=='color'; else color_dot">{{element[col.field]}}</div>
                  <ng-template #color_dot>
                    <div  class="dot" [style.backgroundColor]="element[col.field]"></div>
                  </ng-template>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <div *ngIf="series.length > 5" class="table-details">Showing the top 5 default {{groupByField !== 'locomotives' ? 'models' : 'locomotives'}} </div>
        </div>
      </div>
    </div>
    <ng-template #no_consumption_profile_data>
      <div class="row">
        <div class='col-12 d-flex justify-content-center load'>
          <div class="infoTemplate-line1">{{infoTemplateFirst}}<div class="infoTemplate-line2" style="color:grey">
              {{infoTemplateSecond}}</div>
          </div>
        </div>
      </div>
    </ng-template>
    <div class='row'>
      <div class='col-12 d-block d-sm-none mt-4 d-flex justify-content-center'>
        <volt-button variant="outline" [disabled]="dataEmpty" [innerText]="'overview.listView' | translate" (click)="gotoListView()"></volt-button>
      </div>
    </div>
    <div class="example-loading-shade" *ngIf="showSpinner">
      <el-spinner></el-spinner>
    </div>
  </mat-card-content>
</mat-card>