import { Locomotive } from './../../model/locomotive';
import { Component, OnInit } from '@angular/core';
import { LocomotiveListService } from '@app/modules/shared/services/locomotive-list.service';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
@Component({
    selector: 'fuel-app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    XTotalCount: number | undefined;
    filterOptions = 'class eq "CMS"';
    offSet= 0;
    pageSize= 500;
    defaultSortColumn= 'loco_id';
    defaultSortDirection= 'asc';        
    dropDownList! : Locomotive[];
    selectLoco: Locomotive[]=[];
    savedSelectedList : Locomotive[]=[];
    isSavedSelectedList = false;
    fuelPricePerGalon: number | string = '0.00';
    inputPrice: number | string = '0.00';

    constructor(private locomotiveListService:LocomotiveListService,private fuelSessionService:FuelSessionService) {}

    ngOnInit(): void {
        const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        const sessionLocomotives: Locomotive[] | undefined = fuelSession?.locomotives?.length >0 ? fuelSession.locomotives : undefined;
        this.dropDownList = [];
        this.locomotiveListService.getLocoList(
            this.filterOptions,
            this.pageSize,
            this.offSet,
            this.defaultSortColumn,
            this.defaultSortDirection
        ).subscribe(response => {
            if(response){
                response.map(data => {
                    this.dropDownList.push(data); 
                    if(sessionLocomotives){
                        const value = sessionLocomotives.find(x=> x.device_name == data.device_name);
                        if(value){
                            this.savedSelectedList.push(data);
                            this.isSavedSelectedList =true;
                        }
                    }
                }); 
            }
        },(error) => {
            console.log('error');
            console.log(error);
        }); 
        const parameter = this.savedSelectedList ? this.savedSelectedList:[];
        if(this.isSavedSelectedList){// call when all locomotives values are there
            this.selectedLocoList(parameter);
        }
    }
 
    selectedLocoList(event?: Locomotive[]): void{
        if(event != undefined ){
            const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
            if(fuelSession && fuelSession.card){
                this.fuelSessionService.setFuelSession(event,fuelSession.card);
            } else{
                if(this.fuelSessionService && this.fuelSessionService.fuelSession){
                    const card =  [
                        {'type':'','startTime':0,'endTime':0,'cardName':'overview'},
                        {'type':'','startTime':0,'endTime':0,'cardName':'events'},
                        {'type':'','startTime':0,'endTime':0,'cardName':'consumption'}
                    ];
                    this.fuelSessionService.setFuelSession(event,card);
                }
            }
            this.selectLoco = event;
        }
    }

    updatePriceValue($event: number | string):void {
        this.inputPrice = Number($event).toFixed(2);
    }

    setPricePerGalon(): void {
        this.fuelPricePerGalon = this.inputPrice;
    }
}
