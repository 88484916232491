import { environment } from '@env/environment';
import { ConfigurationStore } from './configurator-helper';
import { ConfigService } from '../services/config.service';
import { InjectorInstance } from '../shared.module';


/**
 * init App with http call.
 * 
 * @param {configurationStore} configurationStore configuration store reference to store configuration.
 * @returns {object} url configuration object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initAppWithHttp(configurationStore: ConfigurationStore): any {
    return () => {
        const configService = InjectorInstance.get<ConfigService>(ConfigService);
        return configService.getConfig()
            .toPromise()
            .then((config) => {
                const configuration = {
                    appName:'fuel',
                    environment: environment.base,
                    domainConfig: config,
                    landingPage:'views/home',
                    sideMenu: [
                        {
                            label: 'Home',
                            url: ['/views/home'],
                            icon: 'home.svg',
                            visible: true,
                        },
                        {
                            label: 'Overview',
                            url: ['/views/kpi/list'],
                            icon: 'icon KPIDetails',
                            visible: true,
                        },
                        {
                            label: 'Consumption profile',
                            url: ['/views/consumption/list'],
                            icon: 'icon consumptionProfile',
                            visible: true,
                        },
                        {
                            label: 'Events',
                            url: ['/views/events/list'],
                            icon: 'icon events',
                            visible: true,
                        },
                        {
                            label: 'Map',
                            url: ['/views/map/list'],
                            icon: 'icon map',
                            visible: true,
                        }
                    ]
                };
                configurationStore.setConfig(configuration);
            });
    };
}

