import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';

const routes: Routes = [
    { path: 'header', component: SiteHeaderComponent, pathMatch: 'full' },
    { path: 'footer', component: SiteFooterComponent, pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule {}
