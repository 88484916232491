<div id="map" *ngIf="markers && center">
    <div id="centerMap" class="button-map-center" (click)="centerMap()">
        <mat-icon aria-hidden="true">gps_fixed</mat-icon>
    </div>
    <agm-map [style.height]="height" (mapClick)="mapClicked($event)" (mapReady)="mapReady($event)" (centerChange)="centerChange($event)" [scrollwheel]="true" [streetViewControl]="false" [fullscreenControl]="true" [zoom]="zoom" [latitude]="center['latitude']" [longitude]="center['longitude']"
        [controlSize]="mapControlSize" [minZoom]="minZoom" [maxZoom]="maxZoom" [mapTypeControl]="mapTypeControl" [mapTypeControlOptions]="mapTypeControlOptions" [mapTypeId]="mapTypeId">
        <div id="legend-bottom-left">
            <ng-template [ngTemplateOutlet]="bottomLeftLegendTemplate"></ng-template>
        </div>
        <div id="legend-top-right">
            <ng-template [ngTemplateOutlet]="toprightLegendTemplate"></ng-template>
        </div>
        <agm-marker-cluster [averageCenter]="true" [gridSize]="30" [maxZoom]="maxZoom-1" [styles]="[{url: imagePath+'1.png', textColor: '#fff', textSize: '16', height: '53', width: '53'}, {url: imagePath+'2.png', textColor: '#fff', textSize: '16', height: '56', width: '56'}, {url: imagePath+'3.png', textColor: '#fff', textSize: '16', height: '66', width: '66'}, {url: imagePath+'4.png', textColor: '#fff', textSize: '16', height: '78', width: '78'}, {url: imagePath+'5.png', textColor: '#fff', textSize: '16', height: '90', width: '90'}]">
            <agm-marker *ngFor="let m of markers" [latitude]="m.latitude" [longitude]="m.longitude" [iconUrl]="m.iconUrl" (markerClick)="clickedMarker(m)">
                <agm-snazzy-info-window [maxWidth]="400" [closeWhenOthersOpen]="true" [placement]="m.placement || 'top'">
                    <ng-template>
                        <div class="marker-info">
                            <ng-container>
                                <ng-template [ngTemplateOutlet]="tooltipInfo" [ngTemplateOutletContext]="{$implicit: m, col: m}"></ng-template>
                            </ng-container>
                        </div>
                    </ng-template>
                </agm-snazzy-info-window>
            </agm-marker>
        </agm-marker-cluster>
    </agm-map>
</div>