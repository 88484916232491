/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';
import { Card, FuelSession} from '@app-shared/model/fuel-session';

@Injectable({
    providedIn: 'root'
})
export class FuelSessionService {
     fuelSession: FuelSession = {'locomotives':[],
         'card':[
             {'type':'','startTime':0,'endTime':0,'cardName':'overview'},
             {'type':'','startTime':0,'endTime':0,'cardName':'events'},
             {'type':'','startTime':0,'endTime':0,'cardName':'consumption'}
         ],
         
     };
     // eslint-disable-next-line @typescript-eslint/no-explicit-any
     setFuelSession(locomotives:Locomotive[],card: Card[]): void{
         this.fuelSession = {
             'locomotives':locomotives,
             // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
             'card':card
         };
         localStorage.setItem('fuelSession',JSON.stringify(this.fuelSession));
     }
     getFuelSession():string {
         const fuelSession = (localStorage.getItem('fuelSession')) as string;
         return fuelSession;
     }
}
