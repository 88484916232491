/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MapMarkersService } from '@app/modules/shared/services/map-markers.service';
import { MapCoordinates } from '@edgelinc/ui-library';
import { catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { MarkerCoordinatesFilter, MarkerCoordinatesResponse, MarkerInfo, MarkerInformationFilter, MarkerList } from '../model/marker';

export const StatusMarkerIcons = {
    0: 'unreachable',
    4: 'operational',
    5: 'operational'
};

@Injectable({
    providedIn: 'root'
})
export class MapMarkers {
  protected loading = new BehaviorSubject<boolean>(false);
  public list = new BehaviorSubject<any>(undefined);
  public loading$ = this.loading.asObservable();
  connect(): Observable<any> {
      return this.list.asObservable();
  }
  disconnect(): void {
      this.list.complete();
      this.loading.complete();
  }

  markers: MapCoordinates[] = [];

  constructor(private markersService: MapMarkersService) {}

  public getMarkerData({ coordinatesFilter, infoFilter}: { coordinatesFilter: MarkerCoordinatesFilter, infoFilter: MarkerInformationFilter }): void { 
      this.loading.next(true);
      this.markersService.getMarkerCoordinates(coordinatesFilter).pipe(
          catchError(() => observableOf([])),
          finalize(() => this.loading.next(false))
      ).subscribe((response: any) => {
          this.list.next(this.getMarkerColors(response, infoFilter));
      });
  }

  public getMarkerColors(coordinatesResult: MarkerCoordinatesResponse, infoFilter: MarkerInformationFilter): void {
      const { list } = coordinatesResult;
      const locomotives = list.map(markerInfo => markerInfo.lvId);

      this.loading.next(true);
      this.markersService.getMarkersInformation(locomotives, infoFilter).pipe(
          catchError(() => observableOf([])),
          finalize(() => this.loading.next(false))
      ).subscribe((response: MarkerInfo[]) => {
          this.list.next(this.processData(response, list));
      });
  }

  processData(locomotives: MarkerInfo[], list: MarkerList[]): void {
      const formattedData: MapCoordinates[] = [];

      for (const locomotive of locomotives) {
          const { operational_status,  operational_status_text, device_name, location } = locomotive || {};
          const iconType = StatusMarkerIcons[operational_status as keyof typeof StatusMarkerIcons];
          const listItem = list.find(item => item.lvId === locomotive.device_name);

          const lat = location ? location.lat : listItem?.geoSpatialData.latitude;
          const long = location ? location.lon : listItem?.geoSpatialData.longitude;

          if (iconType != undefined) {
              formattedData.push({
                  label: operational_status_text,
                  latitude: lat,
                  longitude: long,
                  markerInfo: device_name,
                  placement: 'right',
                  iconUrl: `./assets/icons/${iconType}_m.svg`,
                  type: iconType
              });
          }
      }

      this.markers = formattedData;
  }
}