import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppStorageService {

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFromAppStorage(name:string):any {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(localStorage.getItem(name));
    }

    setToAppStorage(name:string, value:string):void {
        localStorage.setItem(name, value);
    }

    removeFromAppStorage(name:string):void {
        localStorage.removeItem(name);
    }
}
