import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import * as Highcharts from 'highcharts';
import { DataObject } from '../../model/aggregate-output';
interface eventDataI {
    name?: string | undefined,
    y?: number | undefined
}
@Component({
    selector: 'fuel-app-fuel-usage',
    templateUrl: './fuel-usage.component.html',
    styleUrls: ['./fuel-usage.component.scss']
})
export class FuelUsageComponent implements OnInit, OnChanges {
    Highcharts: typeof Highcharts = Highcharts;
    @Input() innerSize!: string;
    chartOptions!: Highcharts.Options;
    @Input() totalUsage!: number | undefined;
    @Input() fuelData: Array<DataObject> = [];
    @Input() infoTemplate: string | undefined;
    @Output() hoverPieSlice: EventEmitter<number> = new EventEmitter<number>();
    chartData: Array<eventDataI> = [];
    DataEmpty = true;
    hashFuelData: Map<string, number> = new Map<string, number>();
    unitConversion!: UnitConversion;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {
        console.log('onInit');
    }

    ngOnChanges(): void {
        if (this.fuelData) {
            this.chartData = [];
            this.chartData = this.generateData(this.fuelData);
            if (this.chartData.length > 0) {
                this.DataEmpty = false;
                this.chartOptions = this.generateChartOptions(this.chartData);
            } else {
                this.DataEmpty = true;
            }
        }
    }

    generateData(response: Array<DataObject>): Array<eventDataI> {
        this.hashFuelData = new Map<string, number>();
        const Data: Array<eventDataI> = [];
        for (const data of response) {
            if (data.current_loco_state !== 'Others') {
                //this.totalFuelCount += Number(fuelData.result);
                const key = String(data.current_loco_state);
                if (this.hashFuelData.get(key) !== undefined) {
                    this.hashFuelData.set(key, (Number(this.hashFuelData.get(key)) + Number(data.result)));
                } else {
                    this.hashFuelData.set(key, Number(data.result));
                }
            }
        }
        if (this.hashFuelData != undefined) {
            for (const data of this.hashFuelData.keys()) {
                Data.push({
                    name: data.split('_').join(' '),
                    y: Number(this.hashFuelData.get(data))
                });

            }
        }
        return Data;
    }

    generateChartOptions(chartData: Array<eventDataI>): Highcharts.Options {
        // eslint-disable-next-line unicorn/no-this-assignment
        const _this = this;//chart self reference
        return {
            chart: {
                plotBackgroundColor: undefined,
                plotBorderWidth: undefined,
                plotShadow: false,
                type: 'pie',
                marginLeft: 0,
                marginBottom: 0
            },
            colors: ['#066DA1', '#007DBA', '#CCE5F1', '#61D883', '#DFF7E6', '#53B575', '#80DF9B'],
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: 'Fuel Used: <b>{point.y}</b>',
                style: {
                    fontSize: '10',
                }, 
            },
            legend: {
                useHTML: true,
                title: {
                    // text: '<b style="padding-left:2em;">Fuel Usage details</b>',
                    text: '',
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                        textAlign: 'right'
                    }

                },
                itemStyle: {
                    fontWeight: '8',
                    fontFamily: 'Roboto',
                },
                labelFormat: '<div style="font-size: 16px;" click="console.log(1)">{percentage:.1f}' + ' % - ' + '{name}</div>',
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 8,
                symbolRadius: 8,
                /* itemWidth: 180,
                maxHeight: 300, */
                x: -280,
                y: -10,
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,

                    },
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false; // <== returning false will cancel the default action
                            }
                        }
                    },
                    showInLegend: true,
                    states: {
                        hover: {
                            brightness: 0,
                            lineWidthPlus: 0,
                            halo: undefined
                        }
                    }

                },

            },
            series: [{
                type: 'pie',
                data: chartData,
                innerSize: this.innerSize,
                point: {
                    events: {
                        mouseOver: function() {
                            if (this.y) {
                                _this.hoverPieSlice.emit(this.y);
                            }
                        },
                        legendItemClick: function() {
                            if (this.y) {
                                _this.hoverPieSlice.emit(this.y);
                            }
                            return false;
                        }
                    }
                },
                
            }]
        };
    }
}
