import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { SiteLayoutComponent } from './components/site-layout/site-layout.component';
import { SiteSidemenuComponent } from './components/site-sidemenu/site-sidemenu.component';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { MaterialModule } from '../shared/material.module';


@NgModule({
    declarations: [SiteHeaderComponent, SiteFooterComponent, SiteLayoutComponent, SiteSidemenuComponent],
    imports: [
        CommonModule,
        LayoutsRoutingModule,
        MaterialModule,
    ],
    exports: [SiteHeaderComponent, SiteFooterComponent, SiteLayoutComponent, SiteSidemenuComponent]
})
export class LayoutsModule {}
