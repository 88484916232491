/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable, of } from 'rxjs';
import { AppStorage } from '../../../data/app-storage';
import { Token } from '../../../data/token';
export class FakeAuthService {

    access_token: string;
    refresh_token: string;
    storageName = 'data';

    constructor() {}

    public isAuthenticated(): boolean {
        return;
    }

    public login(name:string, key:string): Observable<Token>{ return; }

    logout(): void {}

    refreshToken(): Observable<Token>  {
        return ;
    }

    getSessionDetails(): AppStorage { return; }

    setSessionDetails(data:Token, userData:object, tenantSpecificData:object, permissions:string[]): void {}

    getScopeValues(): {zoneId: string, tenantId: string} {
        return { zoneId: '', tenantId: '' };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getHeaders() {
        return {};
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getTenantBasedConfig() {
        const result = {
            'tenant': 'predix-appliance-06-tenant-01',
            'devices': [
                'LDRSV',
                'FTM'
            ],
            'commonParameters': [
                {
                    'customerId': 'Cust. ID',
                    'locoId': 'Loco ID',
                    'messageTime': 'Last Communication',
                    'milepost': 'Milepost',
                    'station': 'Station',
                    'location': 'Location'
                }
            ],
            'deviceParameters': [
                {
                    'deviceName': 'LDRSV',
                    'parameters': {
                        'Generator_Field': 'Generator Field',
                        'Headlight': 'Headlight',
                        'Forward': 'Forward',
                        'Reverse': 'Reverse',
                    }
                }
            ]
        };
        return of(result);
    }
}
