/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { MapsConfig } from './utilities/maps-config';
import { GmapComponent } from './components/gmap/gmap.component';
import { ExportCSVService } from './../core/utilities/export-csv.service';
import { LoginComponent } from './components/login/login.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { TruncatePipe } from './utilities/truncate-pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { ToastrModule } from 'ngx-toastr';  

@NgModule({
    declarations: [LoginComponent, SpinnerComponent, MultiSelectComponent, BaseTableComponent, TruncatePipe, GmapComponent ,ColumnFilterComponent],
    imports: [
        CommonModule,
        MaterialModule,
        BrowserModule,
        FormsModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AgmJsMarkerClustererModule,
        AgmSnazzyInfoWindowModule,
        AgmCoreModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-right',
            tapToDismiss : true
        })
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA],
    exports : [LoginComponent, SpinnerComponent, MultiSelectComponent, BaseTableComponent , TruncatePipe, GmapComponent, ColumnFilterComponent],
    providers:[ExportCSVService,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        {provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfig}]
})
export class SharedModule {}
