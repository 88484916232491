import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AclService } from '../../../core/services/acl.service';
import { AuthService } from '../../../core/services/auth.service';
import { TenantService } from '../../../core/services/tenant.service';
import { UserManagementService } from '../../../core/services/user-management.service';
import { Token } from '../../../data/token';
import { UserManagement } from '../../../data/user-management';
import { Error } from '../../model/error';
import { LibraryConfigurationProvider } from '../../utilities/library-configuration';

@Component({
    selector: 'el-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showSpinner = false;
  gotoUrl: string;
  error: boolean;
  errorMessage: string;
  loginForm:FormGroup;

  constructor(private route: ActivatedRoute, private router: Router,
    private authService:AuthService,
    private userManagementService:UserManagementService,private aclService:AclService,private formBuilder: FormBuilder, private tenantService: TenantService, private libraryConfigurationProvider:LibraryConfigurationProvider) {
      // this.gotoUrl = this.route.snapshot.queryParams['gotoUrl'] as string || '/views/home';
  }

  ngOnInit():void {
      this.loginForm = this.formBuilder.group({
          // eslint-disable-next-line @typescript-eslint/unbound-method
          username: ['', Validators.required],
          // eslint-disable-next-line @typescript-eslint/unbound-method
          identification: ['', Validators.required]
      });
  }

  login():void { 
      console.log('inside login method');
      this.showSpinner = false;
      this.error = false;
      this.errorMessage = '';
      if (!this.loginForm.valid) {
          return;
      } else{
          this.showSpinner = true;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          this.authService.login(this.loginForm.value.username,this.loginForm.value.identification).subscribe((data:Token)=>{
              this.authService.access_token  = data.access_token;
              data.expires_at = data.expires_in * 1000 + Date.now();
              this.authService.setSessionDetails(data, {}, {}, []);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              this.userManagementService.getUser(this.loginForm.value.username).subscribe((userData:UserManagement) => {
                  this.aclService.getRole(userData.roles[0]).subscribe((roleData) => {
                      this.aclService.getRoleDetailsACL(roleData.roleId).subscribe(() => {
                          this.tenantService.getTenantBasedConfig().subscribe((tenantSpecificData) => {
                              this.authService.setSessionDetails(data, userData, tenantSpecificData, roleData.permissions);
                              this.showSpinner = false;
                              const gotoUrl = this.route.snapshot.queryParams['gotoUrl'] as string || `${this.libraryConfigurationProvider.config['landingPage'] as string}`;
                              this.router.navigateByUrl(gotoUrl);
                          });
                      });
                  });
                  return userData;
              });
          },(error:Error) =>{
              this.error = true;
              console.log(JSON.stringify(error));
              if (error.error.error_description.includes('Bad credentials')) {
                  this.errorMessage = 'Invalid username or password.';
              } else if (error.error.error_description.includes('Your account has been locked')) {
                  this.errorMessage = 'Your account has been locked.';
              } else {
                  this.errorMessage = 'Something went wrong';
              }
              this.showSpinner = false;
          });
      }
   
  }

}
