import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserManagement } from '../../data/user-management';
import { LibraryConfigurationProvider } from '../../shared/utilities/library-configuration';
@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
url:string;
constructor(private http: HttpClient,public libraryConfigurationProvider: LibraryConfigurationProvider) {}

public getUser(userName: string):Observable<UserManagement> {	
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access	
    this.url = this.libraryConfigurationProvider.config ? `${this.libraryConfigurationProvider.config['environment'] as string}/lpr/rest/1.9/users/${userName}` : `/rest/1.9/users/${userName}`;	
    return this.http.get<UserManagement>(this.url, {	
        headers: {	
            'Content-Type': 'application/json'	
        }	
    })	
        .pipe(catchError(error =>{	
            return throwError(error);	
        }));	
}

}
