import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { OverviewRoutingModule } from './overview-routing.module';
import { UiLibraryModule, MaterialModule } from '@edgelinc/ui-library';
import { KpiComponent } from './components/kpi/kpi.component';
import { KpiListViewComponent } from './components/kpi-list-view/kpi-list-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { FuelUsageComponent } from './components/fuel-usage/fuel-usage.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from '../shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { FleetListService } from './services/fleet-list.service';
import { ConsumptionListViewComponent } from './components/consumption-list-view/consumption-list-view.component';

import { OverviewComponent } from './components/overview/overview.component';
@NgModule({
    declarations: [KpiComponent, KpiListViewComponent, FuelUsageComponent, ConsumptionListViewComponent, OverviewComponent],
    imports: [
        SharedModule,
        UiLibraryModule,
        MaterialModule,
        TranslateModule,
        HighchartsChartModule,
        OverviewRoutingModule,
        MatNativeDateModule,
        BrowserModule,
        FormsModule,
        MatButtonModule,
        BrowserAnimationsModule
    ],
    exports: [KpiComponent, KpiListViewComponent, FuelUsageComponent, ConsumptionListViewComponent, OverviewComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [FleetListService]
})
export class OverviewModule {}
