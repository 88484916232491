import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphViewComponent } from './graph-view/graph-view.component';
import { ListViewComponent } from './list-view/list-view.component';
import { ConsumptionProfileRoutingModule } from './consumption-profile-routing.module';
import { MaterialModule } from '@edgelinc/ui-library';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from '../shared/shared.module';
@NgModule({
    declarations: [GraphViewComponent, ListViewComponent],
    imports: [
        SharedModule,
        CommonModule,
        ConsumptionProfileRoutingModule,
        MaterialModule,
        TranslateModule,
        HighchartsChartModule
    ],
    exports: [GraphViewComponent, ListViewComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsumptionProfileModule {}
