/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class ConfigurationStore {
  private internalConfig: any;

  setConfig(config: any): void {
      this.internalConfig = config;
  }
  getConfig(): any {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.internalConfig;
  }
}
