import { MapModule } from './../map/map.module';
import { MaterialModule, UiLibraryModule, MapsConfig} from '@edgelinc/ui-library';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { OverviewModule } from '../overview/overview.module';
import { EventsAlertsModule } from '../events-alerts/events-alerts.module';
import { ConsumptionProfileModule } from '../consumption-profile/consumption-profile.module';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

export let InjectorInstance : Injector;
@NgModule({
    declarations: [DashboardComponent],
    imports: [
        BrowserModule,
        SharedModule, 
        UiLibraryModule,
        TranslateModule,
        MaterialModule,
        ConsumptionProfileModule,
        OverviewModule,
        EventsAlertsModule, 
        DashboardRoutingModule,
        MapModule,
        AgmCoreModule.forRoot(),
    ],
    exports : [DashboardComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers:[
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        {provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfig},
    ]
})
export class DashboardModule {
    constructor(private injector: Injector){
        InjectorInstance = injector;
    }
}
