/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Sort } from '@angular/material/sort';
import { Component, Input, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { IColumns, ITableSettings, emitValue, AuthService, BaseTableComponent } from '@edgelinc/ui-library';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { Aggregate } from '@app/modules/shared/utilities/aggregate';

import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import { RecievedDate } from '@app/modules/shared/model/date';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DateRangePickerComponent } from '@app/modules/shared/components/dateRangePicker/date-range-picker/date-range-picker.component';
import { ConsumptionListDataSource } from '../../services/consumption-list-data-source';

import * as AggregateGroups from '../../model/aggregate-groups';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';
import { AggregateGroupsService } from '@app/modules/shared/services/aggregate-groups.service';

@Component({
    selector: 'fuel-app-consumption-list-view',
    templateUrl: './consumption-list-view.component.html',
    styleUrls: ['./consumption-list-view.component.scss']
})
export class ConsumptionListViewComponent {
    public consumptionDataSourceComp!: ConsumptionListDataSource;
    consumptionPayload!: AggregateGroups.AggregateGroupsPayload;

    filterCount = 0;
    XTotalCount!: number;
    tableColumns: IColumns[] = [];
    tableSettings!: ITableSettings;
    calculateDate!: TimestampCalculation;
    columnHeader: { 'param': string, 'key': string, 'value': string, 'type': string, 'path': string, 'mapping': string, 'disableSelectOpt': boolean }[] = [];
    size = 'small';
    cardName = 'consumption';
    typeString: { 'value': string, 'viewValue': string }[] = [];
    @Input() totCount: number | undefined;
    receivedDate: {
        startTime:number;endTime:number;type:string;cardName?:string;isValid?:boolean;
    } = {
        startTime: 0, endTime: 0, type: '', isValid: false
    };
    calculateDateUtil!: TimestampCalculation;
    unitConversion!: UnitConversion;
    tenantSpecificData!: FuelTenant;
    topModelIds: string[] = [];
    units = '';
    defaultValue = 'Month';
    selectLoco: Locomotive[] = [];
    isSavedSelectedList = false;
    savedSelectedList: Locomotive[] = [];
    locomotives!: Locomotive[];
    filterOptions = 'class eq "CMS"';
    offSet = 0;
    pageSize = 500;
    defaultSortColumn = 'loco_id';
    defaultSortDirection = 'asc';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('filterTemplate', { static: true }) filterTemplate: any;
    @ViewChild('consumptionDateRangePickerTemplate', { static: true }) dateRangePickerTemplate: any;
    @ViewChild(BaseTableComponent, { static: true }) child!: BaseTableComponent;
    @ViewChild(DateRangePickerComponent, { static: true }) dateRangePicker!: DateRangePickerComponent;
    constructor(
        private fuelSessionService: FuelSessionService,
        private aggregateGroupService: AggregateGroupsService,
        private authService: AuthService,
        private router: Router,
        private viewPortScroller: ViewportScroller) {
        this.router.events.pipe(// scroll to top while page is navigated
            filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
    }

    ngOnInit(): void {
        this.calculateDate = new TimestampCalculation();
        const sortState: Sort = { active: 'roadNumber', direction: 'desc' };
        this.typeString = [
            { value: 'eq', viewValue: 'Equal to' }
        ];


        this.calculateDateUtil = new TimestampCalculation();
        this.unitConversion = new UnitConversion();
        if (this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData) {
            this.tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if (this.tenantSpecificData && this.tenantSpecificData.unitToDisplay && this.tenantSpecificData.topModelIds) {
                this.topModelIds = this.tenantSpecificData.topModelIds;
                this.units = Object.keys(this.tenantSpecificData.unitToDisplay)[0];//get tenant specific units
            }
        }
        // set default month start/end time for calling API
        const { startTime, endTime } = this.calculateDateUtil.calculateDate(this.defaultValue);
        if (startTime !== 0 && endTime !== 0) {
            this.receivedDate = { ...this.receivedDate, startTime, endTime, isValid: true };
        }

        this.tableColumns = [
            { field: 'customerId', title: 'Cust. ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'customerId' },
            { field: 'roadNumber', title: 'Loco ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'roadNumber' },
            { field: 'DB', title: 'Notch DB', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N0', title: 'Notch N0', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N1', title: 'Notch N1', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N2', title: 'Notch N2', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N3', title: 'Notch N3', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N4', title: 'Notch N4', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N5', title: 'Notch N5', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N6', title: 'Notch N6', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N7', title: 'Notch N7', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'N8', title: 'Notch N8', isCustomColumn: false, customTemplate: undefined, binding: false },
        ];
        this.columnHeader = [
            { 'param': '', 'key': 'customerId', 'value': 'Cust. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'roadNumber', 'value': 'Loco ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false }
        ];
        this.tableSettings = {
            offSet: 0,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 200, 500],
            defaultSortColumn: 'roadNumber',
            defaultSortDirection: sortState.direction,
            exportFileName: 'consumption_profile_list:DD-MM-YYYY_HH-mm-ss'
        };

        this.consumptionDataSourceComp = new ConsumptionListDataSource(this.aggregateGroupService, this.authService);
    }

    refreshConsumptionList($event?: emitValue): void {
        this.filterCount = 0;
        if (this.consumptionDataSourceComp && this.tableSettings) {
            this.consumptionPayload = this.formRequestPayload({ event: $event });
            this.consumptionDataSourceComp.loadConsumptionGroup(this.consumptionPayload);
        }
    }

    ngAfterContentChecked(): void {
        if (this.consumptionDataSourceComp != undefined) {
            this.XTotalCount = (this.consumptionDataSourceComp.totalCount) <= 5000 ? this.consumptionDataSourceComp?.totalCount : 5000;
        }
    }
    // form payload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formRequestPayload({ event, filters }: { event?: emitValue, filters?: AggregateGroups.CustomFilter[] }): AggregateGroups.AggregateGroupsPayload {
        let receivedDate;
        const aggregate = new Aggregate();
        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession());
        if (this.receivedDate.isValid && this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0) {// pass date range picker value to API
            receivedDate = this.receivedDate;
        } else {
            if (fuelSession && fuelSession.card) {//get stored card data from localStorage
                const consumption = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'consumption');
                if (consumption?.type != undefined) {
                    receivedDate = (consumption?.type !== 'Custom') ? this.calculateDate.calculateDate(consumption?.type) : consumption;
                }
            }
        }
        if (this.child && this.child.emitValue) {
            event = this.child.emitValue;
        }
        const assetInfo: string[] = (fuelSession && fuelSession.locomotives) ? fuelSession.locomotives.map((x: any) => x.device_name) : [];
        const sort: AggregateGroups.Sort = {
            columnName: event?.sortActive || 'roadName',
            direction: event?.sortDirection.toUpperCase() || 'ASC'
        };
        const pagination: AggregateGroups.Pagination = {
            offset: event?.offset.toString() || 0,
            limit: event?.pageSize.toString() || 25
        };
        const customFilters: AggregateGroups.CustomFilter[] = filters || [];
        if (assetInfo.length > 0 && !filters) {
            customFilters.push({
                columnName: 'locomotives',
                condition: 'IN',
                value: assetInfo.toString(),
            });
        }
        if (receivedDate) {
            customFilters.push({
                columnName: 'eventTime',
                condition: 'BETWEEN',
                value: `${receivedDate.startTime}&${receivedDate.endTime}`,
            });
        }

        const consumptionProfile = aggregate.createGroupObject('ConsumptionProfile', 'RATIO', 'Notch_State_Change', {
            numerator: 'total_fuel_consumed',
            denominator: 'total_duration',
            multiplier: 3600
        }, ['current_notch', 'locomotives', 'total_fuel_consumed_unit', 'roadNumber', 'customerId']);

        const aggregationGroup: AggregateGroups.AggregateGroupsPayload = {
            sort,
            pagination,
            customFilters,
            operations: [consumptionProfile]
        };
        return aggregationGroup;
    }
    // go back to homepage
    // goBack(): void {
    //     this.location.back();
    // }
    // show/hide base table filter pop up
    showHideFilter(): void {
        this.child.showHideFilter();
    }
    // call when date range value is changed
    receivedDateValue(receivedDate: RecievedDate): void {
        this.receivedDate = receivedDate;
        if (this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0 && this.receivedDate.isValid) {//call API is date range is valid;future dates not allowed
            this.refreshConsumptionList();
        }
    }
    applyFilter($event: any[]): void {
        const customFilters: AggregateGroups.CustomFilter[] = [];
        if ($event == undefined || $event.length === 0) {
            return;
        }

        for (const filter of $event) {
            const condition = this.getCondition(filter.columnType);
            if (condition !== undefined) {
                customFilters.push({ columnName: filter.columnName?.key, condition, value: filter.fromTextfield });
            }
        }

        this.filterCount = customFilters.length;
        const customerIdFilter = customFilters.find(filter => filter.columnName === 'customerId');
        const roadNumberFilter = customFilters.find(filter => filter.columnName === 'roadNumber');

        if (customerIdFilter && roadNumberFilter) {
            customFilters.push({
                columnName: 'locomotives',
                condition: 'IN',
                value: customerIdFilter.value + '_' + roadNumberFilter.value
            });
        }

        if (this.consumptionDataSourceComp && this.tableSettings) {
            this.consumptionPayload = this.formRequestPayload({ filters: customFilters });
            this.consumptionDataSourceComp?.loadConsumptionGroup(this.consumptionPayload);
        }
    }
    getCondition(value?: string): string | undefined {
        switch (value) {
        case 'eq':
            return 'EQUAL';
        case 'lt':
            return 'LESSER_THAN';
        case 'gt':
            return 'GREATER_THAN';
        case 'btw':
            return 'BETWEEN';
        case 'lte':
            return 'LESSER_THAN_EQUAL';
        case 'gte':
            return 'GREATER_THAN_EQUAL';
        case 'contains':
            return 'CONTAINS';
        default:
            return;
        }
    }
}
