/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { of as observableOf } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService, BaseTableDataSource } from '@edgelinc/ui-library';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import { AggregateGroupsService } from '@app/modules/shared/services/aggregate-groups.service';

import * as AggregateGroups from '../../overview/model/aggregate-groups';
import { TableColumns } from '../../overview/model/aggregate-output';

@Injectable({
    providedIn: 'root'
})
export class ConsumptionListDataSource extends BaseTableDataSource {
    unitToDisplay: string | undefined;
    unitToConvert: string | undefined;
    unitConversion!: UnitConversion;
    displayedFuel: string | undefined;
    totCount!: number;

    dataEmpty = true;
    updateFlag = true;
    notches: string[] = [];

    columns: TableColumns[] = [];

    get totalCount(): number {
        return this.totCount;
    }
    get tableColumns(): TableColumns[] {
        return this.columns;
    }
    constructor(private aggregateGroupService: AggregateGroupsService, private authService: AuthService) {
        super();
    }
    loadConsumptionGroup(eventGroup: AggregateGroups.AggregateGroupsPayload) {
        this.unitConversion = new UnitConversion();
        if (this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData) {
            const tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if (tenantSpecificData && tenantSpecificData.unitToDisplay) {
                this.unitToDisplay = Object.values(tenantSpecificData.unitToDisplay)[0] as string;
                this.unitToConvert = Object.keys(tenantSpecificData.unitToDisplay)[0];
            }
        }
        this.loading.next(true);
        this.aggregateGroupService.getAggregateGroups(eventGroup).pipe(
            // tslint:disable-next-line: deprecation
            catchError(() => observableOf([])),
            finalize(() => this.loading.next(false))
        )
            .subscribe((events: any) => {
                this.list.next(this.processGroupData(events));
                this.generateTableColumns(events);
            });
    }

    processGroupData(data: AggregateGroups.AggregationConsumptionGroupsResponse): AggregateGroups.AggregationConsumptionTableItem[] {
        if (data == undefined || data?.length === 0) {
            return [];
        }

        const consumptionData = data.filter(consumption => consumption?.aggregations?.ConsumptionProfile?.length !== 0);

        const formattedResults = consumptionData.map(consumption => {
            const { customerId, roadNumber, aggregations } = consumption;
            const notches: {
                [key: string]: string | number;
            } = {};

            if (aggregations?.ConsumptionProfile?.length) {
                for (const aggregation of aggregations.ConsumptionProfile) {

                    if (aggregation.result) {
                        if (aggregation.total_fuel_consumed_unit) {
                            notches[aggregation.current_notch] = `${aggregation.result} ${aggregation.total_fuel_consumed_unit}`;
                        } else {
                            notches[aggregation.current_notch] = aggregation.result;
                        }
                    } else {
                        notches[aggregation.current_notch] = '-';
                    }
                }
            }

            return { customerId, roadNumber, ...notches };
        });

        this.totCount = formattedResults.length;

        return formattedResults;
    }

    generateTableColumns(response: AggregateGroups.AggregationConsumptionGroupsResponse): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const data = response[0]?.aggregations?.ConsumptionProfile?.sort((a, b) => (a.current_notch > b.current_notch) ? 1 : -1);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.notches = [];
        data?.map((data) => {
            if (data.current_notch && !this.notches.includes(data.current_notch)) {//get all notches value
                this.notches.push(data.current_notch);
            }
        });

        const tableColumns:TableColumns[] = [
            { field: 'roadNumber', title: 'Loco ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'roadNumber' },
            { field: 'customerId', title: 'Cust ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'customerId' },
        ];

        for (const notch of this.notches) {
            tableColumns.push({ field: notch, title: `Notch ${notch}`, isCustomColumn: false, customTemplate: undefined, binding: false });
        }

        this.columns = tableColumns;
    }
    validateUnit(unitToConvert: string | undefined, total_fuel_unit: string, result: number): string {
        if (total_fuel_unit && (unitToConvert == total_fuel_unit)) {
            //units matched and convert to K,M,G format for display
            this.displayedFuel = this.unitConversion.convertFormat(result);
        } else {
            // units not matched;convert to tenantSpecific units and convert to K,M,G format for display
            this.displayedFuel = this.unitConversion.convertFormat(this.unitConversion.convertUnits(result, this.unitToConvert));
        }
        return this.displayedFuel;
    }
}
