import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class ToastrNotification {

    constructor(private toastr: ToastrService) {}

    showNotification(message: string, title: string): void{
     
        if(title=='success'){
            this.toastr.success(message, 'Success', {});
        }else if(title=='error'){
            this.toastr.error(message, 'Error', {});
        }
    }
}