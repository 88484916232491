<div class='ml-3 mr-3 top mb-4'>
  <div class="row">
    <div class="col mb-2">
      <div class="bredcrumb-container">
        <span class="breadcrumb-active-title" routerLink="/views/home">Home</span><span> / </span>
        <span [innerText]="'map.map' | translate"></span>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12 mb-4'>
      <el-base-table [dataSource]="mapListDataSourceComp" [tableColumns]="tableColumns" [dateRangePicker]="true" [dateRangePickerTemplate]="dateRangePickerTemplate"  [tableSettings]="tableSettings" [showFilterBtn]="true" [XTotalCount]="XTotalCount"  [showInfo]="true" [filterTemplate]="filterTemplate" [filterCount]="filterCount" (refreshList)="refreshMapList($event)" [size]="size"></el-base-table>
      <ng-template #filterTemplate>
        <el-column-filter [columnHeader]="columnHeader" [typeString]="typeString" (filterCloseEvent)="showHideFilter()" (filterApplyEvent)="applyFilter($event)" (filterClearAllEvent)="refreshMapList()"></el-column-filter>
      </ng-template>
      <ng-template #dateRangePickerTemplate>
        <fuel-app-date-range-picker (emitDateValue)="receivedDateValue($event)" [startTime]="receivedDate.startTime" [endTime]="receivedDate.endTime"></fuel-app-date-range-picker>
      </ng-template>
    </div>
  </div>
</div>