/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { of as observableOf } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService, BaseTableDataSource } from '@edgelinc/ui-library';
import moment from 'moment';

import { MapMarkersService } from '@app/modules/shared/services/map-markers.service';
import { MarkerCoordinatesFilter, MarkerCoordinatesResponse, MarkerList, MarkerTableRow } from '../model/marker';

@Injectable({
    providedIn: 'root'
})
export class MapListDataSource extends BaseTableDataSource {
  totCount!: number;
  get totalCount(): number {
      return this.totCount;
  }

  constructor(private markersService: MapMarkersService, private authService: AuthService) {
      super();
  }
  loadMapItems(filter: MarkerCoordinatesFilter) {
      this.loading.next(true);
      this.markersService.getMarkerCoordinates(filter).pipe(
      // tslint:disable-next-line: deprecation
          catchError(() => observableOf([])),
          finalize(() => this.loading.next(false))
      ).subscribe((data: any) => {
          this.list.next(this.processData(data));
      });
  }
  processData(data: MarkerCoordinatesResponse): MarkerTableRow[]{
      const { list } = data;
      this.totCount = list.length;

      return list.map((marker: MarkerList) => ({
          customerId: marker.customerId,
          roadNumber: marker.locoId,
          messageTime: moment(marker.messageTime).format('DD-MM-YYYY HH:mm:ss'),
          mp: marker.geoSpatialData.milepost,
          subdivision: marker.geoSpatialData.station,
          location: marker.geoSpatialData.location,
          fuel_level:'-'
      }));
  }
}
