/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import configureMeasurements from 'convert-units';
export class UnitConversion{
    value!: number;
    index!:number;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    // convert to 'K','M','G','T','P','E' data format
    convertFormat(value:number):string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        if(!value) return '';
        if(value<99999) {
            return String(value.toFixed(2));
        }
        const sizes = ['k','M','G','T','P','E'];
        for (let index = 0; index < sizes.length; index++) {
            value = Number((value/1000).toFixed(2));
            this.index = index;
            if(value<=99999) break;
        }
        // return value as 100M,100G
        return String(value) + sizes[this.index];
    }
    // unit conversion gallons to liters and vice-versa
    convertUnits(value: number, to: string | undefined):number {
        let convertedValue = 0;
        if(to == 'liters'){// gallons to liters
            convertedValue = configureMeasurements(value).from('gal').to('l');
        } else if(to == 'gallons'){// liters to gallons
            convertedValue = configureMeasurements(value).from('l').to('gal');
        } else{// no units matching;no conversion required
            convertedValue = value;
        }
        return convertedValue;
    }
    decimalRound(value:number):string{
        return (value - Math.floor(value)) !== 0 ? String(value.toFixed(2)) : String(value);
    }
  
}
