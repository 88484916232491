
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { LayoutsService } from '../../services/layouts.service';

@Component({
    selector: 'el-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {

  sidebarToggle: boolean;
  checked: boolean;
  loggedInUser: string;
  loggedInUserInitials: string;

  constructor(private layout: LayoutsService, private authService:AuthService) {}

  ngOnInit(): void {
      this.layout.toggleSidebar.subscribe(value => this.sidebarToggle = value);
      const session = this.authService.getSessionDetails()?.userData;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.loggedInUser = session ? session['name'] : '';
      this.loggedInUserInitials = this.getNameInitials(this.loggedInUser);
      if (this.loggedInUser === undefined || this.loggedInUser === '') {
      // this.authService.toLogin();
      }
  }

  logout(): void {
      this.authService.logout();
  }


  toggleSidebar(): void {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      this.layout.drawer.toggle();
      window.dispatchEvent(new Event('resize'));
  }

  getNameInitials(fullName: string): string {
      if (!fullName) {
          return '';
      }
      return ((fullName.includes(' ') ? fullName.split(' ') : (fullName.includes('_') ? fullName.split('_') : [fullName])).map((name) => {
          return name[0];
      })).join('');
  }
}
