/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Sort } from '@angular/material/sort';
import { Component, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { IColumns, ITableSettings, AuthService, BaseTableComponent, emitValue, MultiSelectComponent } from '@edgelinc/ui-library';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import { RecievedDate } from '@app/modules/shared/model/date';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DateRangePickerComponent } from '@app/modules/shared/components/dateRangePicker/date-range-picker/date-range-picker.component';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { EventsListDataSource } from '../../services/events-list-data-source';
import { EventsService } from '@app/modules/shared/services/events-list.service';
import { CustomFilter } from '../../model/filter';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { Event } from '../../model/table';

type EventsList = { value: string; name: string; }[];

@Component({
    selector: 'fuel-app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
    eventsListDataSourceComp!:EventsListDataSource;
    eventsListPayload!: CustomFilter;

    dropDownList:EventsList = [];
    defaultDisplayText = 'name';

    filterCount = 0;
    typeString: { 'value': string, 'viewValue': string }[] = [];
    tableColumns: IColumns[] = [];
    tableSettings!: ITableSettings;
    calculateDate!: TimestampCalculation;
    columnHeader: { 'param': string, 'key': string, 'value': string, 'type': string, 'path': string, 'mapping': string, 'disableSelectOpt': boolean }[] = [];
    XTotalCount = 0;
    cardName = 'events';
    size = 'small';

    tenantSpecificData!: FuelTenant;
    eventValues:string[] = [];


    defaultValue = 'Today';
    endVariation = 0;
    startTime = 0;
    endTime = 0;
    receivedDate: {
        startTime:number;endTime:number;type:string;cardName?:string;isValid?:boolean;
    } = {
        startTime: 0, endTime: 0, type: this.defaultValue, isValid: false
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('filterTemplate', { static: true }) filterTemplate: any;
    @ViewChild('dateRangePickerTemplate', { static: true }) dateRangePickerTemplate: any;
    @ViewChild('searchSelectTemplate', { static: true }) searchSelectTemplate: any;
    @ViewChild(BaseTableComponent, { static: true }) child!: BaseTableComponent;
    @ViewChild(DateRangePickerComponent, { static: true }) dateRangePicker!: DateRangePickerComponent;
    @ViewChild(MultiSelectComponent, { static: true }) searchSelect!: MultiSelectComponent;
    constructor(
        private authService: AuthService,
        private router: Router,
        private viewPortScroller: ViewportScroller,
        private eventsService: EventsService,
        private fuelSessionService: FuelSessionService) {
        this.router.events.pipe(// scroll to top while page is navigated
            filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
    }

    ngOnInit(): void {
        this.calculateDate = new TimestampCalculation();
        const sortState: Sort = { active: 'roadNumber', direction: 'desc' };
        this.typeString = [
            { value: 'eq', viewValue: 'Equal to' }
        ];
        this.tableColumns = [
            { field: 'alarm_severity', title: 'Severity', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'alarm_severity' },
            { field: 'category', title: 'Event type', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'category' },
            { field: 'timestamp', title: 'Date / Time', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'timestamp' },
            { field: 'customerId', title: 'Cust. ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'customerId' },
            { field: 'roadNumber', title: 'Loco ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'roadNumber' },
            // { field: 'locationName', title: 'Location Name', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'locationName' },
            // { field: 'locationType', title: 'Location Type', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'locationType' },
            { field: 'description', title: 'Description', isCustomColumn: false, customTemplate: undefined, binding: false }
        ];
        this.columnHeader = [
            { 'param': '', 'key': 'customerId', 'value': 'Cust. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'roadNumber', 'value': 'Loco. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': '', 'value': 'Date', 'type': 'Date', 'path': '', 'mapping': '', 'disableSelectOpt': false },
        ];
        this.tableSettings = {
            offSet: 0,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 200, 500],
            defaultSortColumn: 'timestamp',
            defaultSortDirection: sortState.direction,
            exportFileName: 'events_list:DD-MM-YYYY_HH-mm-ss'
        };

        if (this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData) {
            this.tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if (this.tenantSpecificData) {
                this.eventValues = Object.keys(this.tenantSpecificData.topMostEvents);
                this.dropDownList = this.eventValues.map(eventValue => ({
                    value: eventValue,
                    name: this.tenantSpecificData.topMostEvents[eventValue]
                }));

                this.eventValues.push('Snapshot');
                this.dropDownList.push({ name: 'Snapshot', value: 'Snapshot' });
            }
        }

        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession());
        if (fuelSession && fuelSession.card) {//get stored card data from localStorage
            const events = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'events');
            if (events?.type != undefined) {
                const receivedDate = (events?.type !== 'Custom') ? this.calculateDate.calculateDate(events?.type) : events;
                this.receivedDate = receivedDate;
            }
        }

        this.eventsListDataSourceComp = new EventsListDataSource(this.eventsService, this.authService);
    }
    ngAfterContentChecked(): void {
        if (this.eventsListDataSourceComp != undefined) {
            this.XTotalCount = (this.eventsListDataSourceComp.totalCount) <= 5000 ? this.eventsListDataSourceComp?.totalCount : 5000;
        }
    }
    refreshEventsList($event?: emitValue): void {
        this.filterCount = 0;
        if (this.eventsListDataSourceComp && this.tableSettings) {
            this.eventsListPayload = this.formRequestPayload({ event: $event });
            this.eventsListDataSourceComp.loadEvents(this.eventsListPayload);
        }
    }
    // form payload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formRequestPayload({ event, filters }: { event?: emitValue, filters?: CustomFilter }): CustomFilter {
        let receivedDate;
        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession());
        if (this.receivedDate.isValid && this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0) {// pass date range picker value to API
            receivedDate = this.receivedDate;
        } else {
            if (fuelSession && fuelSession.card) {//get stored card data from localStorage
                const events = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'events');
                if (events?.type != undefined) {
                    receivedDate = (events?.type !== 'Custom') ? this.calculateDate.calculateDate(events?.type) : events;
                }
            }
        }
        if (this.child && this.child.emitValue) {
            event = this.child.emitValue;
        }
        const assetInfo: string[] = (fuelSession && fuelSession.locomotives) ? fuelSession.locomotives.map((x: any) => x.loco_id) : [];

        const loco_id_int = filters?.loco_id_int || assetInfo;
        const customFilter: CustomFilter = {
            type: this.eventValues,
            loco_id_int,
            sort_by: event?.sortActive || 'timestamp',
            sort_order: event?.sortDirection || 'desc',
            offset: event?.offset || 0,
            limit: event?.pageSize || 25,
        };

        if (receivedDate) {
            customFilter.from = receivedDate.startTime;
            customFilter.to = receivedDate.endTime;
        }
        if (filters?.customer_id) {
            customFilter.customer_id = filters.customer_id;
        }

        return customFilter;
    }
    // show/hide base table filter pop up
    showHideFilter(): void {
        this.child.showHideFilter();
    }
    // call when date range value is changed
    receivedDateValue(receivedDate: RecievedDate): void {
        this.receivedDate = receivedDate;
    }

    applyFilter($event: any[]): void {
        const customFilters: CustomFilter = {};
        if ($event == undefined || $event.length === 0) {
            return;
        }

        this.filterCount = $event.length;
        for (const filter of $event) {
            if (filter.columnName?.key === 'roadNumber') {
                if (customFilters.loco_id_int?.length) {
                    customFilters.loco_id_int = [...customFilters.loco_id_int, filter.fromTextfield];
                } else {
                    customFilters.loco_id_int = [filter.fromTextfield];
                }
            }
            if (filter.columnName?.key === 'customerId') {
                customFilters.customer_id = filter.fromTextfield;
            }
        }

        if (this.eventsListDataSourceComp && this.tableSettings) {
            this.eventsListPayload = this.formRequestPayload({ filters: customFilters });
            this.eventsListDataSourceComp.loadEvents(this.eventsListPayload);
        }
    }
    selectedEventsList(eventsList: EventsList): void {
        this.eventValues = eventsList.map(event => event.value);
        this.eventsListPayload.type = this.eventValues;
        this.eventsListDataSourceComp.loadEvents(this.eventsListPayload);
    }
    rowClicked(row: Event): void {
        const baseEvents = this.eventsListDataSourceComp.baseEvents;
        const baseEvent = baseEvents.find(event => event.data?.id === row.id);

        if (baseEvent?.data) {
            const { customer_id, loco_id_str, device_model_name, name } = baseEvent.data;
            void this.router.navigate(['/views/event'], {
                queryParams: {
                    customer_id,
                    loco_id_str,
                    device_model_name,
                    name,
                    timestamp: baseEvent.timestamp
                }
            });
        }
    }
}
