import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';

type DialogData = {
    locomotives: Locomotive[],
    itemRemove: (locomotive: Locomotive) => void,
    removeAllItems: () => void,
}

@Component({
    selector: 'fuel-app-selected-locomotives-dialog',
    templateUrl: './selected-locomotives-dialog.component.html',
    styleUrls: ['./selected-locomotives-dialog.component.scss']
})
export class SelectedLocomotivesDialogComponent implements OnInit {
  locomotives: Locomotive[] = [];

  constructor(private dialogReference: MatDialogRef<SelectedLocomotivesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.locomotives = data.locomotives;
  }

  ngOnInit(): void {
      console.log('on init');
  }

  itemRemove(item: Locomotive): void {
      const updatedLocomotives = this.locomotives.filter(locomotive=> locomotive.device_name !== item.device_name);

      this.locomotives = updatedLocomotives;
      this.data.itemRemove(item);
  
      if (updatedLocomotives.length <= 5) {
          this.dialogReference.close();
      }
  }
}
