import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStorageService } from './utilities/app-storage.service';



@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [AppStorageService]
})
export class CoreModule {}
