import { SharedModule } from './shared/shared.module';
import { LayoutsModule } from './layouts/layouts.module';
import { DataModule } from './data/data.module';
import { CoreModule } from './core/core.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppStorageService } from '../lib/core/utilities/app-storage.service';
import { RequestInterceptor } from './core/interceptor/request.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LibraryConfiguration as LibraryConfiguration } from './shared/utilities/library-configuration';
@NgModule({
    declarations: [],
    imports: [
    ],
    providers: [AppStorageService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }
    ],
    exports: [CoreModule, DataModule, LayoutsModule, SharedModule]
})
export class UiLibraryModule { 
    static forRoot(libraryConfiguration: LibraryConfiguration): ModuleWithProviders<UiLibraryModule> {
        return {
            ngModule: UiLibraryModule,
            providers: [
                libraryConfiguration.config
            ],
        };
    }
}
