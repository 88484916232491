import { AggregateInput, Filter, AggregateOperation } from '@app/modules/overview/model/aggregrate-input';

export class Aggregate {
   
    creatObjects( operationId: string, operation: string, eventName: string, eventAttribute: unknown, filter?: Filter, groupBy?: string[], sort?:{'columnName': string,'direction': string}, pagination?: { 'offset': string; 'limit': string } ): AggregateInput {
        const aggregateInput = new AggregateInput();
        aggregateInput.operationId= operationId;
        aggregateInput.operation= operation;
        aggregateInput.eventName= eventName;
        aggregateInput.eventAttribute= eventAttribute;
        aggregateInput.filter= filter; 
        aggregateInput.groupBy = groupBy;
        aggregateInput.sort = sort;
        aggregateInput.pagination = pagination;
        return aggregateInput;
    }

    createGroupObject(operationId: string, operation: string, eventName: string, eventAttribute: string | number | {[key: string]: string | number;}, groupBy?: string[]): AggregateOperation {
        const aggregateOperation = new AggregateOperation();
        aggregateOperation.operationId= operationId;
        aggregateOperation.operation= operation;
        aggregateOperation.eventName= eventName;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        aggregateOperation.eventAttribute= eventAttribute;
        aggregateOperation.groupBy = groupBy;
        return aggregateOperation;
    }
}
