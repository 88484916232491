import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteLayoutComponent, AuthGuardService as AuthGuard} from '@edgelinc/ui-library';
import { ListViewComponent } from './components/list-view/list-view.component';

const routes: Routes = [
    {
        path: 'views', 
        component: SiteLayoutComponent,
        children: [
            { path: 'map/list', component: ListViewComponent, canActivate: [AuthGuard]}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class MapRoutingModule {}
