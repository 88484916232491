/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthService } from './../../../core/services/auth.service';
import { Component, OnInit, Inject, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { LAZY_MAPS_API_CONFIG } from '@agm/core';
import { LibraryConfigurationProvider } from '../../utilities/library-configuration';
import { MapCoordinates } from '../../interfaces/map';

@Component({
    selector: 'el-gmap',
    templateUrl: './gmap.component.html',
    styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit {
  innerWidth;
  bounds = true;
  _markers : MapCoordinates[];
  mapTypeControl;
  mapTypeControlOptions;
  mapControlSize = 26;
  minZoom;
  maxZoom;
  zoom;
  center;
  mapTypeId: string;
  mapApiKey;
  mapTypes = ['roadmap', 'satellite'];
  parameters = [];
  columnHeader;
  imagePath = 'assets/images/map/m';
  map: any;
  dragend = false;
  markersSize = {
      height: 32,
      width: 24
  };
  tenantSpecificData = {};

  @Input() lang;
  @Input() height = '70vh';

  @Input() bottomLeftLegendTemplate;
  @Input() toprightLegendTemplate;
  @Input() tooltipInfo;

  @Input() set mapType(value: string) {
      if(value) this.mapTypeId = value;
  }

  @Input() set markers(items: any) {
      if (!items || items.length === 0) {
          this._markers = [];
      } else {

          this._markers = [];
          const _markers = [];

          for (const item of items) {
              if (item) {
                  _markers.push(item);
              }
          }
          this.innerWidth = window.innerWidth;
          console.log('markers---->');
          console.log(_markers);
          this._markers = _markers;
      }
  }
  @Output() clickMarker = new EventEmitter<MapCoordinates>();

  get markers() {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this._markers;
  }

  constructor(private libraryConfigurationProvider:LibraryConfigurationProvider, private authService: AuthService, @Inject(LAZY_MAPS_API_CONFIG) public mapConfig) {
      if (this.libraryConfigurationProvider && this.libraryConfigurationProvider.config && this.libraryConfigurationProvider.config['domainConfig']) {
          this.minZoom = this.libraryConfigurationProvider.config['domainConfig']['MIN_ZOOM'];
          this.maxZoom = this.libraryConfigurationProvider.config['domainConfig']['MAX_ZOOM'];
          this.zoom = this.libraryConfigurationProvider.config['domainConfig']['DEFAULT_ZOOM'];
          this.center = this.libraryConfigurationProvider.config['domainConfig']['DEFAULT_CENTER'];
          this.mapTypeId = this.libraryConfigurationProvider.config['domainConfig']['DEFAULT_MAP_TYPE'];
          this.mapApiKey = this.libraryConfigurationProvider.config['domainConfig']['GOOGLE_API_KEY'];
      }
      this.tenantSpecificData = this.authService.getSessionDetails() ? this.authService.getSessionDetails().tenantSpecificData : {};
      this.getMapKey();
  }

  ngOnInit(): void {
      this.mapTypeControl = true;
      this.mapTypeControlOptions = {
          mapTypeIds: this.mapTypes,
          position: 1,
          style: 0
      };
      this.tenantSpecificData = this.authService.getSessionDetails() ? this.authService.getSessionDetails().tenantSpecificData : {};
      this.getMapKey();
  }

  ngOnChanges(changes: SimpleChanges) : void {
      const current_value = changes.markers.currentValue ? changes.markers.currentValue.length : false;
      const previous_value = changes.markers.previousValue == undefined ? 0 : changes.markers.previousValue.length;
      if (current_value !== previous_value) {
          this.setCenterMap();
      } else if((current_value == previous_value) && !this.dragend ) {
          this.setCenterMap();
      }
  }

  clickedMarker(marker: MapCoordinates) : void{
      this.dragend = true;
      this.clickMarker.emit(marker);
  }

  mapClicked($event: MouseEvent): void{
      this.dragend = true;
      this._markers.push({
          lat: $event,
          lng: $event
      });
  }

  mapReady(map: any) : void {
      this.map = map;
      const legend_bottom_left = document.querySelector('#legend-bottom-left');
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend_bottom_left);
      const legend_top_right = document.querySelector('#legend-top-right');
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(legend_top_right);

      this.map.addListener('dragend', function () {
          this.dragend = true;
      });
  }

  setCenterMap(): void {
      if (typeof google !== 'undefined' && this._markers && this._markers.length > 0) {
          const bounds = new google.maps.LatLngBounds();
          for (const v of this._markers) {
              if (v['latitude'] && v['longitude']) {
                  bounds.extend(new google.maps.LatLng(v['latitude'], v['longitude']));
              }
          }
          this.zoom = this.getBoundsZoomLevel(bounds, { width: this.innerWidth, height: 500 }) - 1;
          this.center = { latitude: bounds.getCenter().lat(), longitude: bounds.getCenter().lng() };

          if(this.map) {
              this.map.panTo(new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng()));
              this.map.setZoom(this.zoom);
          }
      }
  }


  getBoundsZoomLevel(bounds: any, mapDim: any) : number {
      const WORLD_DIM = { height: 256, width: 256 };
      const ZOOM_MAX = 21;

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const latFraction = (this.latRad(ne.lat()) - this.latRad(sw.lat())) / Math.PI;

      const lngDiff = ne.lng() - sw.lng();
      const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

      const latZoom = this.zoomInternal(mapDim.height, WORLD_DIM.height, latFraction);
      const lngZoom = this.zoomInternal(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  zoomInternal(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  /**
   * Center map
   */
  centerMap() {
      if(this.map) {
          this.map.panTo(new google.maps.LatLng(this.center['latitude'], this.center['longitude']));
          this.map.setZoom(this.zoom);
      }
  }

  centerChange(event) {
      console.log('[center:changed]',event);
  }
  
  getMapKey(){
      if(this.mapConfig){
          this.mapConfig.apiKey = (this.tenantSpecificData['common'] && this.tenantSpecificData['common'].google_api_key) ? this.tenantSpecificData['common'].google_api_key:this.mapApiKey;
      }
  }
}

