import * as moment from 'moment';
import { Date } from '../model/date';

export class TimestampCalculation {
    startTime!: number;
    endTime! :number;
    calculateDate(event: string):Date{
        this.startTime = 0;
        this.endTime = 0;
        if(event == 'Today'){
            this.startTime = moment().startOf('day').valueOf();
            this.endTime = moment().valueOf();
        } else if(event == 'Month'){
            this.startTime = moment().startOf('month').valueOf();
            this.endTime = moment().valueOf();
        }
        else if(event == 'Year'){
            this.startTime = moment().startOf('year').valueOf();
            this.endTime =  moment().valueOf();
        } 
        return {'startTime':this.startTime,'endTime':this.endTime,'type':event};
    }
    calculateVariation(type:string):Date{
        if(type !=='Custom'){
          
            if(type === 'Today'){
                this.startTime = moment().subtract(1, 'days').startOf('day').valueOf();
                this.endTime = moment().subtract(1, 'days').endOf('day').valueOf();
            } else if(type === 'Month'){
                this.startTime = moment().subtract(1, 'months').startOf('month').valueOf();
                this.endTime = moment().subtract(1, 'months').endOf('month').valueOf();
            } else if(type === 'Year'){
                this.startTime = moment().subtract(1, 'years').startOf('year').valueOf();
                this.endTime = moment().subtract(1, 'years').endOf('year').valueOf();
            }
        } else {
          
            this.startTime = 0;
            this.endTime = 0;
        }

        return {'startTime':this.startTime,'endTime':this.endTime,'type':type};
    }
}
