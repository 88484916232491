import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Role } from '../../data/role';
import { RoleDetails } from '../../data/role-details';
import { LibraryConfigurationProvider } from '../../shared/utilities/library-configuration';
import { AuthService } from './auth.service';




@Injectable({
    providedIn: 'root'
})
export class AclService {
 url=''
 constructor(private http:HttpClient,private libraryConfigurationProvider:LibraryConfigurationProvider,private authService:AuthService) {}
 public getRole(role :string):Observable<Role> {
     this.url = this.libraryConfigurationProvider.config ? `${this.libraryConfigurationProvider.config['environment'] as string}/acl/v1/roles/name/${role}` : `/acl/v1/roles/name/${role}`;
       
     return this.http.get<Role>(this.url, {
         headers: {
             'Content-Type': 'application/json;charset=UTF-8',
             'Predix-Zone-Id': this.authService.getHeaders()['Tenant-Id'],
             'Access-Control-Allow-Origin': '*'
         }
     })
         .pipe(catchError(error =>{
             return throwError(error);
         }));
 }

 public getRoleDetailsACL(roleId: number):Observable<RoleDetails> {
     this.url = this.libraryConfigurationProvider.config ? `${this.libraryConfigurationProvider.config['environment'] as string}/acl/roles/${roleId}` : `/acl/roles/${roleId}`;
     return this.http.get<RoleDetails>(this.url, {
         headers: {
             'Content-Type': 'application/json;;charset=UTF-8'
         }
     })
         .pipe(catchError(error =>{
             return throwError(error);
         }));
 }
}
