import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';
import { CustomFilter } from '@app/modules/events-alerts/model/filter';
import { RawEvent } from '@app/modules/events-alerts/model/table';

@Injectable({
    providedIn: 'root'
}) 
export class 
EventsService {
    constructor(private http: HttpClient, private authService: AuthService) {}
  
    public getEvents(filter: CustomFilter):Observable<RawEvent[]>{
        const url = `${environment.base}/lpr/rest/1.9/logs`;

        const formattedFilter: { [param: string]: string | string[]; } = {};

        let filterText = '(';

        for (const key of Object.keys(filter)) {
            const filterValue = filter[key as keyof CustomFilter];

            if ((key === 'type' || key === 'loco_id_int')) {
                if (filter[key]?.length) {
                    let newFilter = `${key} in [`;
  
                    let valuesString = '';
                    filter[key]?.map(value => valuesString += `"${value}",`);
  
                    let editedFilterText = valuesString.slice(0, -1);
                    editedFilterText += ']';
  
                    newFilter += editedFilterText;
  
                    if (filterText.length !== 1) {
                        filterText += ' and ';
                    }
                    filterText += newFilter;
                }
            } else if ((key === 'customer_id')) {
                const value = filter[key];
                if (value != undefined) {
                    const newFilter = `${key} eq "${value}"`;
    
                    if (filterText.length !== 1) {
                        filterText += ' and ';
                    }
                    filterText += newFilter;
                }
            } else if (filterValue != undefined) {
                formattedFilter[key] = filterValue.toString();
            }
        }

        filterText += ')';

        formattedFilter.filter = filterText;

        return this.http.get<RawEvent[]>(url, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type']
            },
            params: formattedFilter,
        }).pipe(map((response: RawEvent[]) => {
            return response;
        }))
            .pipe(catchError(error => {
                return throwError(error);
            }));
    }   
}