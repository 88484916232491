/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Sort } from '@angular/material/sort';
import { Component, Input, ViewChild } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';
import { IColumns, ITableSettings, emitValue, AuthService, BaseTableComponent } from '@edgelinc/ui-library';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { Aggregate } from '@app/modules/shared/utilities/aggregate';
import { KpiListDataSource } from '../../services/kpi-list-data-source';

import { AggregateGroupsService } from '@app/modules/shared/services/aggregate-groups.service';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import { RecievedDate } from '@app/modules/shared/model/date';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DateRangePickerComponent } from '@app/modules/shared/components/dateRangePicker/date-range-picker/date-range-picker.component';

import * as AggregateGroups from '../../model/aggregate-groups';

@Component({
    selector: 'fuel-app-kpi-list-view',
    templateUrl: './kpi-list-view.component.html',
    styleUrls: ['./kpi-list-view.component.scss']
})
export class KpiListViewComponent {
    public kpiListDataSourceComp!: KpiListDataSource;
    filterCount = 0;
    XTotalCount!: number;
    tableColumns: IColumns[] = [];
    tableSettings!: ITableSettings;
    calculateDate!: TimestampCalculation;
    columnHeader: { 'param': string, 'key': string, 'value': string, 'type': string, 'path': string, 'mapping': string, 'disableSelectOpt': boolean }[] = [];
    kpiListPayload!: AggregateGroups.AggregateGroupsPayload;
    size = 'small';
    cardName = 'overview';
    typeString: { 'value': string, 'viewValue': string }[] = [];
    @Input() totCount: number | undefined;
    receivedDate = { 'startTime': 0, 'endTime': 0, 'type': '', 'isValid': false };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('filterTemplate', { static: true }) filterTemplate: any;
    @ViewChild('dateRangePickerTemplate', { static: true }) dateRangePickerTemplate: any;
    @ViewChild(BaseTableComponent, { static: true }) child!: BaseTableComponent;
    @ViewChild(DateRangePickerComponent, { static: true }) dateRangePicker!: DateRangePickerComponent;
    constructor(
        private location: Location,
        private fuelSessionService: FuelSessionService,
        private aggregateService: AggregateGroupsService,
        private authService: AuthService,
        private router: Router,
        private viewPortScroller: ViewportScroller) {
        this.router.events.pipe(// scroll to top while page is navigated
            filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
    }

    ngOnInit(): void {
        this.calculateDate = new TimestampCalculation();
        const sortState: Sort = { active: 'roadNumber', direction: 'desc' };
        this.typeString = [
            { value: 'eq', viewValue: 'Equal to' }
        ];
        this.tableColumns = [
            { field: 'customerId', title: 'Cust. ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'customerId' },
            { field: 'roadNumber', title: 'Loco ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'roadNumber' },
            { field: 'modelID', title: 'Model ID', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'CumulativeFuel', title: 'Cumulative Fuel', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'FuelAdded', title: 'Fuel Added', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'Stationary_Idle', title: 'Stationary Idle Usage', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'Moving_Idle', title: 'Moving Idle Usage', isCustomColumn: false, customTemplate: undefined, binding: false },
            { field: 'running', title: 'Running Usage', isCustomColumn: false, customTemplate: undefined, binding: false }
        ];
        this.columnHeader = [
            { 'param': '', 'key': 'customerId', 'value': 'Cust. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'roadNumber', 'value': 'Loco ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false }
        ];
        this.tableSettings = {
            offSet: 0,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 200, 500],
            defaultSortColumn: 'roadNumber',
            defaultSortDirection: sortState.direction,
            exportFileName: 'kpi_list:DD-MM-YYYY_HH-mm-ss'
        };
        this.kpiListDataSourceComp = new KpiListDataSource(this.aggregateService, this.authService);
    }
    // call agers API to get list view data

    refreshKPIList($event?: emitValue): void {
        this.filterCount = 0;
        if (this.kpiListDataSourceComp && this.tableSettings) {
            this.kpiListPayload = this.formRequestPayload({ event: $event });
            this.kpiListDataSourceComp.loadKPIGroup(this.kpiListPayload);
        }
    }

    ngAfterContentChecked(): void {
        if (this.kpiListDataSourceComp != undefined) {
            this.XTotalCount = (this.kpiListDataSourceComp.totalCount) <= 5000 ? this.kpiListDataSourceComp?.totalCount : 5000;
        }
    }
    // form payload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formRequestPayload({ event, filters }: { event?: emitValue, filters?: AggregateGroups.CustomFilter[] }): AggregateGroups.AggregateGroupsPayload {
        let receivedDate;
        const aggregate = new Aggregate();
        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession());
        if (this.receivedDate.isValid && this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0) {// pass date range picker value to API
            receivedDate = this.receivedDate;
        } else {
            if (fuelSession && fuelSession.card) {//get stored card data from localStorage
                const overview = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'overview');
                if (overview?.type != undefined) {
                    receivedDate = (overview?.type !== 'Custom') ? this.calculateDate.calculateDate(overview?.type) : overview;
                }
            }
        }
        if (this.child && this.child.emitValue) {
            event = this.child.emitValue;
        }
        const assetInfo: string[] = (fuelSession && fuelSession.locomotives) ? fuelSession.locomotives.map((x: any) => x.device_name) : [];
        const sort: AggregateGroups.Sort = {
            columnName: event?.sortActive || 'roadName',
            direction: event?.sortDirection.toUpperCase() || 'ASC'
        };
        const pagination: AggregateGroups.Pagination = {
            offset: event?.offset.toString() || 0,
            limit: event?.pageSize.toString() || 10
        };
        const customFilters: AggregateGroups.CustomFilter[] = filters || [];
        if (assetInfo.length > 0 && !filters) {
            customFilters.push({
                columnName: 'locomotives',
                condition: 'IN',
                value: assetInfo.toString(),
            });
        }
        if (receivedDate) {
            customFilters.push({
                columnName: 'eventTime',
                condition: 'BETWEEN',
                value: `${receivedDate.startTime}&${receivedDate.endTime}`,
            });
        }

        const cumulativeFuel = aggregate.createGroupObject('CumulativeFuel', 'SUM', 'Fuel_Fill', 'total_fuel_change', ['roadNumber', 'customerId', 'total_fuel_change_unit']);
        const fuelAdded = aggregate.createGroupObject('FuelAdded', 'SUM', 'Fuel_Fill', 'total_fuel_change', ['roadNumber', 'customerId', 'total_fuel_change_unit']);
        const fuelUsage = aggregate.createGroupObject('FuelUsage', 'SUM', 'Notch_State_Change', 'total_fuel_consumed', ['roadNumber', 'customerId', 'current_loco_state', 'total_fuel_consumed_unit']);
        const totalFuelUsage = aggregate.createGroupObject('TotalFuelUsage', 'SUM', 'Notch_State_Change', 'total_fuel_consumed', ['roadNumber', 'customerId', 'total_fuel_consumed_unit']);

        const aggregationGroup: AggregateGroups.AggregateGroupsPayload = {
            sort,
            pagination,
            customFilters,
            operations: [
                cumulativeFuel,
                fuelAdded,
                fuelUsage,
                totalFuelUsage
            ]
        };
        return aggregationGroup;
    }
    // go back to homepage
    // goBack(): void {
    //     this.location.back();
    // }
    // show/hide base table filter pop up
    showHideFilter(): void {
        this.child.showHideFilter();
    }
    // call when date range value is changed
    receivedDateValue(receivedDate: RecievedDate): void {
        this.receivedDate = receivedDate;
        if (this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0 && this.receivedDate.isValid) {//call API is date range is valid;future dates not allowed
            this.refreshKPIList();
        }
    }
    applyFilter($event: any[]): void {
        const customFilters: AggregateGroups.CustomFilter[] = [];
        if ($event == undefined || $event.length === 0) {
            return;
        }

        for (const filter of $event) {
            const condition = this.getCondition(filter.columnType);
            if (condition !== undefined) {
                customFilters.push({ columnName: filter.columnName?.key, condition, value: filter.fromTextfield });
            }
        }

        this.filterCount = customFilters.length;
        const customerIdFilter = customFilters.find(filter => filter.columnName === 'customerId');
        const roadNumberFilter = customFilters.find(filter => filter.columnName === 'roadNumber');

        if (customerIdFilter && roadNumberFilter) {
            customFilters.push({
                columnName: 'locomotives',
                condition: 'IN',
                value: customerIdFilter.value + '_' + roadNumberFilter.value
            });
        }

        if (this.kpiListDataSourceComp && this.tableSettings) {
            this.kpiListPayload = this.formRequestPayload({ filters: customFilters });
            this.kpiListDataSourceComp?.loadKPIGroup(this.kpiListPayload);
        }
    }
    getCondition(value?: string): string | undefined {
        switch (value) {
        case 'eq':
            return 'EQUAL';
        case 'lt':
            return 'LESSER_THAN';
        case 'gt':
            return 'GREATER_THAN';
        case 'btw':
            return 'BETWEEN';
        case 'lte':
            return 'LESSER_THAN_EQUAL';
        case 'gte':
            return 'GREATER_THAN_EQUAL';
        case 'contains':
            return 'CONTAINS';
        default:
            return;
        }
    }
}
