import { MaterialModule, SpinnerComponent, UiLibraryModule} from '@edgelinc/ui-library';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedRoutingModule } from './shared-routing.module';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatButtonModule } from '@angular/material/button'; 
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

import { DateRangePickerComponent } from './components/dateRangePicker/date-range-picker/date-range-picker.component';
import { DateDropDownComponent } from './components/dateSelection/date-drop-down/date-drop-down.component';
import { LocomotivesSelectComponent } from './components/locomotives-select/locomotives-select.component';
import { SelectedLocomotivesDialogComponent } from './components/locomotives-select/selected-locomotives-dialog/selected-locomotives-dialog.component';

export let InjectorInstance : Injector;
@NgModule({
    declarations: [DateDropDownComponent,DateRangePickerComponent,LocomotivesSelectComponent,SelectedLocomotivesDialogComponent],
    imports: [
        UiLibraryModule,
        CommonModule,
        MaterialModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SharedRoutingModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        MatDialogModule
    ],
    exports : [DateDropDownComponent,SpinnerComponent,DateRangePickerComponent,LocomotivesSelectComponent,SelectedLocomotivesDialogComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
    constructor(private injector: Injector){
        InjectorInstance = injector;
    }
}
