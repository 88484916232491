<div class="container-fluid popover__content">
    <div class="d-flex justify-content-between">
        <div>
            <p class="popover__message pt-2">Filter</p>
        </div>
        <div class="close" (click)="showHideFilter()">
            <button mat-icon-button>
             <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form #filtersForm="ngForm" id="form-id">
        <div class="row mb-0 mb-md-4" *ngFor="let filter of filters; let i = index;">
            <div class="col-12 col-sm-12 col-md-3">
                <mat-form-field class="rowDropdowns" floatLabel="never" appearance="outline">
                    <mat-label>Select Column</mat-label>
                    <mat-select panelClass="custom-panel-select" (selectionChange)="getColumnCondition($event.value,filter.id,i)" ngDefaultControl (openedChange)="disableColumns()" [(ngModel)]="filter.columnName" name="columnName_{{filter.id}}">
                        <mat-option *ngFor="let columns of columnHeader" [disabled]="columns.disableSelectOpt" [value]="columns">
                            {{columns.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-12 col-md-3 ml-md-1">
                <mat-form-field class="rowDropdowns" floatLabel="never" appearance="outline">
                    <mat-label>Condition</mat-label>
                    <mat-select [disabled]="filter.columnTypeDisabled" (selectionChange)="onConditionChange(filter.id,i)" ngDefaultControl [(ngModel)]="filter.columnType" name="columnType_{{filter.id}}">
                        <mat-option *ngFor="let type of filter.colType" [value]="type.value">
                            {{type.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filter.showDateRange" class="rowDateRangeContainer col-10 col-sm-11 col-md-4 ml-md-1" [ngClass]="{'errorTextfield':filter.showDateRangeErrormsg}">
                  <ng-template [ngTemplateOutlet]="dateRangeTemplate" ></ng-template>
                <div style="color:red;font-size:14px" *ngIf="filter.showDateRangeErrormsg" class="mb-3">Please select a Date</div>
            </div>
            <div *ngIf="!filter.showFromTextField && !filter.fromDropdownfield" [ngClass]="{'col-12 col-sm-12 col-md-2': filter.showToTextField,'col-10 col-sm-11 col-md-4' : !filter.showToTextField}" class="ml-md-1">
                <mat-form-field class="rowTextfield" [ngClass]="{'d-inline-block':!filter.showToTextField,'d-md-block':filter.showToTextField,'errorTextfield':filter.showFromTextFieldErrormsg}" appearance="outline">
                    <mat-label>Value</mat-label>
                    <input matInput [(ngModel)]="filter.fromTextfield" [disabled]="filter.inputDisabled" name="fromTextfield_{{filter.id}}">
                </mat-form-field>
                <div style="color:red;font-size:14px" *ngIf="filter.showFromTextFieldErrormsg" class="mb-3"> Value is required
                </div>
            </div>
            <div *ngIf="filter.showToTextField" class="col-10 col-sm-11 col-md-2 ml-md-0">
                <mat-form-field class="rowTextfield" [ngClass]="{'d-inline-block':!filter.showToTextField,'d-md-block':filter.showToTextField,'errorTextfield':filter.showToTextFieldErrormsg}" appearance="outline">
                    <mat-label>Value</mat-label>
                    <input matInput [(ngModel)]="filter.toTextfield" name="toTextfield_{{filter.id}}">
                </mat-form-field>
                <div style="color:red;font-size:14px" *ngIf="filter.showToTextFieldErrormsg" class="mb-3"> Value is required
                </div>
            </div>
            <div *ngIf="filter.fromDropdownfield" class="col-12 col-sm-12 col-md-3">
                <mat-form-field class="rowDropdowns" floatLabel="never" appearance="outline">
                    <mat-label>Select Value</mat-label>
                    <mat-select (selectionChange)="getDropDownSelect($event.value,filter.id,i)" [(ngModel)]="filter.columnValue" name="columnValue_{{filter.id}}">
                        <mat-option *ngFor="let listType of filter.dropDownList" [value]="listType" innerHTML="{{listType.viewValue}}">
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-1 col-sm-1 col-md-1 mt-3 ml-md-0">
                <button mat-icon-button id="removeFilter" [style.opacity]="(filters.length==1) ? '0.6':'initial' " [disabled]="filters.length==1" (click)="removeFilter(i)">
                <div class='delete-img'></div>
             </button>
            </div>
        </div>
        <div class="footerBtnContainer">
            <div>
                <button class="addFilterBtn" mat-stroked-button (click)="addFilter()" [style.opacity]="(addDisable) ? '0.6':'initial' " [disabled]="addDisable">Add Filter</button>
            </div>
            <div class="footerBtnInline">
                <div>
                    <button mat-button class="clearAll" (click)="clearFilter()">Clear All</button>
                </div>
                <div>
                    <button mat-flat-button color="primary" class="applyBtn" (click)="applyFilter()">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>