<div class='ml-3 mr-3 top mb-4'>
  <div class="row">
    <div class="col mb-2">
      <div class="bredcrumb-container">
        <span class="breadcrumb-active-title" routerLink="/views/home">Home</span><span> / </span>
        <span [innerText]="'events.events' | translate"></span>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12 mb-4'>
      <el-base-table [dataSource]="eventsListDataSourceComp" [tableColumns]="tableColumns" [tableSettings]="tableSettings" [showFilterBtn]="true" [XTotalCount]="XTotalCount"  [showInfo]="true" [filterTemplate]="filterTemplate" [filterCount]="filterCount" (refreshList)="refreshEventsList($event)" [size]="size" [searchSelect]="true" [searchSelectTemplate]="searchSelectTemplate" (clickRow)="rowClicked($event)" [isRowClicked]="true"></el-base-table>
      <ng-template #filterTemplate>
        <el-column-filter [fromDate]="receivedDate.startTime" [toDate]="receivedDate.endTime" [dateRangeTemplate]="dateRangePickerTemplate" [columnHeader]="columnHeader" [typeString]="typeString" (filterCloseEvent)="showHideFilter()" (filterApplyEvent)="applyFilter($event)" (filterClearAllEvent)="refreshEventsList()"></el-column-filter>
      </ng-template>
      <ng-template #dateRangePickerTemplate>
        <div class="filter-date-range">
          <fuel-app-date-range-picker [cardName]="cardName" (emitDateValue)="receivedDateValue($event)"></fuel-app-date-range-picker>
        </div>
      </ng-template>
      <ng-template #searchSelectTemplate>
        <div class='events-selector'>
          <el-multi-select [multiSelectList]="dropDownList" [placeholder]="'Event type'"
          [defaultDisplayText]="defaultDisplayText" (selectedList)="selectedEventsList($event)"></el-multi-select>
        </div>
      </ng-template>
    </div>
  </div>
</div>