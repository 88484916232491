import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map , catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { ConfigurationStore } from '@app-shared/utilities/configurator-helper';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    constructor(private http: HttpClient, private authService: AuthService,private configurationStore:ConfigurationStore) {}
    
    public getConfig(): Observable<FuelTenant> {
        const url = `${environment.base}/config/fuel`;
        return this.http.get<FuelTenant>(url, {
            headers: this.authService.getHeaders()
        }).pipe(
            map((response:FuelTenant) => {
                return response;
            }))
            .pipe(catchError(error => {
                return throwError(error);
            }
            ));
    }
}
