/* eslint-disable unicorn/prefer-array-some */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-array-reduce */
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Date } from '@app/modules/shared/model/date';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import * as Highcharts from 'highcharts';
import { AuthService,AppStorageService } from '@edgelinc/ui-library';
import { Aggregate } from '@app/modules/shared/utilities/aggregate';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import { AggregateOutput, CustomFilters, DataObject } from '@app/modules/overview/model/aggregate-output';
import { AggregateInput } from '@app/modules/overview/model/aggregrate-input';
import { AggregateService } from '@app/modules/shared/services/aggregate.service';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import { BaseLineService } from '../services/base-line.service';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { DateDropDownComponent } from '@app/modules/shared/components/dateSelection/date-drop-down/date-drop-down.component';
import moment from 'moment';

export interface ChartElement {
    color: any;
    model: string;
    value: number;
    base: number | string;
  }
@Component({
    selector: 'fuel-app-graph-view',
    templateUrl: './graph-view.component.html',
    styleUrls: ['./graph-view.component.scss']
})
export class GraphViewComponent implements OnInit {
    displayedColumnHeaders = {};
    displayedColumns = ['color', 'model', 'value', 'base'];
    tableColumns = [
        {'field':'color','title':''},
        {'field':'model','title':'MODEL ID'},
        {'field':'value','title':'CONSUMPTION'},
        {'field':'base','title':'BASE VALUE'}
    ];
    tableColumnsByLoc = [
        {'field':'color','title':''},
        {'field':'model','title':'LOCO ID'},
        {'field':'value','title':'VALUE'},
        {'field':'base','title':'BASE VALUE'}
    ];
    updateFlag = true;
    groupByField = 'assetModel'; 
    ELEMENT_DATA: ChartElement[] = [];
    dataSource = this.ELEMENT_DATA;
    Highcharts: typeof Highcharts = Highcharts;
    lineChartOptions!:Highcharts.Options;
    units = '';
    notchValue = 'Value of the graph will show here when you hover over graph';

    defaultValue = 'Month';
    receivedDate = {'startTime':0,'endTime':0,'type':this.defaultValue};
    fuelComparison = true;
    startVariation = 0;
    endVariation = 0;
    @ViewChild(DateDropDownComponent) dateDropDownComponent!: DateDropDownComponent;

    xAxis:Array<string> = [];
    series :any[]= [];
    table = false;
    topModelIds:string[] = [];
    tenantSpecificData!:FuelTenant;
    showSpinner = true;
    calculateDateUtil!: TimestampCalculation;
    unitConversion!:UnitConversion;
    dataEmpty = true;
    infoTemplateFirst = 'Data is unavailable for current selection';
    infoTemplateSecond = 'Try changing the time range or locomotive selection';
    yAxisMax=1;
    seriesData :any[]= [];
    tablePoints:any[]= [];
    isTableUpdated = false;
    chartInstance: any;
    @Input() set locoList(value: Locomotive[]) {
        this.isTableUpdated = false;
        if (!value) { return; }
        this.showSpinner = true;
        // do not call aggregrate API here on page load;only on locolist dropdown value should be called 
        if(this.receivedDate.startTime!=0 && this.receivedDate.endTime!=0){ 
            this.callAggregateService();
        }
    }
    constructor(private authService:AuthService,
        private fuelSessionService:FuelSessionService,
        private aggregateService: AggregateService,
        private baseApiService:BaseLineService,
        private appStorageService:AppStorageService,
        private router:Router,
        private cdr: ChangeDetectorRef){}
    ngOnInit():void{
        this.calculateDateUtil = new TimestampCalculation();
        this.unitConversion = new UnitConversion();
        if(this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData){
            this.tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if(this.tenantSpecificData && this.tenantSpecificData.unitToDisplay && this.tenantSpecificData.topModelIds){
                this.topModelIds =  this.tenantSpecificData.topModelIds;
                this.units = Object.keys(this.tenantSpecificData.unitToDisplay)[0];//get tenant specific units
            }
        }
        const calculatedDate = this.calculateDateUtil.calculateDate(this.defaultValue);
        this.receivedDate.startTime = calculatedDate.startTime;
        this.receivedDate.endTime = calculatedDate.endTime;
        this.receivedDate.type = this.defaultValue;
        this.startVariation = this.calculateDateUtil.calculateVariation(this.defaultValue).startTime;
        this.endVariation = this.calculateDateUtil.calculateVariation(this.defaultValue).endTime;
        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.defaultValue).type !== 'Custom' ? true : false;
        // set default month start/end time for calling API
        if (!this.baseApiService.checkForKey()) {//check for baseValue key in local storage;if not found then call API
            this.callBaseValueAPI();
        }
    }

    /**
     * executes when HighChartComponent creates its instance
     *
     * @param chartInstance
     */
    onChartInstance(chartInstance:any) {
        this.chartInstance = chartInstance;
    }
    
    syncChartColorsWithDataSource() {
        if(this.dataSource.length === this.chartInstance?.series?.length) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.chartInstance.series.map((chartPoint: {color: string, name: string}, index: number) => {
                this.dataSource[index].color = chartPoint.color;
            });
        }
    }

    ngAfterViewInit(): void {
        // retaining previously selected values in date drop down
        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        if (fuelSession && fuelSession.card) {
            const consumption = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'consumption');
            if (consumption?.type != undefined) {
                if (consumption.type !== 'Custom') {
                    // eslint-disable-next-line unicorn/no-lonely-if
                    if (consumption.type != '') {
                        this.defaultValue = consumption.type;
                        this.receivedDate = { 'type': this.defaultValue, 'startTime': consumption.startTime, 'endTime': consumption.endTime };
                        this.startVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.receivedDate.type).type !== 'Custom' ? true : false;
                    } else {
                        this.defaultValue = 'Today';
                        this.receivedDate = { 'type': this.defaultValue, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime };
                        this.startVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.receivedDate.type).type !== 'Custom' ? true : false;
                    }

                    if (this.fuelSessionService && this.fuelSessionService.fuelSession) {//set in fuelSession localstorage
                        fuelSession.card[2] = { 'type': this.calculateDateUtil.calculateDate(this.defaultValue).type, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime, 'cardName': 'consumption' };
                        this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, fuelSession.card);
                    }
                } else {// for custom get date value and set in date picker option
                    const date = `${moment(consumption.startTime).format('DD-MMMM-YYYY')} - ${moment(consumption.endTime).format('DD-MMMM-YYYY')}`;
                    if (this.dateDropDownComponent) {// datedropdown child component 
                        this.dateDropDownComponent.customText = date;//setting drop down display value
                        this.defaultValue = 'Custom'; // setting selected value 
                        this.receivedDate = { 'type': consumption.type, 'startTime': consumption.startTime, 'endTime': consumption.endTime };// for rendering as full width 
                    }
                }
            }
        } else { // on initial login no local storage found so setting in localStorage
            if (this.fuelSessionService && this.fuelSessionService.fuelSession) {
                this.fuelSessionService.fuelSession.card[2] = { 'type': this.calculateDateUtil.calculateDate(this.defaultValue).type, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime, 'cardName': 'consumption' };
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, this.fuelSessionService.fuelSession.card);
            }
        }
        this.cdr.detectChanges();
    }
    ngAfterContentInit():void{
        this.isTableUpdated = false;
    }
    ngAfterContentChecked():void {
        const highChartsSeries = this.Highcharts.charts[0]?.series;

        if (highChartsSeries?.length && !this.isTableUpdated) {
            setTimeout(() => {
                this.table = true;
                this.getTableValue(this.tablePoints, this.xAxis[0]);
            }, 0);
        }
    }
    //function to call baseline API
    callBaseValueAPI():void{
        this.baseApiService.getBaseAPIValue().subscribe(response => {
            if(response){ 
                this.appStorageService.setToAppStorage('baseKey',JSON.stringify(response));//set in local storage for future call as it needs to call only once
            } 
        },(error) => {
            console.log(error);
            this.appStorageService.setToAppStorage('baseKey',JSON.stringify([]));//set in local storage []
        });
    }
    // function to call aers service
    callAggregateService():void{
        const aggregate = new Aggregate();
        let customFilter:CustomFilters = { columnName: '',condition:'',value: ''};
        const eventAttribute:unknown =  {
            'numerator': 'total_fuel_consumed',
            'denominator': 'total_duration',
            'multiplier': 3600
        };
        customFilter={
            columnName: 'assetModel',
            condition: 'IN',
            value: String(this.topModelIds)// top model ids from tenant config to be passed
        };
        const eventList:Array<AggregateInput> = [];
        const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        let assetInfo:string[] = [];
        assetInfo =  fuelSession && fuelSession.locomotives && fuelSession.locomotives.length > 0 ? fuelSession.locomotives.map(x => x.device_name) as string[]: [];
        
        const filter = (assetInfo.length > 0 ? {
            startTime: this.receivedDate.startTime,
            endTime: this.receivedDate.endTime,
            assetGrpId: assetInfo//pass this if some locos selected
        }: {
            startTime: this.receivedDate.startTime,
            endTime: this.receivedDate.endTime,
            customFilters:[customFilter]// pass this if no locos selected
        });

        this.setGroupedByField( assetInfo.length > 0 ? 'locomotives' : 'assetModel');

        const groupBy = [
            'current_notch',
            this.groupByField,
            'total_fuel_consumed_unit'
        ];
  
        const graphView = aggregate.creatObjects('ConsumptionProfile','RATIO', 'Notch_State_Change',eventAttribute, filter,groupBy);
        eventList.push(graphView);
        this.aggregateService.getAggregate(eventList).subscribe(response => {
            if(response){ 
                this.showSpinner = false;
                if(response[0].data && response[0].data?.length > 0){
                    this.dataEmpty = false;
                    this.generateData(response);//generate graph data 
                    this.lineChartOptions = this.generateChartOptions(); //get graph options
                    setTimeout(() => {
                        this.syncChartColorsWithDataSource();
                    }, 500);
                }else{
                    this.dataEmpty = true;//no data found
                }

                this.updateFlag = true;
            } 
        },(error) => {
            this.showSpinner = false;
            this.infoTemplateFirst = 'We are unable to fetch the data for current selection';
            this.infoTemplateSecond = 'Try refreshing the page';
            console.log('error');
            console.log(error);
        });
    }
    setGroupedByField(value:string): void {
        this.groupByField = value;
    }
    //function call to get table on line graph hover
    getTableValue(points: { x: string; y: number; name:string; color?: string; }[], notch:string):void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const baseKey = this.appStorageService.getFromAppStorage('baseKey');
        this.ELEMENT_DATA = [];
        const EXTRA_ELEMENTS: ChartElement[] = [];
        const pointNames: string[] = [];

        this.notchValue = points[0]['x'];
        const notchIndex = this.xAxis.indexOf(this.notchValue);

        const highChartsSeries = this.Highcharts.charts[0]?.series;

        points.map((point)=>{
            const h_point: any = highChartsSeries?.find(h_serie => h_serie.name === point.name);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            if(baseKey){//check if localStorage exists or not
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const  deviceModelId = baseKey.find((baseKey: { deviceModelId: string; })=>baseKey.deviceModelId===point.name);//filter out that model id
                const baseValues = (baseKey && deviceModelId && deviceModelId.baseValue)? deviceModelId.baseValue:[];//get that model id baseValues array
                if(baseValues){//check if baseValues exists
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    const notchValue = (baseValues.find((notchValue: { notch: string; })=>notchValue.notch ===point.x));//find that notch 
                    const baseValue= (notchValue)? notchValue[this.units] : 0;//find for that notch tenant specific units value
                    this.ELEMENT_DATA.push({ model: point.name, value: point.y, base: baseValue!==0 ? baseValue : '-', color: point.color || h_point?.color });
                }  
              
            }
            else{
                this.ELEMENT_DATA.push({ model: point.name, value: point.y, base: '-', color: point.color || h_point?.color });//if no local storage assign base value '-'
            }

            pointNames.push(point.name);
        });
        const extraSeries = this.series.filter(serie => !pointNames.includes(serie.name) && serie.data[notch]);

        extraSeries.map((serie) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            if(baseKey){//check if localStorage exists or not
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const  deviceModelId = baseKey.find((baseKey: { deviceModelId: string; })=>baseKey.deviceModelId===serie.name);//filter out that model id
                const baseValues = (baseKey && deviceModelId && deviceModelId.baseValue)? deviceModelId.baseValue:[];//get that model id baseValues array
                if(baseValues){//check if baseValues exists
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    const notchValue = (baseValues.find((notchValue: { notch: string; })=>notchValue.notch ===serie.x));//find that notch 
                    const baseValue= (notchValue)? notchValue[this.units] : 0;//find for that notch tenant specific units value
                    EXTRA_ELEMENTS.push({ model: serie.name, value: serie.data[notchIndex], base: baseValue!==0 ? baseValue : '-', color: undefined });
                }  
              
            }
            else{
                EXTRA_ELEMENTS.push({ model: serie.name, value: serie.data[notchIndex], base: '-', color: undefined });//if no local storage assign base value '-'
            }
        });

        this.dataSource = [...this.ELEMENT_DATA, ...EXTRA_ELEMENTS];
        this.table = true;// show graph tableView
    }
    // function call to recieved calculated startTime/endtime from called component
    receivedDateValue(receivedDate:Date):void{
        this.receivedDate = receivedDate;
        if(this.fuelSessionService && this.fuelSessionService.fuelSession){//set in fuelSession localstorage for consumption profile card
            const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
            if (fuelSession && fuelSession.card) {
                fuelSession.card[2]=  {'type':this.receivedDate.type,'startTime':this.receivedDate.startTime,'endTime':this.receivedDate.endTime,'cardName':'consumption'};
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, fuelSession.card);
            }
        }
        this.callAggregateService();
        this.showSpinner = true;
        this.isTableUpdated = false;
    }
    //function to generate graph data
    generateData(response:Array<AggregateOutput>):void{
        let data = response[0].data;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data = data?.sort((a, b) => (a.current_notch! > b.current_notch!) ? 1 : -1);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.xAxis =[];//xaxis 
        this.seriesData = [];//series data 
        this.series = [];//entire series
        // eslint-disable-next-line unicorn/no-array-reduce
        const max = response[0].data?.reduce((a,b)=>a.result>b.result?a:b).result; //find maximum of results and assisgn y axis max
        this.yAxisMax = Math.round(Number(this.unitConversion.decimalRound(Number(max))));
        data?.map((data)=>{
            if(data.current_notch && !this.xAxis.includes(data.current_notch)){//get all notches value
                this.xAxis.push(data.current_notch);
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        // const groupedAssetModelID = this.groupBy(data, 'assetModel');//grouped API response by model ID
        const groupedAssetModelID = this.groupBy(data, this.groupByField);//grouped API response by model ID
        for (const element in groupedAssetModelID) {
            const refactored_array = groupedAssetModelID[element];
            if(refactored_array.length !==this.xAxis.length){
                for (const x of this.xAxis) {//insert null data for notches not found
                    const count = refactored_array.filter((a: { current_notch: string; })=> a.current_notch == x).length;
                    if(count == 0){
                        refactored_array.push({
                            assetModel: element,
                            current_notch: x,
                            // eslint-disable-next-line unicorn/no-null
                            result: null,
                            total_fuel_consumed_unit: ''
                        });
                    }
                }
                if(refactored_array !== undefined && refactored_array.length>0) {
                    refactored_array.sort((a, b) => (this.xAxis.indexOf(a.current_notch) > this.xAxis.indexOf(b.current_notch)) ? 1 : -1);
                }
            } 
        }
        
        for (const key in groupedAssetModelID) {
            this.seriesData = [];
            // eslint-disable-next-line no-prototype-builtins
            if (groupedAssetModelID.hasOwnProperty(key)) {
                if(groupedAssetModelID[key].length > 0){
                    groupedAssetModelID[key].map((result:any)=>{
                        this.seriesData.push(result.result);  //push series data
                    });
                }

                this.series.push({//push to series for each model id 
                    'name': key,
                    'type':'line',
                    'data': this.seriesData,
                    'showInLegend': false
                });
            }
        }
        const filteredSeries = this.series.filter(serie => serie.data[0]);
        const points = filteredSeries.map(serie => ({
            x: this.xAxis[0],
            y: serie.data[0],
            name: serie.name,
            color: undefined,
        }));
        // hide the legend
        this.notchValue = this.xAxis[0];
        this.tablePoints = points;
        this.table = true;
        this.getTableValue(points, this.xAxis[0]);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    groupBy(objectArray: DataObject[] | undefined, property: string) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return objectArray?.reduce((accumulator: { [x: string]: any[]; }, object: { [x: string]: any; }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const key = object[property];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!accumulator[key]) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                accumulator[key] = [];
            }
            // Add object to list for given key's value
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            accumulator[key].push(object);
            return accumulator;
        }, {});
    }
    selectNotch($event: MatSelectChange): void {
        const baseKey = this.appStorageService.getFromAppStorage('baseKey');

        const updatedElements: ChartElement[] = [];

        this.ELEMENT_DATA.map((data: ChartElement) => {
            const currentSerie = this.series.find(serie => serie.name === data.model);
            const notchIndex = this.xAxis.indexOf($event.value);

            let item: ChartElement | undefined;

            if(baseKey){//check if localStorage exists or not
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const  deviceModelId = baseKey.find((baseKey: { deviceModelId: string; })=>baseKey.deviceModelId===currentSerie.name);//filter out that model id
                const baseValues = (baseKey && deviceModelId && deviceModelId.baseValue)? deviceModelId.baseValue:[];//get that model id baseValues array

                if(baseValues){//check if baseValues exists
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    const notchValue = (baseValues.find((notchValue: { notch: string; })=>notchValue.notch === $event.value));//find that notch 
                    const baseValue= (notchValue)? notchValue[this.units] : 0;//find for that notch tenant specific units value
                    item = {
                        ...data,
                        value: currentSerie.data[notchIndex],
                        base: baseValue!==0 ? baseValue : '-'
                    };
                }  
            } else {
                item = {
                    ...data,
                    value: currentSerie.data[notchIndex],
                    base: '-'
                };
            }
            if (item && item.value) {
                updatedElements.push(item);
            }
        });
        this.ELEMENT_DATA = updatedElements;
        this.dataSource = updatedElements;
        this.isTableUpdated = true;
    }
    //function to generate chart options
    generateChartOptions():Highcharts.Options{
        // eslint-disable-next-line unicorn/no-this-assignment
        const _this = this;//chart self reference

        const slicedSeries = this.series.slice(0, 5);

        // eslint-disable-next-line unicorn/no-array-for-each
        this.series.forEach(serie => {
            if (serie?.data?.length) {
                const maxi = Math.max(...serie.data);
                if (maxi > this.yAxisMax) {
                    this.yAxisMax = maxi;
                }
            }
        });

        return {
            credits: {
                enabled: false
            }, 
            chart: {
                type: 'line',
                marginTop:30
            },
            title: {
                text: ''
            },
            xAxis:{
                categories: this.xAxis,
                lineWidth: 2,
                lineColor: '#677e8c',
                tickColor: '#197F07',
                tickInterval: 1,
                labels:{
                    step:1,
                    style:{
                        fontSize: '14px',
                        color: '#57657A',
                        whiteSpace: 'nowrap',
                        textOverflow: 'none',
                     
                    }
                },
                crosshair: {
                    width: 1,
                    color: '#677e8c',
                }
            },
            yAxis: {          
                title:{
                    text:''
                },
                gridLineWidth: 1,
                lineWidth: 2,
                min: 0,
                max: this.yAxisMax,
                lineColor: '#677e8c',
                gridLineColor: '#677e8c',
                labels:{
                    style:{
                        fontSize: '14px',
                        color: '#57657A'
                    }
                }
            },
            tooltip: {
                formatter:function(){
                    const points: { x:string;y:number;name:string;color:string }[] =[];
                    this.points?.map((point)=>{
                        points.push({'x':String(point.x),'y':point.y,'name':point.series.name,'color':point.color as string});
                    });
                    _this.isTableUpdated = true;
                    _this.getTableValue(points, String(points[0].x));
                    return false;
                },
                enabled:true,
                shared:true,
            },
            plotOptions: {
                series: {
                    connectNulls: true,
                    marker: {
                        enabled: false,
                        symbol:'circle'
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        },
                        hover:{
                            lineWidth: 2
                        }
                    },
                    dataLabels:{
                        padding:10
                    }
                }
            },
            series: slicedSeries
        };
    }

    // go to kpi list view page
    gotoListView():void{
        void this.router.navigate(['/views/consumption/list']);
    }
}

