<mat-form-field class="seach-container" appearance="outline">
    <mat-select id="multiselectevent" #multiSelect [formControl]="selectFormControl" [(ngModel)]="selectedValues" [placeholder]="placeholder || 'All locomotives'" [multiple]="true" (selectionChange)="selectionChange($event)">

        <mat-select-trigger>
            <span class="selected-trigger" *ngIf="selectFormControl.value?.length == 1">
        {{selectFormControl.value[0][defaultDisplayText] | truncate: 8}}
        <i class="material-icons clear-icon" (click)="itemRemove(selectFormControl.value[0], $event)">clear</i>
      </span>
            <span class="selected-trigger" *ngIf="selectFormControl.value?.length >= 2">
        {{selectFormControl.value[0][defaultDisplayText] | truncate: 8}}
        <i class="material-icons clear-icon" (click)="itemRemove(selectFormControl.value[0], $event)">clear</i>
        {{selectFormControl.value[1][defaultDisplayText] | truncate: 8}}
        <i class="material-icons clear-icon" (click)="itemRemove(selectFormControl.value[1], $event)">clear</i>
      </span>
            <span *ngIf="selectFormControl.value?.length > 2" class="add-selection">
        +{{ selectFormControl.value.length - 2 }} {{ " More" }}
      </span>
        </mat-select-trigger>
        <div>
            <mat-option>
                <ngx-mat-select-search [formControl]="searchControl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No results found!'">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let multiDropdown of filteredMultiDropdown | async" [value]="multiDropdown">
                {{multiDropdown[defaultDisplayText]}}
            </mat-option>
        </div>
    </mat-select>
</mat-form-field>