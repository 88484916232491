import { Locomotive } from './../../dashboard/model/locomotive';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})  
export class LocomotiveListService {
    private totCount: any;
    constructor(private http: HttpClient, private authService: AuthService) {}
    
    public getLocoList(filter: string, limit: number, offset: number,
        sort_by?: string, sort_order?: string): Observable<Locomotive[]>{
        const url = `${environment.base}/lpr/rest/1.9/systems`;
        let parameters = new HttpParams()
            .set('filter', filter)
            .set('limit', limit.toString())
            .set('offset', offset.toString());
        if (sort_by != undefined && sort_by !== '' && sort_by !== 'undefined' && sort_by !== undefined) {
            parameters = parameters.set('sort_by', sort_by);
        }
        if (sort_order != undefined && sort_order !== '' && sort_order !== 'undefined' && sort_order !== undefined) {
            parameters = parameters.set('sort_order', sort_order);
        }
        
        return this.http.get(url, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type'],
                'Zone-Id': this.authService.getScopeValues().tenantId
            },
            params: parameters
        
        }).pipe(map((response: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return response;
        }))
            .pipe(catchError(error => {
                return throwError(error);
            }
            ));
    }

}