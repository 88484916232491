import { Injectable } from '@angular/core';
import { ConfigurationStore } from './configurator-helper';
import { LibraryConfiguration, LibraryConfigurationProvider} from '@edgelinc/ui-library';

@Injectable({ providedIn: 'root' })
export class ConfigFromApp implements LibraryConfigurationProvider {
    constructor(private configStore: ConfigurationStore) {}

    get config(): LibraryConfiguration {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.configStore.getConfig();
    }
}
