
import { FleetDetails } from './../model/fleet';
import { Injectable } from '@angular/core';
import { map, catchError} from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService } from '@edgelinc/ui-library';
import { Fleet } from '../model/fleet';
@Injectable()
export class FleetListService {
  private totCount!: number;
  get totalCount() : number{
      return this.totCount;
  }
   
  constructor(private http: HttpClient, private authService: AuthService) {}

  public getFleetList(limit: number, offset: number,
      sort_by: string, sort_order: string, searchParameter?: string): Observable<FleetDetails[]> {
      let url = '';
      url = searchParameter ? `${environment.base}/fleetmanager/v1/fleets/search` : `${environment.base}/fleetmanager/v1/fleets`;
      const tenantId = this.authService.getScopeValues().tenantId;
      let parameters = new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('field', sort_by)
          .set('sortDirection', sort_order);
      if (searchParameter != undefined && searchParameter !== '' && searchParameter !== 'undefined' && searchParameter !== undefined) {
          parameters = parameters.set('searchParam', searchParameter);
      }
      return this.http.get<Fleet>(url, {
          headers: {
              'Content-Type': 'application/json',
              'zoneId': this.authService.getScopeValues().zoneId,
              // eslint-disable-next-line unicorn/prefer-string-slice
              'tenantId': tenantId.substring(tenantId.indexOf('tenant'))
              //   'Tenant-Id': this.authService.getHeaders()['Tenant-Id'],
              //   'Zone-Id': this.authService.getHeaders()['Zone-Id']
          },
          params: parameters
      }).pipe(
          map((result: Fleet) => {
              const totCounts = result['totalElements'] ? result['totalElements'] : 1;
              this.totCount = totCounts;
              return this.processData(result);
          }))
          .pipe(catchError(error =>{
              return throwError(error);
          }));
  }

  processData(result:Fleet): FleetDetails[] { 
      return result['fleets'];
  }
}

