<mat-card class='events-map'>
    <mat-card-content>
        <div class="d-flex justify-content-between text-container">
            <span class="text overview" [innerText]="'map.map' | translate"></span>
            <div class="filter-container mt-n2" (click)="gotoListView()">
                <div class="list-view-el d-none d-sm-block"></div>
          </div>
        </div>
        <el-gmap (clickMarker)="clickMarker($event)" [markers]="mapMarkers.markers" [mapType]="'roadmap'" [lang]="lang" [toprightLegendTemplate]="toprightLegendTemplate" [bottomLeftLegendTemplate]="bottomLeftLegendTemplate" [tooltipInfo]="tooltipInfo"></el-gmap>
        <div class='map-related-info'>
            <ng-template #bottomLeftLegendTemplate>
                <div class="app-grid-2 p-2">
                    <div class="legend-item">
                        <div class="legend-item-icon">
                            <img src="./../../../../../assets/icons/station_l.svg" />
                        </div>
                        <div>Station</div>
                    </div>
                    <div class="legend-item">
                        <div class="legend-item-icon">
                            <img src="./../../../../../assets/icons/operational_l.svg" />
                        </div>
                        <div>Available Locomotives</div>
                    </div>
                    <div class="legend-item">
                        <div class="legend-item-icon">
                            <img src="./../../../../../assets/icons/fuel-depot_l.svg" />
                        </div>
                        <div>Fuel Depot</div>
                    </div>
                    <div class="legend-item">
                        <div class="legend-item-icon">
                            <img src="./../../../../../assets/icons/unreachable_l.svg" />
                        </div>
                        <div>Unavailable Locomotives</div>
                    </div>
                    <div class="legend-item">
                        <div class="legend-item-icon">
                            <img src="./../../../../../assets/icons/repair-center_l.svg" />
                        </div>
                        <div>Repair Center</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #tooltipInfo let-element let-col='col'>
                <div class="tooltip">
                    <div *ngIf="!loading">
                        <div class="tooltip-header">
                            <div class="tooltip-name">{{element.markerInfo}}</div>
                            <div class="tooltip-label {{element.type}}">{{element.label}}</div>
                        </div>
                        <div class="app-grid-2 tooltip-body">
                            <div>
                                <div class="title">Total Fuel Filled</div>
                                <div class="value" [innerText]="totalFuelFilled"></div>
                                <div class="date" [innerText]="currentDate"></div>
                            </div>
                            <div>
                                <div class="title">Last Fuel Filled</div>
                                <div class="value" [innerText]="lastFuelFilled"></div>
                                <div class="date" [innerText]="currentDate"></div>
                            </div>
                        </div>
    
                        <div *ngIf='!dataEmpty' style="width: 330px; overflow-x: hidden;">
                            <div style="height: 180px; width: 600px; ">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
                                    class="chart-container">
                                </highcharts-chart>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loading" style="height: 12rem; width: 15rem;">
                        <el-spinner></el-spinner>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class='col-12 d-block d-sm-none mt-4 d-flex justify-content-center'>
            <volt-button variant="outline" [innerText]="'overview.listView' | translate" (click)="gotoListView()"></volt-button>
        </div>
    </mat-card-content>
</mat-card>