import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteLayoutComponent, AuthGuardService as AuthGuard } from '@edgelinc/ui-library';
import { ConsumptionListViewComponent } from './components/consumption-list-view/consumption-list-view.component';
import { KpiListViewComponent } from './components/kpi-list-view/kpi-list-view.component';

const routes: Routes = [
    {
        path: 'views',
        component: SiteLayoutComponent,
        children: [
            { path: 'kpi/list', component: KpiListViewComponent, canActivate: [AuthGuard]},
            { path: 'consumption/list', component: ConsumptionListViewComponent, canActivate: [AuthGuard]}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class OverviewRoutingModule {}
