
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';
import { AggregateOutput } from '@app/modules/overview/model/aggregate-output';
import {AggregateInput } from '@app/modules/overview/model/aggregrate-input';

@Injectable({
    providedIn: 'root'
}) 
export class AggregateService{
   
    constructor(private http: HttpClient, private authService: AuthService) {}
    
    public getAggregate(request:AggregateInput[]):Observable<AggregateOutput[]>{
        const url = `${environment.base}/aers/events/aggregate`;

        return this.http.post<AggregateOutput[]>(url, request, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type']
            },
        }).pipe(
            map(response => {
                return response;
            }))
            .pipe(catchError(error => {
                return throwError(error);
            }
            ));
    }   
}