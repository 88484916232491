<div class="sidemenu">
     <div *ngFor="let menu of sideMenu">
        <div class="menu-list menu" [routerLink]="menu.url" [class.hidden]="!menu.visible" routerLinkActive="active" *ngIf='menu.children == null'>
            <div class="menu-label" [innerText]="menu.label"></div>
        </div>
        <mat-expansion-panel [expanded]="menu.expand" style="box-shadow: none;" [class.hidden]="!menu.visible" *ngIf='menu.children != null'>
            <mat-expansion-panel-header style="padding-left: 11px;height: 42px; flex-direction: row">
                <mat-panel-title style="margin-top: -4px;">
                    <div class="menu-label" [innerText]="menu.label">
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let childMenu of menu.children">
                <div class="menu-list menu" [routerLink]="childMenu.url" [class.hidden]="!childMenu.visible" routerLinkActive="active">
                    <div class="menu-label" [innerText]="childMenu.label"></div>
                </div>
            </div>
        </mat-expansion-panel>
    </div> 
</div>