import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect} from '@angular/material/select';
import { RecievedDate } from '@app/modules/shared/model/date';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import * as moment from 'moment';
import { DateRangePickerComponent } from '../../dateRangePicker/date-range-picker/date-range-picker.component';

@Component({
    selector: 'fuel-app-date-drop-down',
    templateUrl: './date-drop-down.component.html',
    styleUrls: ['./date-drop-down.component.scss']
})
export class DateDropDownComponent {
  maxDate = new Date();
  @ViewChild('picker') datePicker!: MatDatepicker<Date>;
  @ViewChild('dateSelect') dateSelect!: MatSelect;
  // default selected value
  @Input() defaultValue = 'Today';
  customText = 'Custom';
  @Output() emitDateValue = new EventEmitter<{'startTime':number,'endTime':number,type:string}>();
  @ViewChild(DateRangePickerComponent) dateRangePickerComponent!: DateRangePickerComponent;
  startTime!: number;
  endTime! :number;
  isDateSelected = false;
  calculateDateUtil!: TimestampCalculation;
  constructor(){
      this.calculateDateUtil = new TimestampCalculation();
  }
  // function call to open date picker if custom already selected and then again option not changed
  openedChange(event:boolean,value:string):void{
      if(!event && value === 'Custom'){
          this.dateRangePickerComponent.datePicker.open();
      }
  }
  // function call on date drop down value selection
  onDateChange(type: string):void{
      if(type !== 'Custom'){
          this.customText = 'Custom';
          // emit calculated startTime/End time to calling component
          this.emitDateValue.emit(this.calculateDateUtil.calculateDate(type));
      }
      else{
          this.startTime = 0;
          this.endTime = 0;
          this.dateRangePickerComponent.datePicker.open();// open date picker manually
      }
  }
  checkCustom(customText:string):string {
      return customText!='Custom'?customText:'';

  }
  //fubction call when date picker value is emitted
  receivedDateValue(receivedDateValue:RecievedDate):void{
      if(receivedDateValue && receivedDateValue.startTime!==0 && receivedDateValue.endTime!==0){
          this.startTime = receivedDateValue.startTime;
          this.endTime = receivedDateValue.endTime;
          const dateRangeSelected = `${moment(receivedDateValue.startTime).format('DD-MMMM-YYYY')} - ${moment(receivedDateValue.endTime).format('DD-MMMM-YYYY')}`; 
          this.customText = dateRangeSelected;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.emitDateValue.emit({'startTime':this.startTime,'endTime':this.endTime,'type':this.dateSelect.value});
      }
    
  }

}
