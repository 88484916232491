import { Component } from '@angular/core';
import { TooltipComponent } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'fuel-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fuel-app';
  constructor(private translate: TranslateService) {
      translate.setDefaultLang('en');
      Highcharts.setOptions({
          colors: ['#61D883', '#E4A350', '#F97DD8', '#50CCE4', '#9150E4', '#E4CB50']
      });


      Object.defineProperty(TooltipComponent.prototype, 'message', {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          set(v: any) {
              const element = document.querySelectorAll('.mat-tooltip');
 
              if (element) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  element[element.length - 1].innerHTML = v;
              }
          },
          configurable: true
      });
  }

}
