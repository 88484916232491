
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';
import { EventParameters, SingleEventResponse } from '@app/modules/events-alerts/model/single-event';

@Injectable({
    providedIn: 'root'
}) 
export class SingleEventService {
    constructor(private http: HttpClient, private authService: AuthService) {}
  
    public getEvent(eventParameters: EventParameters):Observable<SingleEventResponse>{
        const { customer_id = '', loco_id_str: loco_id_string = '', device_model_name = '', name = '', timestamp = '' } = eventParameters;

        const url = `${environment.base}/snapshot/event/loco/${customer_id}_${loco_id_string}/device/${device_model_name}/event/${name}/parameters?set_ts=${timestamp}`;

        return this.http.get<SingleEventResponse>(url, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type']
            },
        }).pipe(map((response: SingleEventResponse) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return response;
        }))
            .pipe(catchError(error => {
                return throwError(error);
            }));
    }   
}