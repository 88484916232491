<mat-card class="overview-card second-card">
    <mat-card-content>
        <!-- overview text  -->
        <div class="d-flex justify-content-between">
            <span class="text overview" [innerText]="'overview.overview' | translate"></span>
            <div class="filter-container mt-n2">
                <fuel-app-date-drop-down [defaultValue]='defaultValue' (emitDateValue)="receivedDateValue($event)">
                </fuel-app-date-drop-down>
                <div (click)="gotoListView()" class="list-view-el  d-none d-sm-block"></div>
            </div>
        </div>
        <div *ngIf='fuelAddedDisplay && (fuelAddedDisplay!== undefined)' class='container-md'>
            <div class='overview-row mt-n0 mt-md-n3 h-center'>
            <!-- Fuel Added -->
                <div class="col-md-2 col-xl-3">
                    <div>
                        <div class="text fuelFilled" [innerText]="'overview.totalFuel' | translate"></div>
                        <div *ngIf="receivedDate.type =='Today' && fuelAddedDisplay && (fuelAddedDisplay!== undefined)"
                            class="fuel-text upToDate" [innerText]="currentDate"></div>
                    </div>
                    <div class="text-container">
                        <div *ngIf='fuelAddedDisplay && (fuelAddedDisplay!== undefined)'>
                            <span class="fuel-text unitText" [innerText]='fuelAddedDisplay'></span>&nbsp;
                            <span class="fuel-text unitText" [innerText]='unitToDisplay'></span>&nbsp;
                        </div>
                        <div class='perc-container'>
                            <div *ngIf='percentage' class="perc-text">
                                <span class="percentage" [ngClass]='percentage! >= 0? "green": "red"' [innerText]='percentage! >= 0? percentageAverage: percentageAverage| slice: 1: 5'></span>
                                <span class="percentage" [ngClass]='percentage! >= 0? "green": "red"'>%</span>
                                <span>
                                    <mat-icon [ngClass]='percentage! >= 0? "green": "red"'
                                        [innerText]='percentage! >= 0? iconUpward: iconDownward'></mat-icon>
                                </span>
                            </div>
    
                            <div *ngIf='fuelAddedDisplay && comparisonDays' class='compared-text'>Compared to the last {{comparisonDays}} {{comparisonDays === 1 ? 'day' : 'days'}}</div>    
                        </div>
                    </div>
                </div>
                <div class='separator'>&nbsp;</div>
                <!--  Fuel Usage  -->
                <div class='col-md-6 col-xl-4 chart-container'>
                    <fuel-app-fuel-usage [fuelData]="fuelData" [totalUsage]="totalFuelCount" [infoTemplate]="infoTemplate" (hoverPieSlice)="hoverPieSlice($event)"
                        [innerSize]="innerSize" style="height:180px;"></fuel-app-fuel-usage>
                </div>
                <div class="col-md-auto col-xl-1 d-none d-md-block arrow-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="41.896" viewBox="0 0 25 41.896">
                        <path id="Mask"
                            d="M3.34.821A1.768,1.768,0,0,0,.8.711,1.866,1.866,0,0,0,.7,3.306L19.848,24.633a1.769,1.769,0,0,0,2.645,0l8.339-9.286L41.645,3.306A1.866,1.866,0,0,0,41.537.711,1.768,1.768,0,0,0,39,.821L21.17,20.676Z"
                            transform="translate(-0.227 42.118) rotate(-90)" />
                    </svg>
                </div>
                <div class='col-md-2 col-xl-3'>
                    <div class="text-container">
                        <div *ngIf='fuelAddedDisplay && (fuelAddedDisplay!== undefined)'>
                            <span class="text unitText">${{fuelCost}}</span>&nbsp;
                        </div>
                        <div *ngIf='fuelAddedDisplay && (fuelAddedDisplay!== undefined)' class="perc-container">
                            <!-- <div *ngIf='receivedDate.type !=="Custom"' class="perc-text">
                                <span class="percentage green">4.77%</span>
                                <span>
                                    <mat-icon [ngClass]='"green"' [innerText]='iconUpward'></mat-icon>
                                </span>
                            </div> -->
                            <div *ngIf='fuelAddedDisplay && comparisonDays' class='compared-text'>Compared to the last {{comparisonDays}} {{comparisonDays === 1 ? 'day' : 'days'}}</div>    
                        </div>
                    </div>
                </div>
                <div class='col-12 d-block d-sm-none mt-4 d-flex justify-content-center'>
                    <volt-button variant="outline" (click)="gotoListView()" [innerText]="'overview.listView' | translate"></volt-button>
                </div>
            </div>
        </div>
        <div *ngIf='!fuelAddedDisplay || (fuelAddedDisplay === undefined)' class='overview-row empty wrap-c center full'>
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
                <path id="Mask"
                    d="M42.888,1.027a4.956,4.956,0,0,1,6.654,2.2h0l40.3,80.088A4.955,4.955,0,0,1,85.417,90.5H5.329A4.956,4.956,0,0,1,.891,83.336h0L40.678,3.249,41,2.7A4.956,4.956,0,0,1,42.888,1.027Zm2.244,10.842L9.958,82.672h70.8Zm.245,53.849a5.216,5.216,0,1,1-5.216,5.216A5.216,5.216,0,0,1,45.378,65.719Zm0-37.82a3.771,3.771,0,0,1,3.912,3.611h0V55.587a3.925,3.925,0,0,1-7.825,0h0V31.511A3.771,3.771,0,0,1,45.378,27.9Z"
                    transform="translate(-0.372 -0.497)" />
            </svg>
            <div class="text small-title mt-1" [innerText]="'general.Empty' | translate"></div>
        </div>
        <div class="example-loading-shade" *ngIf="showSpinner">
            <el-spinner></el-spinner>
        </div>
    </mat-card-content>
</mat-card>