/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
export interface IElement {
  requestFullscreen;
  mozRequestFullScreen;
  webkitRequestFullscreen;
  msRequestFullscreen;
}
export interface IDocument {
  exitFullscreen;
  mozCancelFullScreen;
  webkitExitFullscreen;
  msExitFullscreen;
}
export class FullScreen {
    angularElement: IElement;
    angularDocument: IDocument;
    id;
    constructor(id: string) {
        this.init(id);
    }
    init(id){
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.id= id;
        // changes w.r.t view full screen
        // eslint-disable-next-line unicorn/prefer-query-selector
        this.angularElement = document.getElementById(this.id) as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };
    
        this.angularDocument = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
    }
    maximizeView(id): void {    
        console.log('maximised');
        this.init(id);
        if (this.angularElement.requestFullscreen) {
            this.angularElement.requestFullscreen();
        } else if (this.angularElement.mozRequestFullScreen) {
            /* Firefox */
            this.angularElement.mozRequestFullScreen();
        } else if (this.angularElement.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.angularElement.webkitRequestFullscreen();
        } else if (this.angularElement.msRequestFullscreen) {
            /* IE/Edge */
            this.angularElement.msRequestFullscreen();
        }
    }

    minimizeView(id) : void {
        console.log('minimised');
        this.init(id);
        if (this.angularDocument.exitFullscreen) {
            this.angularDocument.exitFullscreen();
        } else if (this.angularDocument.mozCancelFullScreen) {
            /* Firefox */
            this.angularDocument.mozCancelFullScreen();
        } else if (this.angularDocument.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            this.angularDocument.webkitExitFullscreen();
        } else if (this.angularDocument.msExitFullscreen) {
            /* IE/Edge */
            this.angularDocument.msExitFullscreen();
        }
    }
}



