/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export class FakeLibraryConfigurationProvider {
    config = {
        'appName': 'fuel',
        'environment': '',
        'domainConfig': {
            'EAPI_URL': 'https://lpr-dev-edgelinc.scalr.wabtec.com',
            'AUTH_URL': 'http://predix-uaa-dev-edgelinc.scalr.wabtec.com/uaa',
            'AUTH_CLIENT_ID': 'em-client',
            'ACL_URL': 'https://ldars-getacl-dev-edgelinc.scalr.wabtec.com',
            'SNAPSHOT_URL': 'https://ldars-rulemgmtgw-dev-edgelinc.scalr.wabtec.com/proxy/snapshot',
            'PARAMS_URL': 'http://ldars-rulemgmtgw-dev-edgelinc.scalr.wabtec.com/proxy/snapparams',
            'GOOGLE_API_KEY': 'AIzaSyChb6mkmSWlnP8kH4Rl1PR_wKLpqAjYXfU',
            'REQ_URL': 'http://ldars-reqprocgw-dev-edgelinc.scalr.wabtec.com/proxy/request',
            'AERS_URL': 'http://ldars-reqprocgw-dev-edgelinc.scalr.wabtec.com/proxy/aers',
            'MIN_ZOOM': 3,
            'MAX_ZOOM': 15,
            'DEFAULT_ZOOM': 10,
            'DEFAULT_CENTER': {
                'latitude': 42.68303,
                'longitude': -92.54311
            },
            'DEFAULT_MAP_TYPE': 'roadmap',
            'LOCOMOTIVEMODEL': 'F034A06A',
            'LOCOMOTIVEMODELVERSION': 2,

            'IANR_TENANT_ID': 'predix-appliance-07-tenant-01',
            'MULTI_DEVICE_TENANTS': 'predix-appliance-07-tenant-01,predix-appliance-06-tenant-01,predix-appliance-04-tenant-01',
            'MODEL_DEVICE_NAME': 'GoLINC Locomotive',
            'LOCO_DEVICE_NAME': 'Locomotive',
            'ROLE_OPERATOR': 'Operator',

            'ZAC_URL':'',
            'ZAC_ZONE_PREFIX': 'predix-edge.zones',
            'ZAC_ZONE_SUFFIX': 'user',
            'ZAC_ZONE_SEP': '',

            'PDM_BRAND': 'geldars',

            'VERSION': '',
            'EAPI_VERSION':'',
            'PRODUCTION': 'null',

            'STAT_REFRESH_ENABLED': true,

            'STAT_REFRESH_PERIOD': 30000,
            'DASHBOARD_REFRESH_TIME': 60000,
            'EVENT_RECORDER_REFRESH_TIME': 30000,

            'CLIENT_SERVER_PATH': 'GET/',
            'CLIENT_SERVER_URI':'datastore-dev-edgelinc.scalr.wabtec.com'
        },
        sideMenu:[{
            label: 'Dashboard',
            url: ['/views/dashboard'],
            icon: 'dashboard.svg',
            visible: true,
            expand:false,
            permissionString: 'View Dashboard',
        },
        {
            label: 'Management',
            url: ['/views/management'],
            icon: 'management.svg',
            visible: true,
            expand: false,
            children:[ {
                label: 'Device Model',
                url: ['/views/device-model'],
                icon: 'device-model.svg',
                visible: true,
                permissionString: 'View Devices'
            },
            {
                label: 'Asset',
                url: ['/views/asset-management'],
                icon: 'locomotiv.svg',
                visible: true,
                permissionString: 'View Locomotives'
            },
            {
                label: 'Fleet',
                url: ['/views/fleet-management'],
                icon: 'fleet-management.svg',
                visible:true,
                permissionString:'View Fleet'
            },
            ]
        },
        {
            label: 'Rule Output Events',
            url: ['/views/events'],
            icon: 'icon events',
            visible: true,
            expand:false,
        }
       
        ]
    }
}