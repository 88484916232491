import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { LibraryConfigurationProvider , MapCoordinates} from '@edgelinc/ui-library';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import moment from 'moment';


import { Aggregate } from '@app/modules/shared/utilities/aggregate';
import { AggregateInput } from '@app/modules/overview/model/aggregrate-input';
import { AggregateService } from '@app/modules/shared/services/aggregate.service';
import { MapMarkersService } from '@app/modules/shared/services/map-markers.service';
import { MapMarkers } from '../../services/map-markers';
import { MarkerCoordinatesFilter, MarkerInformationFilter } from '../../model/marker';

type FilterType = {
    startTime: number;
    endTime: number;
    assetGrpId?: string[];
}

@Component({
    selector: 'fuel-app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {
    loading = false;
    mapMarkers!: MapMarkers;
    mapMarkersCoordinatesFilter: MarkerCoordinatesFilter = {size: '500', page: '1', orderBy: 'messageTime', sortOrder: 'DESC'};
    mapMarkersInformationFilter: MarkerInformationFilter = {offset: '0', sort_by: 'statistics.last_seen', sort_order: 'desc'};

    currentDate = `Today, ${moment().format('DD MMMM YYYY')}`;
    totalFuelFilled = '-';
    lastFuelFilled = '-';
    dataEmpty = false;
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions!: Highcharts.Options;
    seriesData: {name: string, y: number;}[] = [];

    langSubscriber;
    lang: string | undefined;
    markers : MapCoordinates[] | undefined; 
    locomotives : MapCoordinates[] | undefined; 
    depots : MapCoordinates[] | undefined; 
    stations : MapCoordinates[] | undefined;
    repairCenters: MapCoordinates[] | undefined;
    @ViewChild('bottomLeftLegendTemplate', { static: true }) bottomLeftLegendTemplate: undefined;
    @ViewChild('toprightLegendTemplate', { static: true }) toprightLegendTemplate: undefined;
  
    constructor(
        private router: Router,
        private translate: TranslateService,
        public configurationProvider: LibraryConfigurationProvider,
        public markersService: MapMarkersService,
        public aggregateService: AggregateService
    ) {
        this.langSubscriber = translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.lang = event.lang;
            this.translate.use(this.lang);
        });
    }

    ngOnInit(): void {
        this.mapMarkers = new MapMarkers(this.markersService);
        this.mapMarkers.getMarkerData({ coordinatesFilter: this.mapMarkersCoordinatesFilter, infoFilter: this.mapMarkersInformationFilter });
    }

    ngOnDestroy(): void {
        this.langSubscriber && this.langSubscriber.unsubscribe();
    }

    clickMarker(marker: MapCoordinates): void {
        this.loading = true;
        const aggregate = new Aggregate();
        const cumulativeFilter: { assetGrpId?: string[] } = {};
        const filter: FilterType = {
            startTime: moment().startOf('day').unix(),
            endTime: moment().unix(),
        };
        if (marker.markerInfo) {
            filter.assetGrpId = [marker.markerInfo];
            cumulativeFilter.assetGrpId = [marker.markerInfo];
        }
        const fuelUsage = aggregate.creatObjects('FuelUsage','SUM', 'Notch_State_Change','total_fuel_consumed', cumulativeFilter, ['current_loco_state']);
        const lastFuelAdded = aggregate.creatObjects('LastFuelAdded','SUM', 'Fuel_Fill','total_fuel_change', filter);
        const totalFuelUsage = aggregate.creatObjects('TotalFuelUsage','SUM', 'Notch_State_Change','total_fuel_consumed', filter);
        
        // const eventList:Array<AggregateInput>  = [cumulativeFuel, fuelAdded, fuelUsage, fuelAddedVariation, lastFuelAdded];
        const eventList:Array<AggregateInput>  = [fuelUsage, lastFuelAdded, totalFuelUsage];

        this.aggregateService.getAggregate(eventList).subscribe(response => {
            response.map(data => {
                if(data && data.data && data.operationId == 'TotalFuelUsage'){
                    if(data.data.length>0){
                        const { result, total_fuel_change_unit, total_fuel_consumed_unit } = data.data[0] || {};
                        const unit = total_fuel_consumed_unit || total_fuel_change_unit;
                        if (result && unit) {
                            this.totalFuelFilled = `${result.toFixed(2)} ${unit}`;
                        }
                    }    
                }else if(data && data.data && data.operationId == 'LastFuelAdded') {
                    if(data.data.length>0){
                        const { result, total_fuel_change_unit, total_fuel_consumed_unit } = data.data[0] || {};
                        const unit = total_fuel_consumed_unit || total_fuel_change_unit;
                        if (result && unit) {
                            this.lastFuelFilled = `${result.toFixed(2)} ${unit}`;
                        }
                    }   
                } else if(data && data.data && data.operationId == 'FuelUsage') {
                    if(data.data.length>0){
                        this.seriesData = data.data.map(usageFuel => ({
                            name: usageFuel.current_loco_state || '',
                            y: usageFuel.result
                        }));
                        this.chartOptions = this.generateChartOptions(this.seriesData);
                    } else {
                        this.dataEmpty = true;
                    }
                }
            });
            this.loading = false;
        },(error) => {
            this.loading = false;
            console.log('error');
            console.log(error);
        });
   
    }

    // go to kpi list view page
    gotoListView():void{
        void this.router.navigate(['/views/map/list']);
    }

    generateChartOptions(data:{name:string;y:number}[]): Highcharts.Options {
        return {
            chart: {
                plotBackgroundColor: undefined,
                plotBorderWidth: undefined,
                plotShadow: false,
                type: 'pie',
                marginLeft: 0,
                marginBottom: 0
            },
            colors: ['#066DA1', '#007DBA', '#CCE5F1', '#61D883', '#DFF7E6', '#53B575', '#80DF9B'],
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: 'Fuel Used: <b>{point.y}</b>',
                style: {
                    fontSize: '10',
                }
            },
            legend: {
                useHTML: true,
                title: {
                    // text: '<b style="padding-left:2em;">Fuel Usage details</b>',
                    text: '',
                    style: {
                        fontSize: '14',
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                        textAlign: 'right'
                    }

                },
                itemStyle: {
                    fontWeight: '8',
                    fontFamily: 'Roboto',
                },
                labelFormat: '{percentage:.1f}' + '%' + ' - ' + '{name}',
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 8,
                symbolRadius: 8,
                // itemWidth: 180,
                // maxHeight: 300,
                x: -280,
                y: -10

            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,

                    },
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false; // <== returning false will cancel the default action
                            }
                        }
                    },
                    showInLegend: true,
                    states: {
                        hover: {
                            brightness: 0,
                            lineWidthPlus: 0,
                            halo: undefined
                        }
                    }

                },

            },
            series: [{
                type: 'pie',
                data,
                innerSize: '70%'
            }]
        };
    }
}
