import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Date as DateModel } from '@app/modules/shared/model/date';
import { TimestampCalculation } from '@app/modules/shared/utilities/timestamp-calculation';
import * as moment from 'moment';
import { AggregateService } from '@app/modules/shared/services/aggregate.service';
import { Aggregate } from '@app/modules/shared/utilities/aggregate';
import { AggregateOutput } from '../../../overview/model/aggregate-output';
import { AuthService } from '@edgelinc/ui-library';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { AggregateInput } from '@app/modules/overview/model/aggregrate-input';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';
import { Router } from '@angular/router';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { DateDropDownComponent } from '@app/modules/shared/components/dateSelection/date-drop-down/date-drop-down.component';

interface eventDataI {
    name?: string | undefined,
    y?: number | undefined
}
@Component({
    selector: 'fuel-app-chart-view',
    templateUrl: './chart-view.component.html',
    styleUrls: ['./chart-view.component.scss']
})
export class ChartViewComponent implements OnInit {
    iconUpward = 'arrow_upward';
    iconDownward = 'arrow_downward'
    infoTemplate = '<div class="infoTemplate-line1">Data is unavailable for current selection<div class="infoTemplate-line2">Try changing the time range or locomotive selection</div></div>';
    Highcharts: typeof Highcharts = Highcharts;
    idleChartOptions!: Highcharts.Options;

    defaultValue = 'Today';
    startVariation = 0;
    totalEvents = 0;
    currentEventsNumber = 0;
    endVariation = 0;
    currentDate = `Today, ${moment().format('DD MMMM YYYY')}`;
    receivedDate = { 'startTime': 0, 'endTime': 0, 'type': this.defaultValue };
    calculateDateUtil!: TimestampCalculation;

    fuelComparison = true;

    loading = false;
    data!: eventDataI;
    LoadData: Array<eventDataI> = [];
    hashEventData: Map<string, number> = new Map<string, number>();
    eventList: Array<AggregateInput> = [];
    assetGrpList?: Locomotive[] | undefined;
    eventsCount: number;
    dataEmpty = true;
    tenantSpecificData!: FuelTenant;
    comparisonDays = 0;
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventValues:string[] = [];
    @ViewChild(DateDropDownComponent) dateDropDownComponent!: DateDropDownComponent;
    @Input() set locoList(value: Locomotive[]) {
        if (!value) { return; }
        this.assetGrpList = value;
        this.eventList = [];
        //do not call aggregrate API here on page load;only on locolist dropdown value should be called 
        if (this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0) {
            this.callAggregateService();
        }

    }
    constructor(
        private aggregateService: AggregateService,
        private authService: AuthService,
        private router:Router, 
        private fuelSessionService: FuelSessionService,
        private cdr: ChangeDetectorRef) {
        this.eventsCount = 0;
    }
    ngOnInit(): void {
        this.calculateDateUtil = new TimestampCalculation();
        this.eventsCount = 0;
        if (this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData) {
            this.tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if (this.tenantSpecificData) {
                this.eventValues = Object.keys(this.tenantSpecificData.topMostEvents);
                this.eventValues.push('Snapshot');
            }
        }

        const calculatedDate = this.calculateDateUtil.calculateDate(this.defaultValue);
        this.receivedDate.startTime = calculatedDate.startTime;
        this.receivedDate.endTime = calculatedDate.endTime;
        this.receivedDate.type = this.defaultValue;
        this.startVariation = this.calculateDateUtil.calculateVariation(this.defaultValue).startTime;
        this.endVariation = this.calculateDateUtil.calculateVariation(this.defaultValue).endTime;
        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.defaultValue).type !== 'Custom' ? true : false;

        this.callAggregateService();
    }
    ngAfterViewInit(): void {
        // retaining previously selected values in date drop down
        const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
        if (fuelSession && fuelSession.card) {
            const events = fuelSession.card.find((a: { cardName: string; }) => a.cardName == 'events');
            if (events?.type != undefined) {
                if (events.type !== 'Custom') {
                    // eslint-disable-next-line unicorn/no-lonely-if
                    if (events.type != '') {
                        this.defaultValue = events.type;
                        this.receivedDate = { 'type': this.defaultValue, 'startTime': events.startTime, 'endTime': events.endTime };
                        this.startVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.receivedDate.type).type !== 'Custom' ? true : false;
                    } else {
                        this.defaultValue = 'Today';
                        this.receivedDate = { 'type': this.defaultValue, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime };
                        this.startVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).startTime;
                        this.endVariation = this.calculateDateUtil.calculateVariation(this.receivedDate.type).endTime;
                        this.fuelComparison = this.calculateDateUtil.calculateVariation(this.receivedDate.type).type !== 'Custom' ? true : false;
                    }

                    if (this.fuelSessionService && this.fuelSessionService.fuelSession) {//set in fuelSession localstorage
                        fuelSession.card[1] = { 'type': this.calculateDateUtil.calculateDate(this.defaultValue).type, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime, 'cardName': 'events' };
                        this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, fuelSession.card);
                    }
                } else {// for custom get date value and set in date picker option
                    const date = `${moment(events.startTime).format('DD-MMMM-YYYY')} - ${moment(events.endTime).format('DD-MMMM-YYYY')}`;
                    if (this.dateDropDownComponent) {// datedropdown child component 
                        this.dateDropDownComponent.customText = date;//setting drop down display value
                        this.defaultValue = 'Custom'; // setting selected value 
                        this.receivedDate = { 'type': events.type, 'startTime': events.startTime, 'endTime': events.endTime };// for rendering as full width 
                    }
                }
            }
        } else { // on initial login no local storage found so setting in localStorage
            if (this.fuelSessionService && this.fuelSessionService.fuelSession) {
                this.fuelSessionService.fuelSession.card[1] = { 'type': this.calculateDateUtil.calculateDate(this.defaultValue).type, 'startTime': this.calculateDateUtil.calculateDate(this.defaultValue).startTime, 'endTime': this.calculateDateUtil.calculateDate(this.defaultValue).endTime, 'cardName': 'events' };
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, this.fuelSessionService.fuelSession.card);
            }
        }
        this.cdr.detectChanges();
    }
    hoverPieSlice($event?: number): void {
        if ($event) {
            this.currentEventsNumber = $event;
        }
    }
    // function call to recieved calculated startTime/endtime from called component
    receivedDateValue(receivedDate: DateModel): void {
        this.receivedDate = receivedDate;
        if(this.fuelSessionService && this.fuelSessionService.fuelSession){//set in fuelSession localstorage for consumption profile card
            const fuelSession: FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
            if (fuelSession && fuelSession.card) {
                fuelSession.card[1]=  {'type':this.receivedDate.type,'startTime':this.receivedDate.startTime,'endTime':this.receivedDate.endTime,'cardName':'events'};
                this.fuelSessionService.setFuelSession(this.fuelSessionService.fuelSession.locomotives, fuelSession.card);
            }
        }

        this.totalEvents = 0;
        this.currentEventsNumber = 0;
        this.callAggregateService();
        this.loading = true;
    }
    //function to call aggregate service which will do an API call to fetch reponse for the tenantConfig
    callAggregateService(): void {
        this.eventList = [];
        this.loading = true;
        const aggregate = new Aggregate();
        const groupBy = [
            'eventName'
        ];
        let assetInfo: string[] = [];
        assetInfo = this.assetGrpList && this.assetGrpList.length > 0 ? this.assetGrpList.map(x => x.device_name) as string[] : [];
        const customFilter = {
            columnName: 'eventName',
            condition: 'IN',
            value: String(this.eventValues)
        };
        const filter = (assetInfo.length > 0 ? {
            startTime: this.receivedDate.startTime,
            endTime: this.receivedDate.endTime,
            assetGrpId: assetInfo,
            customFilters: [customFilter]
        } : {
            startTime: this.receivedDate.startTime,
            endTime: this.receivedDate.endTime,
            customFilters: [customFilter]
        });


        const today = new Date();
        const one_day = 86400000;

        // Calculate the difference in milliseconds
        const difference_ms = today.getTime() - this.receivedDate.startTime;
        // Convert back to days and return
        this.comparisonDays = Math.round(difference_ms/one_day); 

        const chartView = aggregate.creatObjects('EventsList', 'COUNT', 'Events', 'eventName', filter, groupBy);

        this.eventList.push(chartView);
        //aggregateService is subscribed to get the api response which is used in the pieChart
        this.aggregateService.getAggregate(this.eventList).subscribe(response => {
            if (response) {
                this.LoadData = [];
                this.LoadData = this.generateLoadData(response);
                this.loading = false;
                if (this.LoadData.length > 0) {
                    this.dataEmpty = false;
                    this.idleChartOptions = this.generateChartOptions();
                    /* eslint-disable unicorn/no-array-reduce */
                    this.totalEvents = this.LoadData.reduce((total, value) => (total + (value.y || 0)), 0);
                    this.currentEventsNumber = this.totalEvents;
                } else {
                    this.dataEmpty = true;
                }

            }
        }, (error) => {
            this.loading = false;
            this.dataEmpty = true;
            console.log('error');
            console.log(error);
        });


    }
    generateLoadData(response: Array<AggregateOutput>): Array<eventDataI> {
        this.eventsCount = 0;
        const Data: Array<eventDataI> = [];
        this.hashEventData = new Map<string, number>();
        for (const element of response) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            for (const eventData of element.data!) {
                if (this.hashEventData.get(eventData.eventName) != undefined) {
                    this.hashEventData.set(eventData.eventName, (Number(this.hashEventData.get(eventData.eventName)) + Number(eventData.result)));
                } else {
                    this.hashEventData.set(eventData.eventName, eventData.result);

                }
            }
        }


        for (const key of this.hashEventData.keys()) {
            this.eventsCount = this.eventsCount + Number(this.hashEventData.get(key));
            Data.push({
                name: String(key).split('_').join(' '),
                y: Number(this.hashEventData.get(key))
            });
        }

        return Data;
    }
    generateChartOptions(): Highcharts.Options {
        // eslint-disable-next-line unicorn/no-this-assignment
        const _this = this;//chart self reference
        return {
            chart: {
                plotBackgroundColor: undefined,
                plotBorderWidth: undefined,
                plotShadow: false,
                type: 'pie',
                marginLeft: 0,
                marginBottom: 0,
            },
            colors: ['#066DA1', '#007DBA', '#CCE5F1', '#61D883', '#DFF7E6', '#53B575', '#80DF9B'],
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: 'Events: <b>{point.y}</b>'
            },
            legend: {
                useHTML: true,
                title: {
                    text: '',
                    style: {
                        fontSize: '14',
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                        textAlign: 'right'
                    }
                },
                itemStyle: {
                    fontWeight: '8',
                    fontFamily: 'Roboto',
                },
                labelFormat: '<div id="{name}" style="font-size: 16px;">{percentage:.1f}' + '%' + ' - ' + '{name}</div>',
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 8,
                symbolRadius: 8,
                x: -280,
                y: -10

            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,

                    },
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false; // <== returning false will cancel the default action
                            },
                        }
                    },
                    showInLegend: true,
                    states: {
                        hover: {
                            brightness: 0,
                            lineWidthPlus: 0,
                            halo: undefined
                        }
                    }
                },
            },
            series: [{
                type: 'pie',
                data: this.LoadData,
                innerSize: '70%',
                point: {
                    events: {
                        mouseOver: function() {
                            _this.hoverPieSlice(this.y);
                        },
                        legendItemClick: function() {
                            _this.hoverPieSlice(this.y);
                            return false;
                        }
                    }
                },
            }]
        };
    }
    gotoListView():void{
        void this.router.navigate(['/views/events/list']);
    }
}
