/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'el-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss']
})

export class MultiSelectComponent implements AfterContentChecked {

    constructor(private cdr: ChangeDetectorRef) {}

    selectFormControl = new FormControl();
    searchControl = new FormControl();
    selectedValues = [];
    @Input() multiSelectList;
    @Input() savedSelectedList;
    @Input() defaultDisplayText;
    @Input() placeholder;
    @Output() selectedList: EventEmitter<any> = new EventEmitter();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filteredMultiDropdown: Observable<any[]>;
    isSavedSelectedList = false;
  
    ngAfterContentChecked(): void {
        this.filteredMultiDropdown = this.searchControl.valueChanges.pipe(
            startWith<string>(''),
            map(name => this._filter(name))
        );
        this.cdr.detectChanges();
        //retain the saved stored list 
        if(this.multiSelectList && this.multiSelectList.length > 0 && this.savedSelectedList && this.isSavedSelectedList == false) {
            this.selectFormControl.patchValue(this.savedSelectedList);
            this.selectedValues = this.savedSelectedList;
            this.selectionChange();
            this.isSavedSelectedList = true;
        }
    }

    private _filter(name: string) {
        const filterValue = this.searchControl.value ? this.searchControl.value : name.toLowerCase();
        this.setSelectedValues();
        this.selectFormControl.patchValue(this.selectedValues);
        const filteredList = this.multiSelectList?.filter(option => option[this.defaultDisplayText].toLowerCase().includes(filterValue));
        return filteredList;
    }

    itemRemove(item: any, event: any): void {
        if (event && event.preventDefault) {
            event.preventDefault();
            event.stopPropagation();
        }
        const updatedList = this.selectFormControl?.value?.filter(x=> x[this.defaultDisplayText] !== item[this.defaultDisplayText]);
        this.selectFormControl.setValue(updatedList);
        this.selectedValues = updatedList;
        this.selectionChange();
    }

    selectionChange(event?): void {
        if (event?.isUserInput && event?.source?.selected == false) {
            const index = this.selectedValues.indexOf(event.source.value);
            this.selectedValues.splice(index, 1);
        }
        if (this.selectFormControl.value) {
            this.selectedList.emit(this.selectFormControl.value);
        }
    }

    clearSearch(event?: Event): void {
        event?.stopPropagation();
        this.searchControl.patchValue('');
    }

    setSelectedValues(): void {
        if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
            for (const evl of this.selectFormControl.value) {
                if (!this.selectedValues.includes(evl)) {
                    this.selectedValues.push(evl);
                }
            }
        }
    }
}
