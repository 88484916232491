<div class='ml-3 mr-3 top mb-4 single-events' style="min-height: 87vh;">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col mb-2">
        <div class="bredcrumb-container">
          <span class="breadcrumb-active-title" routerLink="/views/events/list">Events</span><span> / </span>
          <span [innerText]="eventName"></span>
        </div>
      </div>
    </div>
    <div class="fuel-app-row back-button" routerLink="/views/events/list">
      <mat-icon class="back-button-icon">chevron_left</mat-icon>
      <span [innerText]="eventName" class="back-button-text"></span>
    </div>
    <div class="fuel-app-row info-container">
      <div [innerText]="date"></div>
      <div class="breaker">|</div>
      <div class="fuel-app-row">
        <b>Customer ID</b>
        <span class="example"> : </span>
        <span [innerText]="eventParams.customer_id"></span>
      </div>
      <div class="breaker">|</div>
      <div class="fuel-app-row">
        <b>Loco ID</b>
        <span class="example"> : </span>
        <span [innerText]="eventParams.loco_id_str"></span>
      </div>
      <div class="breaker">|</div>
      <div class="fuel-app-row">
        <b>Device Name</b>
        <span class="example"> : </span>
        <span [innerText]="eventParams.device_model_name"></span>
      </div>
    </div>
    <div class="fuel-app-row card-container">
      <volt-card class="card-map">
        <b>Map View</b>
        <div class="map-container">
          <el-gmap [markers]="markers" [lang]="lng" [height]="'415px'"></el-gmap>
          <div *ngIf="markers.length === 0" class="map-overlay">
            Latitude and Longitude are not available at the moment
          </div>
        </div>
      </volt-card>
      <volt-card>
        <div class="rule-output">
          <b>Rule Output</b>
          <div class="rule-output__utils">
            <div class="search-container">
                <div class="search-field">
                    <div class='search-img' *ngIf="search.length === 0"></div>
                    <input placeholder="Search" [value]="search" (keyup)="applyFilter($event)">
                    <div (click)="resetSearch()" *ngIf="search.length > 0">
                        <div class="cancel-img cross"></div>
                    </div>
                </div>
            </div>
            <volt-button variant="cta" (click)="exportData()" class="export-button">Export</volt-button>
          </div>
        </div>
        <div class="properties-table-container">
          <table mat-table [dataSource]="dataSource" class="properties-table">
            <ng-container *ngFor="let col of tableColumns;" [matColumnDef]="col.name">
              <th mat-header-cell *matHeaderCellDef> {{col.value}} </th>
              <td mat-cell *matCellDef="let element">
                {{element[col.name]}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </volt-card>
    </div>
  </div>
  <div *ngIf="loading" class="row d-flex justify-content-center">
    <div class="example-loading-shade">
        <el-spinner></el-spinner>
    </div>
  </div>
</div>
