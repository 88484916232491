/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { MapCoordinates , ExportCSVService } from '@edgelinc/ui-library';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, finalize } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import moment from 'moment';

import { SingleEventService } from '@app/modules/shared/services/single-event.service';
import { EventParameters, EventProperties } from '../../model/single-event';

type TableElement = {
    name: string;
    value: string | number;
}
@Component({
    selector: 'fuel-app-single-event',
    templateUrl: './single-event.component.html',
    styleUrls: ['./single-event.component.scss']
})
export class SingleEventComponent {
  eventParams: EventParameters = {};

  loading = false;
  eventName = '';
  date = '';

  search = '';
  tableColumns = [{name: 'name', value: 'Property Name'}, {name: 'value', value: 'Property Value'}];
  displayedColumns: string[] = ['name', 'value'];
  dataSource!: MatTableDataSource<TableElement>
  ELEMENT_DATA: TableElement[] = [];

  lng = 0;
  markers: MapCoordinates[] = [];
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  constructor(
    private router: Router,
    private viewPortScroller: ViewportScroller,
    private singleEventService: SingleEventService,
    public exportCSVService: ExportCSVService
  ) {
      this.eventParams = {...this.router.getCurrentNavigation()?.extractedUrl.queryParams as EventParameters};
      this.date = moment(JSON.parse(this.eventParams.timestamp || '')).format('DD MMM YYYY HH:mm:ss');
      this.router.events.pipe(// scroll to top while page is navigated
          filter(event => event instanceof NavigationEnd))
          .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
  }

  ngOnInit(): void {
      this.loading = true;
      this.singleEventService.getEvent(this.eventParams).pipe(
      // tslint:disable-next-line: deprecation
          catchError(() => observableOf([])),
          finalize(() => this.loading = false)
      ).subscribe((events: any) => {
          const [currentEvent] = events;
          const { snapshotProperties, eventName } = currentEvent || {};
          this.ELEMENT_DATA = Object.keys(snapshotProperties).map(property => ({
              name: property,
              value: snapshotProperties[property as keyof EventProperties] || '-'
          }));
          const lng = snapshotProperties.longitude || undefined, lat = snapshotProperties.latitude || undefined;

          if (lat && lng) {
              this.markers = [{ latitude: Number(lat), longitude: Number(lng) }];
              this.lng = Number(lng);
          }

          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.eventName = eventName;
      });
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.search = filterValue;
  }

  resetSearch():void  {
      this.dataSource.filter = '';
      this.search = '';
  }

  exportData(): void {
      const formattedData = [{name: 'Property Name', value: 'Property Value'}, ...this.ELEMENT_DATA];
      this.exportCSVService.downloadFile(formattedData,  'single_event:' + `${moment(new Date()).format('DD-MM-YYYY_HH-mm-ss').toString()}.csv`);
  }
}
