<div class="login-bg">
    <div class="logo"></div>
    <div class="sublogo"></div>

    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="loginForm" (submit)="login()">
            <div innerHTML="{{errorMessage}}" class="errorSection" [style.display]="error ? 'block' : 'none'"></div>
            <mat-form-field class="login-full-width">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username" required>
                <mat-error *ngIf="loginForm.controls.username.touched && loginForm.controls.username.invalid">
                    <span *ngIf="loginForm.controls.username.errors.required">Please enter username.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="login-full-width">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="identification" required>
                <mat-error *ngIf="loginForm.controls.identification.touched && loginForm.controls.identification.invalid">
                    <span *ngIf="loginForm.controls.identification.errors.required">Please enter password.</span>
                  </mat-error>
            </mat-form-field>
             <el-spinner [style.display]="showSpinner ? 'block' : 'none'"></el-spinner> 
            <button mat-raised-button type="submit" color="primary" class="btn-login" [class.disabled]="(showSpinner || loginForm.invalid)">LOGIN</button>
        </form>
        </mat-card-content>
    </mat-card>

    <div class="footer">
        Copyright © 2021 Wabtec Company. All rights reserved.
    </div>

</div>