import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Locomotive } from '@app/modules/dashboard/model/locomotive';
import { SelectedLocomotivesDialogComponent } from './selected-locomotives-dialog/selected-locomotives-dialog.component';

@Component({
    selector: 'all-locomotives-multi-select',
    templateUrl: './locomotives-select.component.html',
    styleUrls: ['./locomotives-select.component.scss']
})

export class LocomotivesSelectComponent implements AfterContentChecked {
    constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog, private _eref: ElementRef) {}

    selectedValues: Locomotive[] = [];
    @Input() multiSelectList:  Locomotive[]=[];
    @Input() savedSelectedList: Locomotive[]=[];
    @Input() defaultDisplayText!: keyof Locomotive;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() selectedList: EventEmitter<any> = new EventEmitter();
    isSavedSelectedList = false;
    isModalOpened = false;
    isSelectedLocomotivesModalOpened = false;

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
        //retain the saved stored list 
        if(this.multiSelectList && this.multiSelectList.length > 0 && this.savedSelectedList && this.isSavedSelectedList == false) {
            this.selectedValues = this.savedSelectedList;
            this.selectionChange();
            this.isSavedSelectedList = true;
        }
    }
    itemRemove(item: Locomotive): void {
        const updatedList = this.selectedValues?.filter((x: Locomotive)=> x[this.defaultDisplayText] !== item[this.defaultDisplayText]);
        this.selectedValues = updatedList;
        this.selectionChange();
    }
    toggleModal(): void {
        this.isModalOpened = !this.isModalOpened;
    }
    selectLocomotive(locomotive: Locomotive): void {
        const isAlreadyAdded = this.selectedValues.find(value => value[this.defaultDisplayText ] === locomotive[this.defaultDisplayText ]);
        if (isAlreadyAdded) {
            this.itemRemove(locomotive);
        } else {
            this.selectedValues = [...this.selectedValues, locomotive];
            this.selectionChange();
        }
    }
    selectionChange(): void {
        this.selectedList.emit(this.selectedValues);
    }
    setSelectedValues(): void {
        if (this.selectedValues && this.selectedValues.length > 0) {
            for (const evl of this.selectedValues) {
                if (!this.selectedValues.includes(evl)) {
                    this.selectedValues.push(evl);
                }
            }
        }
    }
    removeAllItems():void {
        this.selectedValues = [];
        this.selectionChange();
    }
    openDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            locomotives: this.selectedValues,
            itemRemove: (locomotive: Locomotive) => this.itemRemove(locomotive),
            removeAllItems: () => {
                this.removeAllItems();
                this.dialog.closeAll();
            },
        };
        this.dialog.open(SelectedLocomotivesDialogComponent, dialogConfig);
    }
}
