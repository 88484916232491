
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService } from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';

import { MarkerCoordinatesFilter, MarkerCoordinatesResponse, MarkerInfo, MarkerInformationFilter } from '@app/modules/map/model/marker';

@Injectable({
    providedIn: 'root'
}) 
export class MapMarkersService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    public getMarkerCoordinates(filters: MarkerCoordinatesFilter):Observable<MarkerCoordinatesResponse>{
        const url = `${environment.base}/lvs/lv-consumer-service/v1/last-stat-value?size=500&page=1&orderBy=messageTime&sortOrder=DESC`;

        return this.http.get<MarkerCoordinatesResponse>(url, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type'],
                'Predix-Zone-Id': this.authService.getHeaders()['Predix-Zone-Id'],
                'Tenant-Id': this.authService.getHeaders()['Tenant-Id'],
                'Zone-Id': this.authService.getHeaders()['Zone-Id']
            },
            params: filters
        }).pipe(map((response: MarkerCoordinatesResponse) => {
            return response;
        }))
            .pipe(catchError(error => {
                return throwError(error);
            }));
    }

    public getMarkersInformation(locoNames: string[], filter: MarkerInformationFilter):Observable<MarkerInfo[]>{
        const url = `${environment.base}/lpr/rest/1.9/systems`;
        let filterText = '';
        if (locoNames.length > 0) {
            let newFilter = 'device_name in [';

            let valuesString = '';
            locoNames.map(value => valuesString += `"${value}",`);

            let editedFilterText = valuesString.slice(0, -1);
            editedFilterText += ']';

            newFilter += editedFilterText;

            filterText += newFilter;
        }

        return this.http.get<MarkerInfo[]>(url, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type'],
            },
            params: {
                ...filter,
                limit: locoNames.length.toString(),
                filter: filterText
            }
        }).pipe(map((response: MarkerInfo[]) => {
            return response;
        }))
            .pipe(catchError(error => {
                return throwError(error);
            }));
    }  
}