<mat-form-field class="dateRange-form-field" appearance="outline">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate" matTooltip="{{toolTiptext}}">
      <input matStartDate placeholder="Start date" formControlName="start" #dateRangeStart (dateChange)="dateRangeChange(dateRangeStart.value, dateRangeEnd.value)">
      <input matEndDate placeholder="End date" formControlName="end" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart.value, dateRangeEnd.value)">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [touchUi]="true">
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel  [innerText]="'date-range-picker.cancel' | translate"></button>
        <button mat-raised-button  matDateRangePickerApply [innerText]="'date-range-picker.set' | translate" class="customBtn" (click)="applyDateFilter()"></button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
    <mat-error *ngIf="range.controls.end.hasError('matDatepickerMax')">Future dates not allowed</mat-error>
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>

