import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Tenant } from '../../data/tenant';
import { LibraryConfigurationProvider } from '../../shared/utilities/library-configuration';
import { AuthService } from './auth.service';
@Injectable({
    providedIn: 'root'
})
export class TenantService {
url:string;
constructor(private http: HttpClient,private authService: AuthService,public libraryConfigurationProvider: LibraryConfigurationProvider) {}

public getTenantBasedConfig():Observable<Tenant> {	
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access	
    const appName = this.libraryConfigurationProvider.config['appName'] as string;
    this.url = `${this.libraryConfigurationProvider.config['environment'] as string}/tenantconfig`;
    return this.http.post<Tenant>(this.url, {app: appName}, {	
        headers: this.authService.getHeaders()
    })	
        .pipe(catchError(error =>{	
            return throwError(error);	
        }));	
}

}
