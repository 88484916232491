/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Token } from '../../data/token';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>,
      next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

      const access_token = this.authService.getSessionDetails() ? this.authService.getSessionDetails().token.access_token : '';
      return next.handle(this.addTokenToRequest(request, access_token))
          .pipe(catchError(error => {
              if (error instanceof HttpErrorResponse) {
                  switch (error.status) {
                  case 401:
                      return access_token !== '' ? this.handle401Error(request, next) : throwError(error);
                  case 400:
                      return throwError(error);
                      // return <any>this.authService.logout();
                  default:
                      return throwError(error);
                  }
              } else {
                  return throwError(error);
              }
          }));
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

      if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;

          // Reset here so that the following requests wait until the token
          // comes back from the refreshToken call.
          this.tokenSubject.next(null);

          return this.authService.refreshToken()
              .pipe(switchMap((data:Token) => {
                  if (data) {
                      this.tokenSubject.next(data.access_token);
                      this.authService.access_token = data.access_token;
                      //  data.expires_at = data.expires_in * 1000 + (new Date()).getTime();
                      data.expires_at = data.expires_in * 1000 + Date.now();
                      this.authService.setSessionDetails(data, this.authService.getSessionDetails().userData, this.authService.getSessionDetails().tenantSpecificData, this.authService.getSessionDetails().permissions);

                      return next.handle(this.addTokenToRequest(request, data.access_token));
                  }
                  return <any>this.authService.logout();
              }),
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              catchError(error => {
                  return <any>this.authService.logout();
              }),
              finalize(() => {
                  this.isRefreshingToken = false;
              })
              );
      } else {
          this.isRefreshingToken = false;
          return this.tokenSubject
              .pipe(filter(token => token != null),
                  take(1),
                  switchMap(token => {
                      return next.handle(this.addTokenToRequest(request, token));
                  }));
      }
  }
}
