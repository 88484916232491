<div class="selected-locomotives-dialog">
  <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
  <div class="selected-locomotives-dialog-content">
    <div mat-dialog-title>Selected locomotives</div>
    <div class="selected-locomotives">
      <div class="app-grid-3 selected-locomotives-container">
        <div *ngFor="let locomotive of locomotives" class="selected-locomotives-element" (click)="itemRemove(locomotive)">
            <span>{{locomotive.device_name}}</span>
            <mat-icon>close</mat-icon>
        </div>
      </div>
    </div>
    <div class="button-container">
      <volt-button variant="text" (click)="data.removeAllItems()">Clear All</volt-button>
    </div>
  </div>
</div>