<div class="app-grid-2 overview-container">
    <mat-card class="overview-card">
        <mat-card-content>
            <div class='cumulativeFuelContainer'>
                <span class="text title" [innerText]="'overview.cumulativeData' | translate"></span>
                <div class='row cstm-mt-11'>
                    <div class='col-12'>
                        <span class="text description fuelFilled"
                            [innerText]="'overview.totalFuelFilled' | translate"></span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 mt-05'
                        *ngIf='fuelFilled && (fuelFilled !== undefined); else no_cumulative_data'>
                        <span class="text cumulativeFuel" [innerText]='fuelFilled'></span>&nbsp;
                        <span class='text cumulativeFuel' [innerText]='unitToDisplay'></span>&nbsp;
                    </div>
                </div>
                <ng-template #no_cumulative_data>
                    <div class='col-12 mt-0 mt-md-2 no-data'>-.-
                        <mat-icon matTooltip="{{infoTemplate}}" matTooltipClass="my-custom-tooltip"
                            matTooltipPosition='below'>
                            <mat-icon class="help">help</mat-icon>
                        </mat-icon>
                    </div>
                </ng-template>
            </div>
            <div class="example-loading-shade" *ngIf="isLocoListChanged">
                <el-spinner></el-spinner>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="overview-card second-card">
        <mat-card-content>
            <div class='currentPriceContainer'>
                <div class='row'>
                    <div class='col-12'>
                        <span class="text title" [innerText]="'overview.currentPrice' | translate"></span>
                    </div>
                </div>
                <div class='row cstm-mt-11'>
                    <div class='col-12'>
                        <span class="text description" [innerText]="'overview.pricePerGallon' | translate"></span>
                    </div>
                </div>
                <div class='row cstm-pad'>
                    <div class='col-6'>
                        <input [value]="price" (change)="updatePrice($event)" name="currentPrice" class='current-price' />
                    </div>
                    <div class='col-6'>
                        <volt-button variant="cta" (click)="setPrice()" name="currentPriceSet" class='current-price-set'
                            [innerText]="'general.apply' | translate"></volt-button>
                    </div>
                </div>
                <ng-template #no_cumulative_data>
                    <div class='col-12 mt-0 mt-md-2 no-data'>-.-
                        <mat-icon matTooltip="{{infoTemplate}}" matTooltipClass="my-custom-tooltip"
                            matTooltipPosition='below'>
                            <mat-icon class="help">help</mat-icon>
                        </mat-icon>
                    </div>
                </ng-template>
            </div>
            <div class="example-loading-shade" *ngIf="isLocoListChanged">
                <el-spinner></el-spinner>
            </div>
        </mat-card-content>
    </mat-card>
</div>