import { Component, OnInit, ViewChild } from '@angular/core';
import { Breakpoints, BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { LayoutsService } from '../../services/layouts.service';

@Component({
    selector: 'el-site-layout',
    templateUrl: './site-layout.component.html',
    styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {
  hasBackdrop = false;
  mode = 'side';
  openDrawer = false;
  isHandset = false;
  isSmallPortrait = false;
  @ViewChild('drawer', { static: true }) 

  public drawer: boolean;
  constructor(private layout: LayoutsService, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
      this.layout.drawer = this.drawer;
      this.breakpointObserver.observe(Breakpoints.Handset)
          .subscribe((state: BreakpointState) => {
              this.isHandset = (state.matches) ? true : false;
              this.openDrawer = !this.isHandset && !this.isSmallPortrait;
          });

      this.breakpointObserver.observe([
          '(orientation: portrait)',
          Breakpoints.Small
      ]).subscribe(({ breakpoints }: BreakpointState) => {
          const isInvalid = Object.keys(breakpoints).find(breakpoint =>  breakpoints[breakpoint] === false);
          this.isSmallPortrait = !isInvalid;
          this.openDrawer = !this.isHandset && !this.isSmallPortrait;
      });;
  }
}
