export class AggregateInput {
    operationId: string | undefined;
    operation: string | undefined;
    eventName: string | undefined;
    eventAttribute: unknown | undefined;
    filter : Filter | undefined;
    groupBy: string[] | undefined;
    sort?:{'columnName': string,'direction': string} | undefined;
    pagination?: { 'offset': string; 'limit': string } | undefined
}

export class Filter {
    startTime?: number | undefined;
    endTime?: number | undefined;
    assetGrpId?: string[] | undefined;
}

export class AggregateOperation {
    operationId?: string;
    operation?: string;
    eventName?: string;
    eventAttribute?: string | number | { [key: string]: string | number; };
    groupBy?: string[];
}
