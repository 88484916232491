/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LibraryConfigurationProvider } from '../../../shared/utilities/library-configuration';
import { SiteMenu } from '../../../shared/model/sitemenu-config';
import { LayoutsService } from '../../services/layouts.service';
import { AuthService } from '../../../core/services/auth.service';



@Component({
    selector: 'el-site-sidemenu',
    templateUrl: './site-sidemenu.component.html',
    styleUrls: ['./site-sidemenu.component.scss']
})
export class SiteSidemenuComponent implements OnInit {

  sideMenu: SiteMenu[];
  toggleTheme: string;
  theme:string;
  constructor(private layout: LayoutsService,
  private router: Router, public configurationProvider: LibraryConfigurationProvider,private authservice :AuthService) {}
  
  ngOnInit() : void {
      this.layout.toggleTheme.subscribe(value => this.theme = value);
      this.sideMenu = this.configurationProvider.config['sideMenu'];
      const permission= this.authservice.getSessionDetails()?this.authservice.getSessionDetails().permissions:[];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if(this.sideMenu) {
          this.sideMenu.map(menu => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              menu.visible = (menu.permissionString && permission && permission.length>0 ? (permission.includes(menu.permissionString) ? true : false) : ((menu.children && menu.children.length > 0) ? false : true)) && (menu.visible);
               
              if (menu.children) {
                  menu.children.map(childMenu => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      childMenu.visible = (childMenu.permissionString  && permission && permission.length >0 ? (permission.includes(childMenu.permissionString) ? true : false) : true) && (childMenu.visible);
                      if (childMenu.visible) {
                          menu.visible = true;
                      }
                  
                      if (childMenu.url && this.router.url.includes(childMenu.url[0]))  {
                          menu.expand = true;
                      }
                  });
              }
          });
      }
  }
  
}
