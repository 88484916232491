import { ViewportScroller } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NavigationEnd, Router } from '@angular/router';
import { DateRangePickerComponent } from '@app/modules/shared/components/dateRangePicker/date-range-picker/date-range-picker.component';
import { RecievedDate } from '@app/modules/shared/model/date';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import { MapMarkersService } from '@app/modules/shared/services/map-markers.service';
import { AuthService, BaseTableComponent, emitValue, IColumns, ITableSettings } from '@edgelinc/ui-library';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { MarkerCoordinatesFilter, MarkerFilter, TableFilter } from '../../model/marker';

import { MapListDataSource } from '../../services/map-list-data-source';

@Component({
    selector: 'fuel-app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
    mapListDataSourceComp!: MapListDataSource;
    mapListPayload: MarkerFilter = {};

    tableColumns: IColumns[] = [];
    tableSettings!: ITableSettings;

    receivedDate = { startTime: moment().startOf('day').unix(), endTime: moment().unix(), type: 'Today', isValid: true };
    typeString: { 'value': string, 'viewValue': string }[] = [];
    columnHeader: { 'param': string, 'key': string, 'value': string, 'type': string, 'path': string, 'mapping': string, 'disableSelectOpt': boolean }[] = [];
    size = 'small';
    mapMarkersCoordinatesFilter: MarkerCoordinatesFilter = {size: '25', page: '1', orderBy: 'messageTime', sortOrder: 'DESC'};

    filterCount = 0;
    XTotalCount!: number;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('filterTemplate', { static: true }) filterTemplate: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('dateRangePickerTemplate', { static: true }) dateRangePickerTemplate: any;
    @ViewChild(BaseTableComponent, { static: true }) child!: BaseTableComponent;
    @ViewChild(DateRangePickerComponent, { static: true }) dateRangePicker!: DateRangePickerComponent;
    
    constructor(
        private router: Router,
        private viewPortScroller: ViewportScroller,
        private mapService: MapMarkersService,
        private authService: AuthService,
        private fuelSessionService: FuelSessionService
    ) {
        this.router.events.pipe(// scroll to top while page is navigated
            filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
    }

    ngOnInit(): void {
        const sortState: Sort = { active: 'messageTime', direction: 'desc' };

        this.tableColumns = [
            { field: 'customerId', title: 'Cust. ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'customerId' },
            { field: 'roadNumber', title: 'Loco ID', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'roadNumber' },
            { field: 'messageTime', title: 'Communication Date', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'time' },
            { field: 'mp', title: 'MP', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'mp' },
            { field: 'subdivision', title: 'Subdivision', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'subdivision' },
            { field: 'location', title: 'Location', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'location' },
            { field: 'fuel_level', title: 'Fuel Level', isCustomColumn: false, customTemplate: undefined, binding: true, sortBinding: 'fuel_level' },
        ];
        this.columnHeader = [
            { 'param': '', 'key': 'customerId', 'value': 'Cust. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'roadNumber', 'value': 'Loco. ID', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'mp', 'value': 'MP', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'subdivision', 'value': 'Subdivision', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'location', 'value': 'Location', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
            { 'param': '', 'key': 'fuel_level', 'value': 'Fuel Level', 'type': 'String', 'path': '', 'mapping': '', 'disableSelectOpt': false },
        ];
        this.tableSettings = {
            offSet: 0,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 200, 500],
            defaultSortColumn: 'messageTime',
            defaultSortDirection: sortState.direction,
            exportFileName: 'map_list:DD-MM-YYYY_HH-mm-ss'
        };

        this.mapListDataSourceComp = new MapListDataSource(this.mapService, this.authService);
    }

    ngAfterContentChecked(): void {
        if (this.mapListDataSourceComp != undefined) {
            this.XTotalCount = (this.mapListDataSourceComp.totalCount) <= 5000 ? this.mapListDataSourceComp?.totalCount : 5000;
        }
    }

    refreshMapList($event?: emitValue): void {
        this.filterCount = 0;
        if (this.mapListDataSourceComp && this.tableSettings) {
            this.mapListPayload = this.formRequestPayload({ event: $event });
            this.mapListDataSourceComp.loadMapItems(this.mapMarkersCoordinatesFilter);
        }
    }

    formRequestPayload({ event, filters }: { event?: emitValue, filters?: TableFilter }): MarkerFilter {
        const receivedDate = this.receivedDate.isValid && this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0 ? this.receivedDate : { startTime: moment().startOf('day').unix(), endTime: moment().unix(), type: 'Today', isValid: true };
        if (this.child && this.child.emitValue) {
            event = this.child.emitValue;
        }

        const customFilter: MarkerFilter = {
            sortOrder: event?.sortDirection || 'desc',
            page: event?.offset && event.pageSize ? (event.offset / event.pageSize).toString() : '1',
            size: event?.pageSize.toString() || '25',
            orderBy: event?.sortActive || 'messageTime'
        };

        //TODO: add date range filtering
        // if (receivedDate) {
        //     customFilter.from = receivedDate.startTime;
        //     customFilter.to = receivedDate.endTime;
        // }        

        console.log(receivedDate, filters);

        return customFilter;
    }

    showHideFilter(): void {
        this.child.showHideFilter();
    }
    // call when date range value is changed
    receivedDateValue(receivedDate: RecievedDate): void {
        this.receivedDate = receivedDate;
        if (this.receivedDate.startTime != 0 && this.receivedDate.endTime != 0 && this.receivedDate.isValid) {//call API is date range is valid;future dates not allowed
            this.refreshMapList();
        }
    }
    applyFilter($event: any[]): void {
        const customFilters: TableFilter = {};
        if ($event == undefined || $event.length === 0) {
            return;
        }

        this.filterCount = $event.length;
        for (const filter of $event) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            customFilters[filter.columnName?.key as keyof TableFilter] = filter.fromTextfield as string;
        }

        if (this.mapListDataSourceComp && this.tableSettings) {
            console.log({customFilters});
            this.mapListPayload = this.formRequestPayload({ filters: customFilters });
            this.mapListDataSourceComp.loadMapItems(this.mapMarkersCoordinatesFilter);
        }
    }
    getCondition(value?: string): string | undefined {
        switch (value) {
        case 'eq':
            return 'EQUAL';
        case 'lt':
            return 'LESSER_THAN';
        case 'gt':
            return 'GREATER_THAN';
        case 'btw':
            return 'BETWEEN';
        case 'lte':
            return 'LESSER_THAN_EQUAL';
        case 'gte':
            return 'GREATER_THAN_EQUAL';
        case 'contains':
            return 'CONTAINS';
        default:
            return;
        }
    }
}
