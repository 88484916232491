/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { of as observableOf } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService, BaseTableDataSource } from '@edgelinc/ui-library';
import { EventsService } from '@app/modules/shared/services/events-list.service';
import { CustomFilter } from '../model/filter';
import { RawEvent, Event } from '../model/table';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class EventsListDataSource extends BaseTableDataSource {
  totCount!: number;
  rawEvents: RawEvent[] = [];
  get totalCount(): number {
      return this.totCount;
  }
  get baseEvents(): RawEvent[] {
      return this.rawEvents;
  }
  constructor(private eventsService: EventsService, private authService: AuthService) {
      super();
  }
  loadEvents(filter: CustomFilter) {
      this.loading.next(true);
      this.eventsService.getEvents(filter).pipe(
      // tslint:disable-next-line: deprecation
          catchError(() => observableOf([])),
          finalize(() => this.loading.next(false))
      ).subscribe((events: RawEvent[]) => {
          this.list.next(this.processData(events));
      });
  }
  processData(events: RawEvent[]): Event[]{
      const dataEvents = events.filter(event => event.data !== undefined);

      const formattedData = dataEvents.map(({ data, timestamp }) => {
          let description = '-';
          if (data?.details_json) {
              const details = JSON.parse(data.details_json);
              if (details?.message) {
                  description = details.message;
              }
          }

          return ({
              id: data?.id,
              alarm_severity: data?.alarm_severity || '-',
              category: data?.category || '-',
              timestamp: moment(timestamp).format('DD MMM YYYY HH:mm:ss').toString() || '-',
              customerId: data?.customer_id || '-',
              roadNumber: data?.loco_id_str || '-',
              locationName: '-',
              locationType: '-',
              description,
          });
      });
      this.totCount = formattedData.length;
      this.rawEvents = dataEvents;

      return formattedData;
  }
}
