/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { of as observableOf } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService, BaseTableDataSource } from '@edgelinc/ui-library';
import { FuelTenant } from '@app/modules/shared/model/fuel-tenant';
import { UnitConversion } from '@app/modules/shared/utilities/unit-conversion';
import { AggregateGroupsService } from '@app/modules/shared/services/aggregate-groups.service';

import * as AggregateGroups from '../model/aggregate-groups';

@Injectable({
    providedIn: 'root'
})
export class KpiListDataSource extends BaseTableDataSource {
    unitToDisplay: string | undefined;
    unitToConvert: string | undefined;
    unitConversion!: UnitConversion;
    displayedFuel: string | undefined;
    totCount!: number;
    get totalCount(): number {
        return this.totCount;
    }
    constructor(private aggregateGroupService: AggregateGroupsService, private authService: AuthService) {
        super();
    }
    loadKPIGroup(eventGroup: AggregateGroups.AggregateGroupsPayload) {
        this.unitConversion = new UnitConversion();
        if (this.authService.getSessionDetails() && this.authService.getSessionDetails().tenantSpecificData) {
            const tenantSpecificData = this.authService.getSessionDetails().tenantSpecificData as FuelTenant;
            if (tenantSpecificData && tenantSpecificData.unitToDisplay) {
                this.unitToDisplay = Object.values(tenantSpecificData.unitToDisplay)[0] as string;
                this.unitToConvert = Object.keys(tenantSpecificData.unitToDisplay)[0];
            }
        }
        this.loading.next(true);
        this.aggregateGroupService.getAggregateGroups(eventGroup).pipe(
            // tslint:disable-next-line: deprecation
            catchError(() => observableOf([])),
            finalize(() => this.loading.next(false))
        )
            .subscribe((events: any) => {
                this.list.next(this.processGroupData(events));
            });
        
    }

    processGroupData(data: AggregateGroups.AggregationKPIGroupsResponse): AggregateGroups.AggregationKPITableItem[] {
        if (data == undefined || data?.length === 0) {
            return [];
        }

        const getFuelAggregationData = (aggregation: AggregateGroups.Aggregation[] | [], setUnit?: boolean): string => {
            if (aggregation == undefined || aggregation?.length === 0) {
                return '';
            }

            const [item] = aggregation;
            const unit = (item.total_fuel_change_unit || item.total_fuel_consumed_unit) as string;
            return setUnit ? `${item.result} ${unit}` : `${item.result}`;
        };

        const getFuelUsage = (fuelUsage: AggregateGroups.Aggregation[]): Record<string, number> => {
            const resultObject: Record<string, number> = {};
            for (const item of fuelUsage) {
                const { current_loco_state, result } = item;
                resultObject[current_loco_state as string] = result;
            }
            return resultObject;
        };

        const result:AggregateGroups.AggregationKPITableItem[] = data.map(({ customerId, roadNumber, aggregations }: AggregateGroups.AggregateItemResponse) => {
            const { FuelAdded, FuelUsage, TotalFuelUsage, CumulativeFuel } = aggregations;
            const item: AggregateGroups.AggregationKPITableItem = {
                customerId,
                roadNumber,
                modelID: '-',
                FuelAdded: getFuelAggregationData(FuelAdded, true),
                FuelUsage: getFuelUsage(FuelUsage),
                TotalFuelUsage: getFuelAggregationData(TotalFuelUsage, false),
                CumulativeFuel: getFuelAggregationData(CumulativeFuel, true)
            };

            if (item.FuelUsage) {
                const fuelUsageKeys = Object.keys(item.FuelUsage);
                for (const key of fuelUsageKeys) {
                    const total = Number(item.TotalFuelUsage) || 1;
                    const usage = item.FuelUsage ? (item.FuelUsage[key as keyof AggregateGroups.FuelUsage] as number): 0;
                    item[key as keyof AggregateGroups.FuelUsage] = this.unitConversion.decimalRound((usage/total)*100) + '%';
                }
            }
            return item;
        });

        this.totCount = result.length;

        return result;
    }
    validateUnit(unitToConvert: string | undefined, total_fuel_unit: string, result: number): string {
        if (total_fuel_unit && (unitToConvert == total_fuel_unit)) {
            //units matched and convert to K,M,G format for display
            this.displayedFuel = this.unitConversion.convertFormat(result);
        } else {
            // units not matched;convert to tenantSpecific units and convert to K,M,G format for display
            this.displayedFuel = this.unitConversion.convertFormat(this.unitConversion.convertUnits(result, this.unitToConvert));
        }
        return this.displayedFuel;
    }
}
