/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, ViewChild,  AfterViewInit, ElementRef, Output, Input, EventEmitter, AfterContentChecked} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, tap, delay } from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ExportCSVService } from '../../../core/utilities/export-csv.service';
import { FullScreen } from '../../utilities/full-screen';
import * as moment_ from 'moment';
import { IColumns, ITableSettings, emitValue } from '../../interfaces/table-settings';
import { BaseTableDataSource } from '../../utilities/base-table-data-source';
import { MatCheckbox } from '@angular/material/checkbox';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

const moment = moment_;

@Component({
    selector: 'el-base-table',
    templateUrl: './base-table.component.html',
    styleUrls: ['./base-table.component.scss']
})
export class BaseTableComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @Input() dataSource: BaseTableDataSource | any;
  @Input() tableColumns: IColumns[];
  @Input() tableSettings:ITableSettings;
  @Input() XTotalCount: number;
  @Input() showCheckboxes: boolean
  @Input() dateRangePicker = false;
  @Input() searchSelect = false;
  @Input() searchTextBox = true;
  @Input() showFilterBtn: boolean;
  @Input() exportBtn = true;
  @Input() refreshBtn = true;
  @Input() expandBtn = true;
  @Input() dateRangePickerTemplate;
  @Input() searchSelectTemplate;
  @Input() filterTemplate;
  @Input() filterCount: number;
  @Input() showInfo = false;
  @Input() size = 'large';
  @Input() isRowClicked = false;

  @Output() refreshList = new EventEmitter<emitValue>();
  @Output() selectedList = new EventEmitter<unknown>();
  @Output() clickRow = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('manualPageInput', { static: true }) manualPageInput: ElementRef;
  @ViewChild('showMoreDiv', { static: true }) showMoreColTemplate: ElementRef;

  @ViewChild('el', { static: true }) actionTemplate: ElementRef;
  manualPage = 1;
  displayedColumnHeaders = {};
  displayedColumns = [];
  search = '';
  emitValue:emitValue;
  tableMinWidth = '650px';
  isViewMaximized = false;

  showExpandIcon = false;
  public selection = new SelectionModel<BaseTableDataSource | any>(true, []);
  showFiltersCount = true;
  fullScreen: FullScreen;
  showFrom = 1;
  showTo = 25;
  showToTemp = 25;
  isHandset = false;
  selectedRow;

  isShown:boolean
  public get Shown(): boolean{
      return this.isShown;
  }

  public set Shown(value: boolean) {
      this.isShown = value;
  }
 
  constructor(public exportCSVService: ExportCSVService, private breakpointObserver: BreakpointObserver) {}
  ngOnInit(): void {
      this.breakpointObserver.observe(Breakpoints.Handset)
          .subscribe((state: BreakpointState) => {
              this.isHandset = (state.matches) ? true : false;
          });
    
      // Start : Load default data on page with default sorting order
      // segregate displayedColumnHeaders, displayedColumns for table values
      if (this.tableColumns) {
          this.tableColumns.push ({ field: 'showMore', title: '', isCustomColumn: true, customTemplate: this.showMoreColTemplate , binding: false });
          this.tableColumns.map(ele => {
              this.displayedColumnHeaders[ele.field] = ele.title;
          });
          this.displayedColumns = this.tableColumns.map(ele => ele.field);

          // for mobile view only, push 'actions' button next to 'showmore' div
          const actions = this.tableColumns.filter(s => s.field === 'actions');
          if (actions && actions[0]) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              this.actionTemplate = actions[0].customTemplate;
          }
      }

      if (this.tableSettings) {
          this.paginator.pageIndex = this.tableSettings.offSet;
          this.paginator.pageSize = (this.paginator.pageSize ? this.paginator.pageSize : this.tableSettings.pageSize);
          this.sort.active = this.tableSettings.defaultSortColumn;
          this.sort.direction = this.tableSettings.defaultSortDirection;

          this.emitValue = {
              pageSize: this.paginator.pageSize,
              offset: this.tableSettings.offSet,
              sortActive: this.tableSettings.defaultSortColumn,
              sortDirection: this.tableSettings.defaultSortDirection,
              searchInput: ''
          };
          this.emitDataToParent(this.emitValue);
      }
      // End : Load default data on page with default sorting order

    
      this.fullScreen = new FullScreen('table-id');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.showExpandIcon = (this.fullScreen.angularElement.requestFullscreen || this.fullScreen.angularElement.mozRequestFullScreen ||this.fullScreen.angularElement.webkitRequestFullscreen || this.fullScreen.angularElement.msRequestFullscreen);   
      // End: changes w.r.t view full screen

      this.showTo = this.tableSettings.pageSize;
      this.showToTemp = this.tableSettings.pageSize;
  }
  ngAfterViewInit() : void{
      // whenever sorting has changed value, make pagination index to start from 0 again
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

      // Binding Search text box to API : with default sorting order
      if(this.searchInput && this.searchInput.nativeElement){
          fromEvent(this.searchInput.nativeElement, 'keyup')
              .pipe(
                  debounceTime(250),
                  delay(0),
                  distinctUntilChanged(),
                  tap(() => {
                      this.paginator.pageIndex = this.tableSettings.offSet;
                      this.emitValue = {
                          pageSize: this.paginator.pageSize,
                          offset: this.paginator.pageIndex,
                          sortActive: this.tableSettings.defaultSortColumn,
                          sortDirection: this.tableSettings.defaultSortDirection,
                          searchInput: this.search
                      };
                      this.emitDataToParent(this.emitValue);
                  })
              )
              .subscribe();
      }
    

      // Binding "goto page" input keyup event
      fromEvent(this.manualPageInput.nativeElement, 'keyup')
          .pipe(
              debounceTime(1000),
              delay(0),
              distinctUntilChanged(),
              tap(() => {
                  if (this.manualPage < (this.paginator.getNumberOfPages() + 1)) {
                      this.paginator.pageIndex = this.manualPage - 1;
                  } else {// go to last page if invalid no of pages is enter
                      this.paginator.pageIndex = this.paginator.getNumberOfPages() - 1;
                      this.manualPage = this.paginator.pageIndex + 1;
                  }
                  this.emitValue = {
                      pageSize: this.paginator.pageSize,
                      offset: (this.tableSettings.offSet === 0) ? this.paginator.pageIndex * this.paginator.pageSize : (this.manualPage * this.paginator.pageSize) / (this.paginator.pageSize),
                      sortActive: this.createSortLookup(this.sort.active),
                      sortDirection: this.sort.direction,
                      searchInput: this.search
                  };
                  this.emitDataToParent(this.emitValue);
              })
          )
          .subscribe();

      // Binding "goto page" input blur event
      fromEvent(this.manualPageInput.nativeElement, 'blur')
          .pipe(
              debounceTime(500),
              delay(0),
              distinctUntilChanged(),
              tap(() => {
                  this.manualPage = this.paginator.pageIndex + 1;
                  this.emitValue = {
                      pageSize: this.paginator.pageSize,
                      offset: (this.tableSettings.offSet === 0) ? this.paginator.pageIndex * this.paginator.pageSize : (this.manualPage * this.paginator.pageSize) / (this.paginator.pageSize),
                      sortActive: this.createSortLookup(this.sort.active),
                      sortDirection: this.sort.direction,
                      searchInput: this.search
                  };
                  this.emitDataToParent(this.emitValue);
              })
          )
          .subscribe();

      // Pagination "next" and "prev" button's API integration
      merge(this.sort.sortChange, this.paginator.page)
          .pipe(
              delay(0),
              tap(() => {
                  this.manualPage = this.paginator.pageIndex + 1;
                  this.emitValue = {
                      pageSize: this.paginator.pageSize,
                      offset: (this.tableSettings.offSet === 0) ? this.paginator.pageIndex * this.paginator.pageSize : (this.manualPage * this.paginator.pageSize) / (this.paginator.pageSize),
                      sortActive: this.createSortLookup(this.sort.active),
                      sortDirection: this.sort.direction,
                      searchInput: this.search
                  };
                  this.emitDataToParent(this.emitValue);
              })
          )
          .subscribe();

      // setting table min width, dynamically depending on the number of columns in any table, so that we get scroll in suitable width, during responsive screen
      setTimeout(() => {
          const size = (this.size == 'small') ? 100 : 143;
          this.tableMinWidth = (this.displayedColumns.length * size).toString() + 'px';
      }, 1);
      if (this.dataSource && this.dataSource.list && this.dataSource.list.value) {
          // Object.keys(this.dataSource.list.value).forEach(ele => ele['showMore'] = true);
          for (const ele of Object.keys(this.dataSource.list.value))  ele['showMore'] = true;
      }
  }

  ngAfterContentChecked(): void {
      this.computeInfo();
  }

  refreshDataOnPage() :void {
     
      this.computeInfo();
      // on refresh, the state of the page should retain : any searches/sorying/pagination combinations as it is
      this.emitValue = {
          pageSize: this.paginator.pageSize,
          offset: (this.tableSettings.offSet === 0) ? this.paginator.pageIndex * this.paginator.pageSize : (this.manualPage * this.paginator.pageSize) / (this.paginator.pageSize),
          sortActive: this.createSortLookup(this.sort.active),
          sortDirection: this.sort.direction,
          searchInput: this.search
      };
      this.emitDataToParent(this.emitValue);
  }

  computeInfo(): void {

      this.showToTemp = (this.paginator.pageIndex * this.paginator.pageSize) + this.paginator.pageSize;
      this.showFrom = (this.paginator.pageIndex * this.paginator.pageSize) + 1;
      this.showTo = (this.showToTemp < this.XTotalCount || this.XTotalCount === undefined) ? this.showToTemp : this.XTotalCount;
  }
  
  showHideFilter() :void{
      // show/hide filter popover
      this.isShown = ! this.isShown;
  }

  resetSearch():void  {
      // on search reset : Load default data on page with default sorting order
      this.search= '';
      this.paginator.pageIndex = this.tableSettings.offSet;
      this.search = '';
      this.emitValue = {
          pageSize: this.tableSettings.pageSize,
          offset: (this.tableSettings.offSet === 0) ? this.paginator.pageIndex * this.paginator.pageSize : (this.manualPage * this.paginator.pageSize) / (this.paginator.pageSize),
          sortActive: this.tableSettings.defaultSortColumn,
          sortDirection: this.tableSettings.defaultSortDirection,
          searchInput: this.search
      };
      this.emitDataToParent(this.emitValue);
  }

  updateManualPage(indexNumber: number) : void{
      if (indexNumber && indexNumber <= (this.paginator.getNumberOfPages() + 1)) {
          this.manualPage = indexNumber;
          this.paginator.pageIndex = indexNumber - 1;
      }
  }
  filteredObject(object: { [x: string]: string; },filter: string | string[]): { [x: string]: string; }{
      if(!Array.isArray(filter)) {
          filter = [filter.toString()];
      }
      const newObject = {};
      for(const index in object) {
          if(!filter.includes(index)) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              newObject[index] = object[index];
          }
      } 
      return newObject;
  }
  exportData() : void {
      // export just waht you see on the page to a csv file
      const processedData = [];
      let dateFormat = '';
      // filter out ['actions','selection'] columns from export csv file
      const filteredColumnHeaders = this.filteredObject(this.displayedColumnHeaders,['actions','selection','showMore','showLess']);
      processedData.push(filteredColumnHeaders);
      if (this.dataSource && this.dataSource.list && this.dataSource.list.value) {
          for (const mainElement of (this.dataSource.list.value)) {
              const object = {};
              for (const element of Object.keys(filteredColumnHeaders)) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  object[element] = mainElement[element] ? mainElement[element] :'-';
              }
              processedData.push(object);
          }
      }
      // pass respective date format
      const formatPassed = (this.tableSettings.exportFileName).split(':');
      dateFormat = formatPassed && formatPassed[1]!= undefined ? formatPassed[1] : 'DD-MM-YYYY';
      // export data into a csv file
      this.exportCSVService.downloadFile(processedData,  (this.tableSettings.exportFileName).replace(dateFormat,'') + `${moment(new Date()).format(dateFormat).toString()}.csv`);
  }

  emitDataToParent(value:emitValue): void {
      this.refreshList.emit(value);
  }

  /*sortByChanged($event): void {
      this.emitValue = {
          pageSize: this.paginator.pageSize,
          offset: this.paginator.pageIndex,
          sortActive: $event.sortBy,
          sortDirection: $event.orderBy,
          searchInput: this.search
      };
      this.emitDataToParent(this.emitValue);
  }*/

  maximizeView(): void {
      this.isViewMaximized = true;
      this.fullScreen.maximizeView('table-id');
  }

  minimizeView() :void {
      this.isViewMaximized = false;
      this.fullScreen.minimizeView('table-id');
  }

  public isSortingDisabled(binding: boolean): boolean {
      return !binding ? true : false;
  }

  public showMoreOption(element: BaseTableDataSource | any): BaseTableDataSource |any {
      if (element['showMore'] === undefined) {
          element['showMore'] = false;
      } else {
          element['showMore'] = !element['showMore'];
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return element;
  }

  createSortLookup(sortActive: string) : string {
      const node = this.tableColumns.filter(value => value.field === sortActive);
      return node && node[0] && node[0].sortBinding ? node[0].sortBinding: sortActive;
  }

  public isAllSelected(): boolean {
      const numberRows = this.dataSource && this.dataSource.list ? this.dataSource.list.value.length : 0;
      let numberSelected = 0;
      if (this.dataSource && this.dataSource.list) {
          for (const element of this.dataSource.list.value) {
              const value = this.selection.selected.find(index => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  return index[this.tableSettings.uniqueId] === element[this.tableSettings.uniqueId];
              });
              if (value) { numberSelected++ ; }
          }
      }
      return numberRows !== 0  && numberSelected !== 0 ? numberRows === numberSelected : false;
  }

  public masterToggle(reference: MatCheckbox | any) : void {
      if (this.isAllSelected()) {
          reference.checked = false;
          for (const row of this.dataSource.list.value) {
              this.unCheck(row);
          }
      } else {
          reference.checked = true;
          for (const row of this.dataSource.list.value) {
              this.check(row);
          }
      }
      this.selectedList.emit(this.selection.selected);
  }

  public checkboxChangeEvent($event: MatCheckbox | any, row: BaseTableDataSource | unknown) : void {
      const returnValue =  $event.checked ? this.check(row) : this.unCheck(row);
      this.selectedList.emit(this.selection.selected);
      return returnValue;
  }

  check(row: BaseTableDataSource | unknown) : void{
      this.selection.select(row);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const found : BaseTableDataSource = this.selection.selected.find(x => x[this.tableSettings.uniqueId] === row[this.tableSettings.uniqueId]);
      if (found) { found['checked'] = true; }
  }

  public unCheck(row: BaseTableDataSource | unknown): void {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const found: BaseTableDataSource = this.selection.selected.find(x => x[this.tableSettings.uniqueId] === row[this.tableSettings.uniqueId]);
      if (found) { found['checked'] = false; }
      this.selection.deselect(found);
  }

  isChecked(row: BaseTableDataSource | unknown): boolean {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const found : BaseTableDataSource = this.selection.selected.find(x => x[this.tableSettings.uniqueId] === row[this.tableSettings.uniqueId]);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return found != undefined ? found['checked'] : false;
  }


  rowClicked(row: BaseTableDataSource | unknown) : void {
    //   console.log('[CLICK]', row);
      if(this.isHandset) {
          this.selectedRow = this.selectedRow === row ? undefined : row;
      }
      this.clickRow.emit(row);
  }
}