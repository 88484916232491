<div>
    <div class='row donut-chart'>
        <div *ngIf='!DataEmpty ;else no_data'>
            <highcharts-chart *ngIf="!DataEmpty" [Highcharts]="Highcharts" [options]="chartOptions"
                class="chart-container">
            </highcharts-chart>
        </div>
        <ng-template #no_data>
            <div class='col-12 mt-2 mt-md-1 no-data'>-.-
                <mat-icon matTooltip="{{infoTemplate}}" matTooltipClass="my-custom-tooltip" matTooltipPosition='below'>
                    <mat-icon class="help">help</mat-icon>
                </mat-icon>
            </div>
        </ng-template>
    </div>
</div>