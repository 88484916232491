import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsAlertsRoutingModule } from './events-alerts-routing.module';
import { MaterialModule, UiLibraryModule } from '@edgelinc/ui-library';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule } from '@angular/forms'; 
import { BrowserAnimationsModule } from 
    '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatButtonModule } from '@angular/material/button';
import { AgmCoreModule } from '@agm/core';

import { ChartViewComponent } from './components/chart-view/chart-view.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { SingleEventComponent } from './components/single-event/single-event.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [ChartViewComponent, ListViewComponent, SingleEventComponent],
    imports: [
        SharedModule, 
        UiLibraryModule,
        CommonModule,
        EventsAlertsRoutingModule,
        MaterialModule,
        TranslateModule,
        HighchartsChartModule,
        SharedModule,
        FormsModule, 
        MatButtonModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        AgmCoreModule.forRoot(),
    ],
    exports: [ChartViewComponent, ListViewComponent, SingleEventComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsAlertsModule {}
