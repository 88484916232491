import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * ngx translate httpLoaderfactory.
 * 
 * @param {http} http httpclient.
 * @returns {object} TranslateHttpLoader configuration object.
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}