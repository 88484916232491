import { MapViewComponent } from './components/map-view/map-view.component';
import { MaterialModule, UiLibraryModule, MapsConfig} from '@edgelinc/ui-library';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';

import { MapRoutingModule } from './map-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ListViewComponent } from './components/list-view/list-view.component';
import { HighchartsChartModule } from 'highcharts-angular';

export let InjectorInstance : Injector;
@NgModule({
    declarations: [MapViewComponent, ListViewComponent],
    imports: [
        BrowserModule,
        SharedModule, 
        UiLibraryModule,
        TranslateModule,
        MaterialModule,
        MapRoutingModule,
        HighchartsChartModule,
        AgmCoreModule.forRoot()
    ],
    exports : [MapViewComponent, ListViewComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers:[
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        {provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfig},
    ]
})
export class MapModule {
    constructor(private injector: Injector){
        InjectorInstance = injector;
    }
}
