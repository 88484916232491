<div id="table-id">
    <div class="row mt-4 mr-2 ml-2 justify-content-sm-end">
        <div class="col-12 col-sm-3 mb-4 mb-sm-0" *ngIf="dateRangePicker">
            <ng-template [ngTemplateOutlet]="dateRangePickerTemplate"></ng-template>
        </div>
        <div class="col-12 col-sm-3 mb-4 mb-sm-0" *ngIf="searchSelect">
            <ng-template [ngTemplateOutlet]="searchSelectTemplate"></ng-template>
        </div>
        <div class="col-12 col-sm-3 mb-4 mb-sm-0" [hidden]="!searchTextBox">
            <div class="search-container">
                <div class="search-field">
                    <div class='search-img' *ngIf="search.length === 0"></div>
                    <input placeholder="Search" value="" #searchInput [(ngModel)]="search">
                    <div (click)="resetSearch()" *ngIf="search.length > 0">
                        <div class="cancel-img cross"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-2 col-md-2 mb-4 mb-sm-0" *ngIf="showFilterBtn">
            <button id="filterButton" (click)="showHideFilter()" mat-stroked-button class="filterColumns" [style.outline]="isShown ? '5px auto #508be4' : 'none'">
                <span class="buttonText">
                  Filter
                  <span *ngIf="showFiltersCount && filterCount>0" class="filtersCount">{{filterCount}}</span>
                </span>
                <span class='filter-img'></span>
            </button>
        </div>
        <div class="col-6 col-sm-2 col-md-2 mb-2 mb-sm-0" *ngIf="exportBtn">
            <button mat-stroked-button (click)="exportData()" style="width:100%">Export</button>
        </div>
        <!--<div class="col-3 table-sort-dropdown">
        <app-table-sort-dropdown [tableColumns]="tableColumns" [defaultSort]="sort.active" [defaultOrder]="sort.direction" (sortByColumn)="sortByChanged($event)"></app-table-sort-dropdown>
        </div>-->
        <div class="col-3 col-sm-1 col-md-1 mb-2 mb-sm-0" *ngIf="refreshBtn">
            <button mat-icon-button color="primary" style="width:100%">
                <mat-icon (click)="refreshDataOnPage()">refresh</mat-icon>
            </button>
        </div>
        <div class="col-3 col-sm-1 col-md-1 mb-2 mb-sm-0" *ngIf='showExpandIcon && expandBtn'>
            <div *ngIf=!isViewMaximized>
                <button title="Toggle fullscreen view" class="max-button" style="width:100%" (click)="maximizeView()">
                        <div class="expand-img"></div>
                </button>
            </div>
            <div *ngIf="isViewMaximized">
                <button title="Exit fullscreen view" class="max-button" style="width:100%" (click)="minimizeView()">
                    <div class="non-expand-img"></div>
                </button>
            </div>
        </div>
    </div>
    <div class="row mt-2 ml-2" *ngIf="showInfo">
        <div class="col-12 mb-2 mb-sm-0" *ngIf="dataSource && dataSource.list?.value?.length >0">
            Showing {{showFrom}} to {{showTo}} of {{XTotalCount}} records
        </div>
    </div>
    <div class="row mt-2" [ngClass]="[ isViewMaximized? (((dataSource &&dataSource.list.value.length > 0) || (dataSource && dataSource.loading$ | async))? 'maximisedHeight additionHeight':'maximisedHeight') : (((dataSource &&dataSource.list.value.length > 0) || (dataSource && dataSource.loading$ | async))? 'minimisedHeight properClass':'minimisedHeight')]">
        <div class="col-12 view-container containerClass">
            <div class="table-container containerClass" [style.overflowX]="dataSource && !dataSource.list.value.length? 'hidden':'auto'" [ngClass]="{'desktop': !isHandset, 'handset': isHandset, 'table-clicked': isRowClicked}">
                <mat-table [dataSource]="dataSource" style="width: 100%;" [ngClass]="[(dataSource && dataSource.list.value.length > 0 || (dataSource && dataSource.loading$ | async)) ? 'mat-table mat-table-withdata' : 'mat-table']" [style.min-width]="tableMinWidth" matSort
                    matSortDisableClear>
                    <ng-container *ngFor="let col of tableColumns; let i = index;" [matColumnDef]="col.field">
                        <span *ngIf="showCheckboxes && i === 0">
                            <mat-header-cell *matHeaderCellDef style="max-width: 8%;" [ngClass]="showCheckboxes ? 'checkbox-class': ''">
                            <mat-checkbox #ref (change)="$event ? masterToggle(ref) : null;" 
                                                [checked]="selection.hasValue() && isAllSelected()">
                            </mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" style="max-width: 8%;" class="checkbox-mat-cell" [ngClass]="showCheckboxes ? 'checkbox-class': ''">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="checkboxChangeEvent($event, row)"
                                            [checked]="isChecked(row)">
                            </mat-checkbox>
                            </mat-cell>
                        </span>
                        <span>
                            <ng-container #tableData>
                                    <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled(col.binding)">
                                                <span> {{ displayedColumnHeaders[col.field] }}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [attr.data-label]="displayedColumnHeaders[col.field]">
                            <span *ngIf="col.isCustomColumn == false" [innerText]="element[col.field] ? element[col.field] : '-'"></span>
                            <ng-template *ngIf="col.isCustomColumn == true" [ngTemplateOutlet]="col.customTemplate" [ngTemplateOutletContext]="{$implicit: element, col: col.field}">
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    </span>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)" [ngClass]="[(tableColumns && tableColumns.length > 6) ? ((row['showMore']== undefined || row['showMore'] == true) ? 'showMoreParent' : 'showLessParent'):'lesscolumns',selection.isSelected(row)? 'checkbox-mat-row':'', row === selectedRow ? 'hover-effect': '']">

                    </mat-row>
                </mat-table>
            </div>
            <div class="row example-loading-shade" *ngIf="dataSource && dataSource.loading$ | async">
                <el-spinner [style.display]="dataSource && dataSource.loading$ ? 'block' : 'none'"></el-spinner>
            </div>
            <div [hidden]="dataSource && dataSource.loading$ | async">
                <div *ngIf="dataSource && !dataSource.list.value.length" class="error-message-container">
                    <div>
                        <div class="empty-message-title">
                            Oops! No results found <span *ngIf="search">for</span>&nbsp;<q *ngIf="search" [innerText]="search"></q>
                        </div>
                        <div class="empty-message-description">
                            Please try again by changing the filter(s)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div [style.display]="isShown ? 'block': 'none'">
            <ng-template [ngTemplateOutlet]="filterTemplate"></ng-template>
        </div>
    </div>
    <div class="row mt-2 mt-sm-0 mb-3 mb-sm-0" [style.display]="dataSource && dataSource.list.value.length > 0 ? 'flex' : 'none'">
        <div class="col-12 col-sm-6 col-md-6">
            <mat-paginator appearance="outline" [length]=XTotalCount [pageSize]="paginator.pageSize" [pageIndex]="0" [pageSizeOptions]="tableSettings ? tableSettings.pageSizeOptions:[]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="col-12 offset-sm-2 col-sm-4 offset-md-3 col-md-3 goto-container">
            <span class="label">Go to Page:</span>
            <mat-form-field class="page-num-container">
                <input id="manualPageText" #manualPageInput matInput [(ngModel)]="manualPage" (ngModelChange)="updateManualPage($event)">
            </mat-form-field>
        </div>
    </div>
</div>

<ng-template #showMoreDiv let-element let-col='col'>
    <span [ngClass]="element['showMore'] == undefined || element['showMore'] == true ? 'showMore':'showLess'" (click)="showMoreOption(element)">
         {{element['showMore'] == undefined || element['showMore']== true ? 'Show More':'Show Less'}}
    </span>
    <span class='ml-auto'>
        <ng-template [ngTemplateOutlet]="actionTemplate" [ngTemplateOutletContext]="{$implicit: element, col: col.field}"></ng-template>
    </span>
</ng-template>