import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppStorage } from '../../data/app-storage';
import { Token } from '../../data/token';
import { LibraryConfigurationProvider } from '../../shared/utilities/library-configuration';
import { AppStorageService } from '../utilities/app-storage.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
  access_token: string;
  refresh_token: string;
  storageName = 'data';
  url='';
  constructor(private http:HttpClient,private appStorage:AppStorageService,private router:Router,private libraryConfigurationProvider:LibraryConfigurationProvider) {}

  public isAuthenticated(): boolean {

      const token = this.getSessionDetails();
      const returnValue = token != undefined && token.token && token.token.access_token !== '';
      // console.log('Reading token from other tab : ' + ret);
      return returnValue;
  }

  public login(name:string,key:string): Observable<Token> {
      this.url = this.libraryConfigurationProvider.config ? `${this.libraryConfigurationProvider.config['environment'] as string}/oauth/token` : '/oauth/token';
      const data = `grant_type=password&password=${key}&response_type=token&username=${(name)}`;
      return this.http.post<Token>(this.url, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .pipe( map(response => {
              return response;
          }))
          .pipe(catchError(error =>{
              return throwError(error);
          }));
  }
  logout(): void {
      localStorage.clear();
      this.router.navigate(['/']);
  }
  refreshToken(): Observable<Token> {
      this.refresh_token = this.refresh_token ? this.refresh_token : this.getSessionDetails().token.refresh_token;
      this.url = this.libraryConfigurationProvider.config ? `${this.libraryConfigurationProvider.config['environment'] as string}/oauth/token` : '/oauth/token';
      const data = 'grant_type=refresh_token&refresh_token=' + this.refresh_token;
      return this.http.post<Token>(this.url, data, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': '' }
      })
          .pipe( map(response => {
              return response;
          }))
          .pipe(catchError(error =>{
              return throwError(error);
          }));
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  setSessionDetails(data:Token, userData:object, tenantSpecificData:object, permissions:string[]):void {
      this.appStorage.setToAppStorage(this.storageName, JSON.stringify({
          token: data,
          userData: userData,
          tokenRefreshing: false,
          permissions: permissions,
          tenantSpecificData: tenantSpecificData
      }));
  }
  getSessionDetails():AppStorage {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const returnValue = this.appStorage.getFromAppStorage(this.storageName);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return returnValue;
  }
  getScopeValues():{zoneId: string, tenantId: string} {
      let zoneId:string, tenantId:string, result:string;
      let scope = [], predixZoneId = [];
      let tenantIds: string;
      if(this.getSessionDetails() && this.getSessionDetails().token) {
          scope = this.getSessionDetails().token.scope.split(' ');
          for (const element of scope) {
              if (element!='uaa.user' && String(element).includes('.user')) {
                  result = String(element);
                  break;
              }
          }
          if (result != undefined && result.length > 1) {
              predixZoneId = result.split('.');
          }
          if (predixZoneId != undefined && predixZoneId.length >= 2 && predixZoneId[2] != undefined) {
              tenantIds = String(predixZoneId[2]);
              zoneId = tenantIds.slice(0, 19);
              tenantId = tenantIds;
          }
          return { zoneId: zoneId, tenantId: tenantId };
      } else {
          return { zoneId: '0', tenantId: '0' };
      }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getHeaders() {
      const scopeValues = this.getScopeValues();
      return {
          'Content-Type': 'application/json',
          'Predix-Zone-Id': scopeValues['zoneId'],
          'Tenant-Id': scopeValues['tenantId'],
          'Zone-Id': scopeValues['tenantId'].slice(Math.max(0, scopeValues['tenantId'].indexOf('tenant')))
      };
  } 
}
