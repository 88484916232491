import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LibraryConfigurationProvider, UiLibraryModule } from '@edgelinc/ui-library';
import {  HttpClient, HttpClientModule } from '@angular/common/http';
import { initAppWithHttp } from './modules/shared/utilities/init-app';
import { ConfigFromApp } from './modules/shared/utilities/config-from-app';
import { ConfigurationStore } from './modules/shared/utilities/configurator-helper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './modules/shared/utilities/http-loader';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { TimestampCalculation } from './modules/shared/utilities/timestamp-calculation';
import { UnitConversion } from './modules/shared/utilities/unit-conversion';
import { FuelSessionService } from './modules/shared/services/fuel-session.service';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxMatSelectSearchModule,
        DashboardModule,
        UiLibraryModule.forRoot({
            config: {
                provide: LibraryConfigurationProvider,
                useClass: ConfigFromApp,
            },
        }),
        HttpClientModule,
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        )
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initAppWithHttp,
            multi: true,
            deps: [ConfigurationStore],
        },
        TimestampCalculation,
        UnitConversion,
        FuelSessionService,
        {provide: OverlayContainer, useClass: FullscreenOverlayContainer}// DE156952 fix and support correct displaying of overlay elements in fullscreen mode.
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
