import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { RecievedDate } from '@app/modules/shared/model/date';
import { FuelSession } from '@app/modules/shared/model/fuel-session';
import { FuelSessionService } from '@app/modules/shared/services/fuel-session.service';
import * as moment from 'moment';
@Component({
    selector: 'fuel-app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  maxDate = new Date();
  range!: FormGroup;
  toolTiptext ='';
  @Input() startTime = 0;
  @Input() endTime = 0;
  @Input() cardName = '';
  @ViewChild('picker') datePicker!: MatDatepicker<Date>;
  @Output() emitDateValue = new EventEmitter<RecievedDate>();
  //@Output() emitDateValue = new EventEmitter<Date>();
  constructor(private fuelSessionService:FuelSessionService) {
      this.range = new FormGroup({
          start: new FormControl(),
          end: new FormControl()
      });
  }
  ngOnInit(): void {
      const fuelSession:FuelSession = JSON.parse(this.fuelSessionService.getFuelSession()) as FuelSession;
      if(fuelSession && fuelSession.card){//get stored card data from localStorage
          const currentCard = fuelSession.card.find((card: { cardName: string; }) => card.cardName==this.cardName);
          if(currentCard?.type !=undefined){
              this.range.controls['start'].setValue(new Date(moment(currentCard.startTime).format('M/D/YYYY')));
              this.range.controls['end'].setValue(new Date(moment(currentCard.endTime).format('M/D/YYYY')));
              this.toolTiptext = `${moment(currentCard.startTime).format('M/D/YYYY')} - ${moment(currentCard.endTime).format('M/D/YYYY')}`;
          }
      }
  }
  // function call date range picker value is selected
  dateRangeChange(dateRangeStart:string, dateRangeEnd:string):void {
      if(dateRangeStart !=='' && dateRangeEnd !==''){
          this.startTime = moment(dateRangeStart,'MM/DD/YYYY').valueOf();
          this.endTime = moment(dateRangeEnd,'MM/DD/YYYY').valueOf();
          this.emitDateValue.emit({'startTime':this.startTime,'endTime':this.endTime,'type':'', 'isValid':this.range.valid});
      }
  }
  applyDateFilter(): void {
      this.emitDateValue.emit({'startTime':this.startTime,'endTime':this.endTime,'type':'', 'isValid':this.range.valid});
  }
}
