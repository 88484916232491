import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService} from '@edgelinc/ui-library';
import { catchError, map } from 'rxjs/operators';

import * as AggregateGroups from '@app/modules/overview/model/aggregate-groups';

@Injectable({
    providedIn: 'root'
}) 
export class AggregateGroupsService{
   
    constructor(private http: HttpClient, private authService: AuthService) {}
    
    public getAggregateGroups(request:AggregateGroups.AggregateGroupsPayload):
    Observable<AggregateGroups.AggregationGroupsResponse>
    {
        const url = `${environment.base}/aers/events/groups/aggregate`;
        return this.http.post<AggregateGroups.AggregationGroupsResponse>(url, request, {
            headers: {
                'Content-Type': this.authService.getHeaders()['Content-Type']
            },
        }).pipe(
            map(response => {
                return response;
            }))
            .pipe(catchError(error => {
                return throwError(error);
            }
            ));
    }   
}