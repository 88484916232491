import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteLayoutComponent, AuthGuardService as AuthGuard} from '@edgelinc/ui-library';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: 'views',
        component: SiteLayoutComponent,
        children: [
            { path: 'home', component: DashboardComponent, canActivate: [AuthGuard]}
        ]
    }  
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class DashboardRoutingModule {}
