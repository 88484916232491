<div class='app-container mb-3'>
    <div class='app-grid-2 loco-data-container'>
        <fuel-app-kpi [locoList]="selectLoco" [price]="inputPrice" (updatePriceValue)="updatePriceValue($event)" (setPricePerGalon)="setPricePerGalon()"></fuel-app-kpi>
        <all-locomotives-multi-select class='all-loco-selector' [multiSelectList]="dropDownList"
            [defaultDisplayText]="'device_name'" [savedSelectedList]="savedSelectedList"
            (selectedList)="selectedLocoList($event)"></all-locomotives-multi-select>
    </div>
    <div class='mb-3'>
        <fuel-app-overview [locoList]="selectLoco" [fuelPricePerGalon]="fuelPricePerGalon"></fuel-app-overview>
    </div>
    <div class='mb-3'>
        <fuel-app-graph-view [locoList]="selectLoco"></fuel-app-graph-view>
    </div>
    <div class='mb-3'>
        <fuel-app-chart-view [locoList]="selectLoco"></fuel-app-chart-view>
    </div>
    <div class='mb-3'>
        <fuel-app-map-view></fuel-app-map-view>
    </div>
</div>