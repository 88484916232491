/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
export interface TypeInteger {
  value: string;
  viewValue: string;
}
export interface TypeString {
  value: string;
  viewValue: string;
}
export interface TypeDate {
  value: string;
  viewValue: string;
}
@Component({
    selector: 'el-column-filter',
    templateUrl: './column-filter.component.html',
    styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit {
  startDate?: number;
  endDate?: number;
  @Input() columnHeader;
  @Input() dateRangeTemplate;
  @Output() filterCloseEvent = new EventEmitter<string>();
  @Output() filterApplyEvent = new EventEmitter<any[]>();
  @Output() filterClearAllEvent = new EventEmitter();
  @ViewChild('filtersForm') filtersForm: NgForm;
  @Input() fromDate;
  @Input() toDate;
  @Input() dropdownfield: { [x: string]: any; };

  @Input() set customStartDate(value: number) {
    if (value) this.startDate = value;
  }
  @Input() set customEndDate(value: number) {
    if (value) this.endDate = value;
  }
  typeInteger: TypeInteger[] = [
      { value: 'lt', viewValue: 'Lesser than' },
      { value: 'gt', viewValue: 'Greater than' },
      { value: 'eq', viewValue: 'Equal to' },
      { value: 'btw', viewValue: 'Between' },
      { value: 'lte', viewValue: 'Lesser Than Equal ' },
      { value: 'gte', viewValue: 'Greater Than Equal' },
  ];
  // typeString: TypeString[] = [
  //     { value: 'contains', viewValue: 'Contains' },
  //     { value: 'eq', viewValue: 'Equal to' }
  // ];
  typeDate: TypeDate[] = [
      { value: 'btw', viewValue: 'Between' }
  ];
  filterCriteria = [];
  public filters: any[] = [{
      id: 1,
      columnName: '',
      columnType: '',
      dateRange: '',
      fromTextfield: '',
      toTextfield: '',
      columnTypeDisabled: true,
      inputDisabled: true,
      showFromTextField: false,
      showToTextField: false,
      fromDropdownfield: false,
      showDateRange: false,
      colType: [],
      showFromTextFieldErrormsg: false,
      showToTextFieldErrormsg: false
  }];
  isInvalid = false;
  addDisable = false;
  showFiltersCount = false;
  @Input() maxDateTime;
  @Input() typeString= [
      { value: 'contains', viewValue: 'Contains' },
      { value: 'eq', viewValue: 'Equal to' }];

    typeStaticEq: TypeString[]= [
        { value: 'eq', viewValue: 'Equal to'}];
    typeStaticBtw: TypeString[]= [
        { value: 'btw', viewValue: 'Between'},
        { value: 'eq', viewValue: 'Equal to' }];
    typeContains: TypeString[]= [
        { value: 'contains', viewValue: 'Contains'}];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() filterOptions: EventEmitter<any> = new EventEmitter();
  

  ngOnInit(): void {
      console.log('init');
  }
  showHideFilter() :void {
      this.filterCloseEvent.emit();
  }
  // disable all columns which are selected previous
  disableColumns():void {
      let index;
      const selectedColumnsList = [];
      for (let index_ = 0; index_ <= (this.filters.length - 1); index_++) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (this.filters[index_].columnName.value !== undefined) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              selectedColumnsList.push(this.filters[index_].columnName.value);
          }
      }
      if (selectedColumnsList.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          index = this.columnHeader.map(function (item) { return selectedColumnsList.indexOf(item.value); });
          for (const [index_, element] of index.entries()) {
              if (element !== -1) {
                  this.columnHeader[index_].disableSelectOpt = true;
              } else {
                  this.columnHeader[index_].disableSelectOpt = false;
              }
          }
      } else {
          this.columnHeader.map(item => item.disableSelectOpt = false);
      }
  }
  // get selected dropdown key and select value
  getDropDownSelect(event:any, filterID:any, rowIndex:any):void {
      this.filters[rowIndex]['fromTextfield'] = event;
  }
  // get selected column value and show condition select value
  getColumnCondition(event:any, filterID:any, rowIndex:any):void {
      this.filters[rowIndex].columnTypeDisabled = false;
      this.filters[rowIndex].inputDisabled = false;
      this.filters[rowIndex].fromTextfield = '';
      if ((event.type === 'Integer') || (event.type === 'Float')) {
          this.filters[rowIndex].colType = this.typeInteger;
      } else if (event.type === 'String') {
          this.filters[rowIndex]['colType'] = this.typeString;
      } else if (event.type === 'typeEqStatic'){
          this.filters[rowIndex]['colType'] = this.typeStaticEq;
          this.filters[rowIndex]['dropDownList'] =  this.dropdownfield[event.value];
      }  else if (event.type === 'typeBtwStatic'){
          this.filters[rowIndex]['colType'] = this.typeStaticBtw;
          this.filters[rowIndex]['dropDownList'] =  this.dropdownfield[event.value];
      } else if (event.type === 'typeContains'){
          this.filters[rowIndex]['colType'] = this.typeContains;
          this.filters[rowIndex]['dropDownList'] =  this.dropdownfield[event.value];
      } else {
          this.filters[rowIndex]['colType'] = this.typeDate;
      }
      if( this.filtersForm.controls['columnType_' + filterID]){
          this.filtersForm.controls['columnType_' + filterID].setValue(this.filters[rowIndex]['colType'][0].value);
          const selectedValue = this.filtersForm.controls['columnType_' + filterID].value;
          if(event.type === 'typeEqStatic'){
              this.filters[rowIndex].fromDropdownfield = true;
              this.filters[rowIndex].showToTextField = false;
              this.filters[rowIndex].showFromTextField = false;
              this.filters[rowIndex].showDateRange =false;
          } else if (selectedValue === 'btw') {
              if (event.type === 'Date') {
                  this.filters[rowIndex].showDateRange = true;
                  this.filters[rowIndex].showToTextField = false;
                  this.filters[rowIndex].showFromTextField = true;
                  this.filters[rowIndex].fromDropdownfield = false;
                  this.fromDate = '';
                  this.toDate = '';
              } else {
                  this.filters[rowIndex].showToTextField = true;
                  this.filters[rowIndex].showFromTextField = false;
                  this.filters[rowIndex].showDateRange = false;
                  this.filters[rowIndex].fromDropdownfield = false;
              }
          } else {
              this.filters[rowIndex].showToTextField = false;
              this.filters[rowIndex].showDateRange = false;
              this.filters[rowIndex].showFromTextField = false;
              this.filters[rowIndex].fromDropdownfield = false;
          }
      }
  }
  // function to handle date range 
  handleDateRange(daterange?):void {
      if (daterange) {
          if (daterange.startTime != undefined && daterange.currentTime != undefined) {
              this.fromDate = daterange.startTime;
              this.toDate = daterange.currentTime;
          } else {
              this.fromDate = '';
              this.toDate = '';
          }
      }
  }
  // on change of filter condition drop down value
  onConditionChange(filterId:any, rowIndex:any):void {
      if( this.filtersForm.controls['columnType_' + filterId]){
          if (this.filtersForm.controls['columnType_' + filterId].value === 'btw') {
              this.filters[rowIndex].showToTextField = true;
              this.filters[rowIndex].fromTextfield = '';
              this.filters[rowIndex].toTextfield = '';
              this.filters[rowIndex].fromDropdownfield = '';
          } else {
              if(this.filters.length > 0) {
                  this.filters[rowIndex].showToTextField = false;
                  this.filters[rowIndex].fromDropdownfield = false;
              }
          }
      }
  }
  defaultFilter(filterID){
      this.filters.push({
          id: filterID,
          columnName: '',
          columnType: '',
          dateRange: '',
          inputDisabled: true,
          fromTextfield: '',
          toTextfield: '',
          columnTypeDisabled: true,
          showFromTextField: false,
          showToTextField: false,
          showDateRange: false,
          showFromTextFieldErrormsg: false,
          showToTextFieldErrormsg: false
      });
      if(this.columnHeader.length !== this.filters.length){
          this.addDisable = false;
      } else{
          this.addDisable = true;
      }
  }
  // add a filter row
  addFilter():void {
      this.defaultFilter(this.filters[this.filters.length - 1].id + 1);
  }
  // deletes a specific row filter
  removeFilter(index: number):void {
      this.filters.splice(index, 1);
      this.addDisable = false;
  }
  // clear All filters
  clearFilter():void {
      this.filters.length = 0;
      if(this.columnHeader) {
          this.columnHeader.map(x => x.disableSelectOpt = false);
      }
      // push default filter
      this.defaultFilter(this.filters.length + 1);
      this.addDisable = false;
      this.filterClearAllEvent.emit();
      this.showHideFilter();
  }
  applyFilter():void {
      this.filters.map((filter) => {
      // Empty fromTextfield Error message show
          filter.showFromTextFieldErrormsg = ((filter.fromTextfield === '' || filter.fromTextfield === undefined)) ? true : false;
          // Empty fromDropdownfield Error message show
          filter.fromDropdownfieldErrormsg = ((filter.fromDropdownfield === '' || filter.fromDropdownfield === undefined)) ? true : false;
          // Empty toTextfield Error message show
          filter.showToTextFieldErrormsg = ((filter.toTextfield === '' || filter.toTextfield === undefined) && filter.columnType === 'btw') ? true : false;
          // check if date range has been entered
          filter.showDateRangeErrormsg = (((this.fromDate === undefined || this.fromDate === '') && (this.toDate === undefined || this.toDate === '')) && filter.columnType === 'btw') ? true : false;
      });
     
      // check if all fields has been entered
      this.isInvalid = this.filters.some(filter => (filter.fromDropdownfield || filter.showToTextField) ||  (filter.showToTextField && filter.showToTextFieldErrormsg) || (filter.showDateRange && filter.showDateRangeErrormsg));
      if(!this.isInvalid){
          this.filterApplyEvent.emit(this.filters);
          this.showHideFilter();
      }
  }
}
