/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';

export class BaseTableDataSource implements DataSource<any> {
    protected loading = new BehaviorSubject<boolean>(false);
    public list = new BehaviorSubject<any[]>([]);
    public loading$ = this.loading.asObservable();
    connect(): Observable<any[]> {
        return this.list.asObservable();
    }
    disconnect(): void {
        this.list.complete();
        this.loading.complete();
    }
}
