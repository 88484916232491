import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'el-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit{

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ngOnInit(): void {}
}
