<mat-toolbar color="primary">
  <mat-icon class="sidebar-icon" (click)="toggleSidebar()">view_headline</mat-icon>
  <div class="wabtec-logo"></div>
  <div class="flex menu-right">
    <div class="login-user">
      <div class="username-container">
        <div class="username-placeholder">
          {{loggedInUserInitials}}
        </div>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" style="display: none;">
        <mat-icon class="expand-icon">expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div mat-menu-item>
          <span>Refresh Token</span>
        </div>
      </mat-menu>
    </div>
    <div class="theme-toggle" style="display: none;">
      <div style="display: flex;">
        <div style="margin-right: 2px;">
          Dark Theme
        </div>
        <div style="margin-top: 3px;margin-left: 5px;">
          <mat-slide-toggle class="example-margin" [checked]="checked">
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="logout">
      <div class="logout-logo" (click)="logout()"></div>
    </div>
  </div>
</mat-toolbar>