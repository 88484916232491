<div class="seach-container">
    <volt-button variant="primary" (click)="toggleModal()" class="select-locomotives">
        <div class="select-locomotives-container">
            <span>{{selectedValues.length ? 'Selected locomotives' : 'All locomotives'}}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    </volt-button>
    <div *ngIf="isModalOpened" class="modal-container">
        <button mat-icon-button (click)="toggleModal()" class="modal-close">
            <mat-icon>close</mat-icon>
        </button>
        <div class="app-grid-2" style="padding: 1rem 0;">
            <div class="all-locomotives">
                <div class="text title">Unit</div>
                <div class="all-locomotives-container">
                    <div *ngFor="let itemList of multiSelectList" class="all-locomotives-element" (click)="selectLocomotive(itemList)">
                        <volt-checkbox [checked]="selectedValues.includes(itemList)"></volt-checkbox>
                        <span>{{itemList[defaultDisplayText]}}</span>
                    </div>
                </div>
            </div>
            <div class="selected-locomotives">
                <div class="text title">Selected</div>
                <div class="all-locomotives-container">
                    <ng-container *ngIf="selectedValues.length<=5">
                        <div *ngFor="let value of selectedValues" class="selected-locomotives-element" (click)="itemRemove(value)">
                            <span>{{value.device_name}}</span>
                            <mat-icon>close</mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedValues.length>5">
                        <div *ngFor="let i of [0,1,2,3,4]" class="selected-locomotives-element" (click)="itemRemove(selectedValues[i])">
                            <span>{{selectedValues[i].device_name}}</span>
                            <mat-icon>close</mat-icon>
                        </div>
                        <div class="consumption-notice">
                            <span>Consumption profile graph only shows the first 5 units above.</span>    
                        </div>
                        <div *ngFor="let value of selectedValues| slice:5;" class="selected-locomotives-element" (click)="itemRemove(value)">
                            <span>{{value.device_name}}</span>
                            <mat-icon>close</mat-icon>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedValues.length !== 0" class="selected-value-container">
    <ng-container *ngIf="selectedValues.length <= 5">
        <ng-container *ngFor="let value of selectedValues;">
            <div class="selected-value">
                {{value.name | truncate: 10}}
                <i class="material-icons clear-icon" (click)="itemRemove(value)">clear</i>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="selectedValues.length > 5">
        <div *ngFor="let i of [0,1,2,3,4]"  class="selected-value">
            {{selectedValues[i].name | truncate: 10}}
            <i class="material-icons clear-icon" (click)="itemRemove(selectedValues[i])">clear</i>
        </div>
        <volt-button variant="underline" (click)="openDialog()">View all selected Locomotives</volt-button>
    </ng-container>
</div>