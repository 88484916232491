import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutsService {
    
  public drawer;
  private sidebarToggleSource = new BehaviorSubject(true);
  toggleSidebar = this.sidebarToggleSource.asObservable();
  
 
  private themeToggleSource = new BehaviorSubject('light');
  toggleTheme = this.themeToggleSource.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggleSidebarState(value: boolean) {
      this.sidebarToggleSource.next(value);
  }


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggleThemeState(value: string) {
      this.themeToggleSource.next(value);
  }

}

