/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import * as json2csv from 'json2csv';
import { saveAs } from 'file-saver'; 

@Injectable({
    providedIn: 'root'
})
export class ExportCSVService {
   
    Json2csvParser = json2csv.Parser;

    public downloadFile(data: unknown, filename = 'data.csv'): void {
        const csvData = this.convertToCSV(data, {header: false});
        const file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(file, filename);
    }

    public convertToCSV(objectArray: unknown, fields?: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const json2csvParser = new this.Json2csvParser(fields);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const csv = json2csvParser.parse(objectArray);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return csv;
    }

    public downloadFileForDashboard(data: unknown, filename = 'data.csv') : void {
        const csvData = this.convertToCSV(data, {header: false, excelStrings: true});
        const file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(file, filename);
    }
}
