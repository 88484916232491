import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppStorageService, AuthService } from '@edgelinc/ui-library';
import { BaseLine } from '../models/base-line';

@Injectable({
    providedIn: 'root'
})
export class BaseLineService {
    baseKey= false;// variable to check for base value api value exists in local storage or not
    constructor(private http: HttpClient, private authService: AuthService, private appStorageService:AppStorageService) {}
    // API to get base values
    public getBaseAPIValue(): Observable<BaseLine[]> {
        const url = `${environment.base}/aers/baselineValues`;
        return this.http.get<BaseLine[]>(url, {
            headers: this.authService.getHeaders()
        }).pipe(
            map(response => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return response;
                
            }))
            .pipe(catchError(error => {
                return throwError(error);
            }
            ));
    }
    // function to check if base value api value exists in local storage
    public checkForKey():boolean{
        this.baseKey = this.appStorageService.getFromAppStorage('baseKey') ? true:false;
        return this.baseKey;
    }
}
