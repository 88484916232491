<mat-form-field [ngClass]="{'customDateSelection':startTime !==0 && endTime !==0 && dateSelect.value=='Custom'}" class="dateSelection" appearance="outline">
    <mat-select #dateSelect [value]="defaultValue" (openedChange)="openedChange($event,dateSelect.value)" (selectionChange)="onDateChange($event.value)" panelClass="custom-panel-select" matTooltip="{{checkCustom(customText)}}" matTooltipPosition='below'>
        <mat-option value="Today">Today</mat-option>
        <mat-option value="Month">Month</mat-option>
        <mat-option value="Year">Year</mat-option>
        <mat-option value="Custom" [innerText]='customText'></mat-option>
        <mat-option class='d-none'>
         <fuel-app-date-range-picker #dateRangePicker (emitDateValue)="receivedDateValue($event)"></fuel-app-date-range-picker>
        </mat-option>
    </mat-select>
</mat-form-field>
